import React from "react";
import { DEFAULT_PROFILE_COLOR } from "../../constants";
import { getLetterFromName } from "../../services/utils";

function ProfileImage({
  username,
  image,
  color,
  size = 12,
  classname = "",
  border,
  ...rest
}) {
  return (
    <div
      className={`image-box ${classname}`}
      style={{
        backgroundColor: !image && (color ?? DEFAULT_PROFILE_COLOR),
        fontSize: size,
        border: border,
      }}
      {...rest}>
      {image ? <img src={image} alt={username} /> : getLetterFromName(username)}
    </div>
  );
}

export default ProfileImage;
