// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactionsList: [],
};

const TransactiontypeReducer = createSlice({
  name: "transactiontype",
  initialState,
  reducers: {
    // CREATE TRANSACTION TYPE
    createTransactionTypeRequest() {},

    // UPDATE TRANSACTION TYPE
    updateTransactionTypeRequest() {},

    // CHANGE TRANSACTION TYPE STATUS
    changeTransactionTypeStatusRequest() {},

    // DELETE TRANSACTION TYPE
    deleteTransactionTypeRequest() {},

    // GET TRANSACTION TYPES
    getTransactionTypeListRequest() {},
    getTransactionTypeListSuccess(state, action) {
      state.transactionsList = action.payload;
    },

    // GET ONE TRANSACTION TYPE
    getOneTransactionTypeRequest() {},
    getOneTransactionTypeSuccess(state, action) {},
  },
});

export const {
  createTransactionTypeRequest,
  updateTransactionTypeRequest,
  changeTransactionTypeStatusRequest,
  getTransactionTypeListRequest,
  getTransactionTypeListSuccess,
  getOneTransactionTypeRequest,
  getOneTransactionTypeSuccess,
  deleteTransactionTypeRequest,
} = TransactiontypeReducer.actions;

export default TransactiontypeReducer.reducer;
