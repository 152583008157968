// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  data: null,
};

const PartyRoleReducer = createSlice({
  name: "partyroles",
  initialState,
  reducers: {
    // CREATE ROLE
    createPartyRoleRequest() {},

    // UPDATE ROLE
    updatePartyRoleRequest() {},

    // CHANGE PARTY ROLE STATUS
    changePartyStatusRequest() {},

    //DELETE PARTY ROLE
    deletePartyRoleRequest() {},

    // GET ROLES
    getPartyRoleListRequest() {},
    getPartyRoleListSuccess(state, action) {
      state.list = action.payload;
    },

    // GET ONE ROLE
    getOneRoleRequest() {},
    getOneRoleSuccess(state, action) {
      state.data = action.payload;
    },
  },
});

export const {
  createPartyRoleRequest,
  updatePartyRoleRequest,
  changePartyStatusRequest,
  getPartyRoleListRequest,
  getPartyRoleListSuccess,
  getOneRoleRequest,
  getOneRoleSuccess,
  deletePartyRoleRequest,
} = PartyRoleReducer.actions;

export default PartyRoleReducer.reducer;
