// @flow
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  usersList: [],
  singleUser: {},
};

const UserReducer = createSlice({
  name: "users",
  initialState,
  reducers: {
    getUsersListRequest() {},
    getUsersListSuccess(state, action) {
      state.usersList = action.payload;
    },

    getOneUserRequest() {},
    getOneUserSuccess(state, action) {
      state.singleUser = action.payload;
    },

    blockUnblockUserRequest() {},
    blockUnblockUserSuccess(state, action) {
      const userIndex = _.findIndex(state.usersList, {
        id: action.payload.id,
      });
      state.usersList[userIndex] = action.payload;
      state.singleUser = action.payload;
    },
  },
});

export const {
  getUsersListRequest,
  getUsersListSuccess,
  getOneUserRequest,
  getOneUserSuccess,
  blockUnblockUserRequest,
  blockUnblockUserSuccess,
} = UserReducer.actions;

export default UserReducer.reducer;
