// @flow
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  data: {},
};

const AuthReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // GET REFRESH TOKEN
    refreshToken(state, action) {
      state.data.accessToken = action.payload.accessToken;
      state.data.refreshToken = action.payload.refreshToken;
    },
    // SIGN IN
    userSignInRequest() {},
    userSignInSuccess(state, action) {
      state.data = action.payload;
    },
    // CONFIRM OTP
    confirmOtpRequest() {},
    // RESEND OTP
    resendOtpRequest() {},
    // SIGN OUT
    userLogOutRequest() {},
    userLogOutSuccess: () => initialState,
  },
});

export const {
  refreshToken,
  userSignInRequest,
  userSignInSuccess,
  userLogOutRequest,
  userLogOutSuccess,
  confirmOtpRequest,
  resendOtpRequest,
} = AuthReducer.actions;

export default AuthReducer.reducer;
