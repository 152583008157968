import React from "react";
import { Col, Form, Input, Rate } from "antd";
import { inputFieldRule } from "../../../services/utils";

const ReviewFormFields = () => {
  return (
    <>
      <Col span={24}>
        <label className="form-lbl">Rating</label>
        <Form.Item
          name="rating"
          rules={inputFieldRule({
            name: "Rating",
            isWhiteSpace: false,
          })}
        >
          <Rate />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">First Name</label>
        <Form.Item
          name="firstName"
          rules={inputFieldRule({
            name: "First name",
            isMax: true,
            max: 80,
          })}
        >
          <Input
            placeholder="Enter reviewer first name"
            className="form-input"
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Last Name</label>
        <Form.Item
          name="lastName"
          rules={inputFieldRule({
            name: "Last name",
            isMax: true,
            max: 80,
            isRequired: false,
          })}
        >
          <Input
            placeholder="Enter reviewer last name"
            className="form-input"
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Email</label>
        <Form.Item
          name="email"
          rules={inputFieldRule({
            name: "Email",
            isWhiteSpace: false,
            isEmail: true,
            isMax: true,
            max: 100,
          })}
        >
          <Input
            placeholder="Enter reviewer job title"
            className="form-input"
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Job Title</label>
        <Form.Item
          name="role"
          rules={inputFieldRule({
            name: "Role",
            isMax: true,
            max: 80,
          })}
        >
          <Input
            placeholder="Enter reviewer job title"
            className="form-input"
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <label className="form-lbl">Review</label>
        <Form.Item
          name="description"
          rules={inputFieldRule({
            name: "Review",
            isMax: true,
            max: 500,
          })}
        >
          <Input.TextArea
            placeholder="Enter review"
            className="form-input"
            style={{ height: "100px", resize: "none" }}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default ReviewFormFields;
