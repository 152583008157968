import { take, put, call, fork, takeLatest } from "redux-saga/effects";
import { ALERT_TYPES } from "../../constants";
import {
  callRequest,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  DELETE_FOLDER,
  GET_FOLDER_LIST,
  GET_FOLDER_DETAIL,
  UPLOAD_FILE,
  UPDATE_FILE,
  GET_FILE_LIST,
  DELETE_FILE,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";
import {
  createFolderRequest,
  deleteFileRequest,
  deleteFolderRequest,
  getFileListRequest,
  getFileListSuccess,
  getFolderListRequest,
  getFolderListSuccess,
  getFolderDetailRequest,
  getFolderDetailSuccess,
  updateFileRequest,
  updateFolderRequest,
  updateFolderSuccess,
  uploadResourceFileRequest,
} from "../slicers/resources";

function* createFolder() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(createFolderRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_FOLDER,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateFolder() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(updateFolderRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_FOLDER,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(updateFolderSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteFolder() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteFolderRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_FOLDER,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getFolderList(action) {
  const {
    payload: { payload, queryParams, pathParams, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: GET_FOLDER_LIST,
      data: payload,
      queryParams,
      pathParams,
    });
    if (response.status) {
      yield put(getFolderListSuccess(response.data));
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getFolderDetail() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(getFolderDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_FOLDER_DETAIL,
        data: payload,
        pathParams,
      });
      if (response.status) {
        yield put(getFolderDetailSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* uploadFile() {
  while (true) {
    const {
      payload: { payload, responseCallback, pathParams },
    } = yield take(uploadResourceFileRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPLOAD_FILE,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateFile() {
  while (true) {
    const {
      payload: { payload, responseCallback, pathParams },
    } = yield take(updateFileRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_FILE,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getFileList(action) {
  const {
    payload: { queryParams, pathParams, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: GET_FILE_LIST,
      pathParams,
      queryParams,
    });
    if (response.status) {
      yield put(getFileListSuccess(response.data));
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* deleteFile() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteFileRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_FILE,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(createFolder);
  yield fork(updateFolder);
  yield fork(deleteFolder);
  yield takeLatest(getFolderListRequest.type, getFolderList);
  yield fork(getFolderDetail);
  yield fork(uploadFile);
  yield fork(updateFile);
  yield takeLatest(getFileListRequest.type, getFileList);
  yield fork(deleteFile);
}
