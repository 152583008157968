// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  data: null,
};

const AdvisoryRoleReducer = createSlice({
  name: "advisoryroles",
  initialState,
  reducers: {
    // CREATE ADVISORY ROLE
    createAdvisoryRoleRequest() {},

    // UPDATE ADVISORY ROLE
    updateAdvisoryRoleRequest() {},

    // CHANGE ADVISORY ROLE STATUS
    changeAdvisoryStatusRequest() {},

    // DELETE ADVISORY ROLE
    deleteAdvisoryRoleRequest() {},

    // GET ADVISORY ROLE
    getAdvisoryRoleListRequest() {},
    getAdvisoryRoleListSuccess(state, action) {
      state.list = action.payload;
    },

    // GET ONE ADVISORY ROLE
    getOneAdvisoryRoleRequest() {},
    getOneAdvisoryRoleSuccess(state, action) {
      state.data = action.payload;
    },
  },
});

export const {
  createAdvisoryRoleRequest,
  updateAdvisoryRoleRequest,
  changeAdvisoryStatusRequest,
  getAdvisoryRoleListRequest,
  getAdvisoryRoleListSuccess,
  getOneAdvisoryRoleRequest,
  getOneAdvisoryRoleSuccess,
  deleteAdvisoryRoleRequest,
} = AdvisoryRoleReducer.actions;

export default AdvisoryRoleReducer.reducer;
