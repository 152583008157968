import { take, put, call, fork, takeLatest } from "redux-saga/effects";
import { ALERT_TYPES } from "../../constants";
import {
  callRequest,
  CREATE_TRANSACTION_TYPE,
  UPDATE_TRANSACTION_TYPE,
  CHANGE_TRANSACTION_TYPE_STATUS,
  GET_TRANSACTION_TYPE_LIST,
  DELETE_TRANSACTION_TYPE,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";
import {
  getOneTransactionTypeRequest,
  getOneTransactionTypeSuccess,
  getTransactionTypeListRequest,
  getTransactionTypeListSuccess,
  createTransactionTypeRequest,
  updateTransactionTypeRequest,
  changeTransactionTypeStatusRequest,
  deleteTransactionTypeRequest,
} from "../slicers/transactiontype";

function* createTransactionType() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(createTransactionTypeRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_TRANSACTION_TYPE,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateTransactionType() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(updateTransactionTypeRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_TRANSACTION_TYPE,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* changeTransactionTypeStatus() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(changeTransactionTypeStatusRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHANGE_TRANSACTION_TYPE_STATUS,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteTransactionType() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteTransactionTypeRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_TRANSACTION_TYPE,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getTransactionTypeList(action) {
  const {
    payload: { payload, queryParams, pathParams, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: GET_TRANSACTION_TYPE_LIST,
      data: payload,
      queryParams,
      pathParams,
    });
    if (response.status) {
      yield put(getTransactionTypeListSuccess(response.data));
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getOneTransactionType() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(getOneTransactionTypeRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_TRANSACTION_TYPE_LIST,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(getOneTransactionTypeSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(createTransactionType);
  yield fork(updateTransactionType);
  yield fork(changeTransactionTypeStatus);
  yield takeLatest(getTransactionTypeListRequest.type, getTransactionTypeList);
  yield fork(getOneTransactionType);
  yield fork(deleteTransactionType);
}
