import React from "react";
import { Col, Row } from "antd";
import PhoneInput from "react-phone-input-2";
const EnterprisesDetailView = ({ data }) => {
  return (
    <>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Enterprise Name</label>
        <p className="view-input">{data?.name}</p>
      </Col>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Phone Number</label>
        <PhoneInput
          containerClass="view-num-input"
          value={data?.workPhone}
          disabled
        />
      </Col>
      {data?.website && (
        <Col xs={24} md={12} lg={12}>
          <label className="form-lbl">Website</label>
          <p className="view-input">{data?.website}</p>
        </Col>
      )}
      <Col xs={24} md={12} lg={24}>
        <label className="form-lbl">Addresses</label>
      </Col>
      {data?.addresses.map((item, index) => (
        <Col lg={12} md={24} key={index}>
          <div className="address-card">
            <h4>{item?.branchName}</h4>
            <p>
              {item?.address} {item?.address2 && ", " + item.address2}
            </p>
            <hr />
            <Row>
              <Col xs={12}>
                <label>City</label>
                <span>{item?.city}</span>
              </Col>
              <Col xs={12}>
                <label>State</label>
                <span>{item?.state}</span>
              </Col>
              <Col xs={12}>
                <label>Postal/Zip Code</label>
                <span>{item?.zip}</span>
              </Col>
              <Col xs={12}>
                <label>Country</label>
                <span>{item?.country}</span>
              </Col>
            </Row>
          </div>
          {/* <Col xs={24} md={12} lg={24}>
            <label className="form-lbl">Address</label>
            <p className="view-input">{item?.address}</p>
          </Col>
          <Col xs={24} md={12} lg={6}>
            <label className="form-lbl">State</label>
            <p className="view-input">{item?.state}</p>
          </Col>
          <Col xs={24} md={12} lg={6}>
            <label className="form-lbl">City</label>
            <p className="view-input">{item?.city}</p>
          </Col>
          <Col xs={24} md={12} lg={6}>
            <label className="form-lbl">Country</label>
            <p className="view-input">{item?.country}</p>
          </Col>
          <Col xs={24} md={12} lg={6}>
            <label className="form-lbl">Zip Code</label>
            <p className="view-input">{item?.zip}</p>
          </Col> */}
        </Col>
      ))}
    </>
  );
};

export default EnterprisesDetailView;
