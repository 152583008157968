import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  getReviewListRequest,
  deleteReviewRequest,
} from "../../../redux/slicers/reviews";
import { Card, Typography, Col, Row, Tooltip, Form, Rate } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterFilled,
} from "@ant-design/icons";
import {
  DataTable,
  FilterDrawer,
  ConfirmationModal,
  ButtonComponent,
  ProfileImage,
} from "../../../components";
import { Link } from "react-router-dom";
import {
  WEB_STRINGS,
  TABLE_PERPAGE_SIZE,
  DATE_TIME,
  REVIEWS_ROUTE,
} from "../../../constants";
import { getFormattedDateTime, toastAlert } from "../../../services/utils";
import ReviewFilter from "./filter";
const { Title } = Typography;

function Reviews() {
  // WEBSTRING
  const { FILTER, TABLE, CONFIRMATION_MODAL } = WEB_STRINGS.REVIEWS;
  const { COLUMN, TOOLTIP } = TABLE;
  // STATES
  const [selectedData, setSelectedData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  // REDUX DATA
  const { list: data } = useSelector((state) => state.reviews);

  // DISPATCH CALLS
  const [getReviews, isDataLoading] = useCustomDispatch(getReviewListRequest);
  const [deleteReview, blockLoading] = useCustomDispatch(deleteReviewRequest);

  // CONST VALS
  const [form] = Form.useForm();
  const paginationConfig = {
    pageSize: TABLE_PERPAGE_SIZE,
    total: total,
  };

  //HANDLERS

  const handleFormSubmit = () => {
    const credentials = form.getFieldsValue();
    Object.keys(credentials).forEach(
      (key) => credentials[key] === undefined && delete credentials[key]
    );
    setFilteredData(credentials);
  };
  const handleResetForm = () => {
    setFilteredData(null);
    form.resetFields();
    setFilterOpen(false);
  };
  const handleConfirmModal = (data) => {
    setSelectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };
  const handleDeleteData = () => {
    deleteReview({
      pathParams: selectedData,
      logic(res) {
        toastAlert(res.message);
        setShowConfirmationModal(false);
        setFilteredData({ ...filteredData });
      },
    });
  };
  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };
  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  // HOOKS
  useEffect(() => {
    const queryParams = {
      limit: TABLE_PERPAGE_SIZE,
      offset: (page - 1) * TABLE_PERPAGE_SIZE,
      ...filteredData,
    };
    getReviews({
      queryParams,
      logic(response) {
        setTotal(response.total);
        setFilterOpen(false);
      },
    });
  }, [page, filteredData]);

  // FILTER DRAWER
  const drawerSec = () => (
    <Form form={form} className="form" onFinish={handleFormSubmit}>
      <ReviewFilter />
      <Form.Item style={{ marginTop: 25 }}>
        <ButtonComponent
          text={FILTER.BTN}
          isLoading={isDataLoading}
          disabled={isDataLoading}
          className="w-100"
        />
      </Form.Item>
      <span className="form-rm-btn" onClick={handleResetForm}>
        Reset Filters
      </span>
    </Form>
  );

  // TABLE COLUMNS
  const columns = [
    {
      title: COLUMN.NO,
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * TABLE_PERPAGE_SIZE) + (i + 1)}</b>
      ),
    },
    {
      title: COLUMN.NAME,
      dataIndex: "",
      key: "2",
      render: (data) => {
        const username = data.firstName + " " + data.lastName;
        return (
          <span className="tbl-detail-box">
            <ProfileImage image={data?.image} username={username} />
            <span className="detail">{username}</span>
          </span>
        );
      },
    },
    {
      title: COLUMN.ROLE,
      dataIndex: "role",
      key: "3",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      title: COLUMN.RATING,
      dataIndex: "rating",
      key: "4",
      render: (data) => {
        return (
          <span>
            <Rate defaultValue={data} disabled style={{ fontSize: 14 }} />
          </span>
        );
      },
    },
    {
      title: COLUMN.CREATED,
      dataIndex: "createdAt",
      key: "5",
      render: (data) => {
        return <span>{getFormattedDateTime(data, DATE_TIME)}</span>;
      },
    },
    {
      title: COLUMN.ACTION,
      key: "6",
      render: (data) => {
        return (
          <div className="actions">
            <Link
              to={REVIEWS_ROUTE.DETAIL.replace(":dataid", data.id)}
              className="update">
              <Tooltip title={TOOLTIP.DETAIL}>
                <EyeOutlined />
              </Tooltip>
            </Link>
            <Link
              to={REVIEWS_ROUTE.UPDATE.replace(":id", data.id)}
              className="update">
              <Tooltip title={TOOLTIP.EDIT}>
                <EditOutlined />
              </Tooltip>
            </Link>
            <span style={{ width: 25 }}>
              <Tooltip title={TOOLTIP.DELETE}>
                <DeleteOutlined onClick={() => handleConfirmModal(data.id)} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <ButtonComponent
                isLink
                link={REVIEWS_ROUTE.CREATE}
                text={TABLE.BTN}
              />
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}>
              <div className="project-ant">
                <div>
                  <Title level={5}>{TABLE.TITLE}</Title>
                </div>
                <ButtonComponent
                  onClick={filterHandler}
                  text={FILTER.TITLE}
                  icon={<FilterFilled />}
                />
              </div>
              <DataTable
                isLoading={isDataLoading}
                data={data}
                columns={columns}
                pagination={{
                  ...paginationConfig,
                  onChange: handlePageChange,
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <ConfirmationModal
        handleClose={handleConfirmationModalClose}
        modalPreview={showConfirmationModal}
        title={CONFIRMATION_MODAL.TITLE}
        description={CONFIRMATION_MODAL.DESCRIPTION}
        handelConfirm={handleDeleteData}
        isLoading={blockLoading}
      />
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
    </>
  );
}

export default Reviews;
