import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { useParams, useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../../components";
import { CONTACT_US_ROUTE, WEB_STRINGS } from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  getContactDetailRequest,
  deleteContactRequest,
} from "../../../redux/slicers/contact";
import { toastAlert } from "../../../services/utils";
import PhoneInput from "react-phone-input-2";

const ContactDetail = () => {
  // WEB STRINGS
  const { DELETE_BTN } = WEB_STRINGS.CONTACTS_FORM;
  const { CONFIRMATION_MODAL } = WEB_STRINGS.CONTACTS;

  // STATES
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // REDUX DATA
  const { data } = useSelector((state) => state.contact);

  // DISPATCH CALLS
  const [getContactDetail, dataLoading] = useCustomDispatch(
    getContactDetailRequest
  );
  const [deleteContact, deleteLoading] =
    useCustomDispatch(deleteContactRequest);

  // CONST VALS
  const { id, dataid } = useParams();
  const paramid = id || dataid;
  const navigate = useNavigate();

  // HOOKS
  useEffect(() => {
    if (paramid) {
      getContactDetail({ pathParams: paramid });
    }
  }, []);

  // HANDLERS
  const handleConfirmModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };
  const handleDeleteData = () => {
    deleteContact({
      pathParams: paramid,
      logic(res) {
        toastAlert(res.message);
        setShowConfirmationModal(false);
        navigate(CONTACT_US_ROUTE.GET);
      },
    });
  };

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <div style={{ textAlign: "right" }}>
            <button
              className="block-btn"
              onClick={handleConfirmModal}
              style={{ marginLeft: 10, marginBottom: 15 }}>
              {DELETE_BTN}
            </button>
          </div>
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 25px" }}>
            {dataLoading ? (
              <div className="loader-wrapper" style={{ height: 320 }}>
                <BeatLoader color="#6F7CED" />
              </div>
            ) : (
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12} lg={12}>
                  <label className="form-lbl">First Name</label>
                  <p className="view-input">{data?.firstName ?? ""}</p>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <label className="form-lbl">Last Name</label>
                  <p className="view-input">{data?.lastName ?? ""}</p>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <label className="form-lbl">Email</label>
                  <p className="view-input">{data?.email ?? ""}</p>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <label className="form-lbl">Phone</label>
                  <PhoneInput
                    containerClass="view-num-input"
                    value={data?.phone}
                    disabled
                  />
                </Col>
                <Col span={24}>
                  <label className="form-lbl">Message</label>
                  <p className="view-input">{data?.message ?? ""}</p>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        handleClose={handleConfirmModal}
        modalPreview={showConfirmationModal}
        title={CONFIRMATION_MODAL.TITLE}
        description={CONFIRMATION_MODAL.DESCRIPTION}
        handelConfirm={handleDeleteData}
        isLoading={deleteLoading}
      />
    </div>
  );
};

export default ContactDetail;
