import Logo from "../assets/logo.svg";
import BlockIcon from "../assets/icons/block.svg";
import UnblockIcon from "../assets/icons/unblock.svg";
import { ReactComponent as Phone } from "../assets/icons/phone.svg";
import { ReactComponent as Upload } from "../assets/icons/upload.svg";

export default {
  Logo,
  BlockIcon,
  UnblockIcon,
  Phone,
  Upload,
};
