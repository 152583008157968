import { Modal } from "antd";
import React from "react";
import "./styles.scss";
import { WEB_STRINGS } from "../../constants";
import ButtonComponent from "../ButtonComponent";
const ConfirmationModal = ({
  handleClose,
  title,
  description,
  handelConfirm,
  modalPreview,
  isLoading,
}) => {
  const { CONFIRMATION_MODAL } = WEB_STRINGS;
  const handleDeleteData = () => {
    handelConfirm();
  };

  return (
    <Modal
      visible={modalPreview}
      title={<span>{title}</span>}
      centered
      onCancel={handleClose}
      footer={null}
      className="modal">
      <div className="modal-wrapper">
        <div className="modal-body">
          <div className="modal-confirmation">{description}</div>
        </div>
        <div className="modal-footer">
          <button className="close" onClick={handleClose}>
            {CONFIRMATION_MODAL.CLOSE}
          </button>
          <ButtonComponent
            text={CONFIRMATION_MODAL.CONFIRM}
            onClick={handleDeleteData}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
