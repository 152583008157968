// import { useState } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DASHBOARD_ROUTE, SIDEBAR_LINKS } from "../../constants";
import { Images } from "../../themes";

function Sidenav({ page }) {
  return (
    <>
      <div className="brand">
        <NavLink to={DASHBOARD_ROUTE} className="logo-wrapper">
          <img src={Images.Logo} alt="Dealtru" />
        </NavLink>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {SIDEBAR_LINKS.map((item, index) => {
          const isActive = page.includes(item.key);
          return (
            <Menu.Item key={index}>
              <NavLink to={item.route} className={isActive ? "active" : ""}>
                <span
                  className="icon"
                  style={{
                    background: isActive ? "#6F7CED" : "",
                  }}>
                  <FontAwesomeIcon
                    style={{
                      color: isActive ? "#fff" : "#bfbfbf",
                    }}
                    icon={item.icon}
                  />
                </span>
                <span className="label">{item.title}</span>
              </NavLink>
            </Menu.Item>
          );
        })}
      </Menu>
    </>
  );
}

export default Sidenav;
