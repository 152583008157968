import React from "react";
import { BeatLoader } from "react-spinners";
import { Table } from "antd";
function DataTable({ data, columns, pagination, isLoading }) {
  const TABLE_CONFIG = {
    emptyText: isLoading ? <div style={{ height: 300 }} /> : null,
  };
  const filteredData = data?.map((item, index) => {
    return { ...item, key: index + 1 };
  });
  return (
    <div className="ant-list-box" style={{ marginTop: 10 }}>
      <Table
        loading={{
          spinning: isLoading,
          indicator: (
            <div
              className="loader-wrapper"
              style={{ height: 450, position: "unset", width: "100%" }}>
              <BeatLoader color="#6F7CED" />
            </div>
          ),
        }}
        locale={TABLE_CONFIG}
        columns={columns}
        dataSource={isLoading ? [] : filteredData}
        pagination={{
          showSizeChanger: false,
          ...pagination,
        }}
      />
    </div>
  );
}

export default DataTable;
