import { take, put, call, fork } from "redux-saga/effects";
import { ALERT_TYPES } from "../../constants";
import {
  callRequest,
  CREATE_PARTY_ROLE,
  UPDATE_PARTY_ROLE,
  CHANGE_PARTY_STATUS,
  GET_PARTY_ROLE_LIST,
  GET_ONE_PARTY_ROLE,
  DELETE_PARTY_ROLE,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";
import {
  createPartyRoleRequest,
  changePartyStatusRequest,
  getOneRoleRequest,
  getOneRoleSuccess,
  getPartyRoleListRequest,
  getPartyRoleListSuccess,
  updatePartyRoleRequest,
  deletePartyRoleRequest,
} from "../slicers/partyroles";

function* createPartyRole() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(createPartyRoleRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_PARTY_ROLE,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updatePartyRole() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(updatePartyRoleRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_PARTY_ROLE,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* changeRoleStatus() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(changePartyStatusRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHANGE_PARTY_STATUS,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deletePartyRole() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deletePartyRoleRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_PARTY_ROLE,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getPartyRoleList() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(getPartyRoleListRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_PARTY_ROLE_LIST,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(getPartyRoleListSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getOneRole() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(getOneRoleRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ONE_PARTY_ROLE,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(getOneRoleSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(createPartyRole);
  yield fork(updatePartyRole);
  yield fork(changeRoleStatus);
  yield fork(getPartyRoleList);
  yield fork(getOneRole);
  yield fork(deletePartyRole);
}
