// @flow
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  folderList: [],
  folderdata: {},
  fileList: [],
};

const ResourcesReducer = createSlice({
  name: "resources",
  initialState,
  reducers: {
    // CREATE FOLDER
    createFolderRequest() {},

    // UPDATE FOLDER
    updateFolderRequest() {},
    updateFolderSuccess(state, action) {
      const dataIndex = _.findIndex(state.folderList, {
        id: action.payload.id,
      });
      state.folderList[dataIndex] = action.payload;
      state.folderdata = action.payload;
    },

    // DELETE FOLDER
    deleteFolderRequest() {},

    // GET FOLDERS
    getFolderListRequest() {},
    getFolderListSuccess(state, action) {
      state.folderList = action.payload;
    },
    // GET FOLDER DETAIL
    getFolderDetailRequest() {},
    getFolderDetailSuccess(state, action) {
      state.folderdata = action.payload;
    },

    // UPLOAD FILE
    uploadResourceFileRequest() {},

    // UPDATE FILE
    updateFileRequest() {},

    // DELETE FILE
    deleteFileRequest() {},

    // GET FILES
    getFileListRequest() {},
    getFileListSuccess(state, action) {
      state.fileList = action.payload;
    },

    // GET ONE FILE
    getOneFileRequest() {},
    getOneFileSuccess(state, action) {},
  },
});

export const {
  createFolderRequest,
  updateFolderRequest,
  updateFolderSuccess,
  deleteFolderRequest,
  getFolderListRequest,
  getFolderListSuccess,
  getFolderDetailRequest,
  getFolderDetailSuccess,
  deleteFileRequest,
  getFileListRequest,
  getFileListSuccess,
  getOneFileRequest,
  getOneFileSuccess,
  updateFileRequest,
  uploadResourceFileRequest,
} = ResourcesReducer.actions;

export default ResourcesReducer.reducer;
