import { take, put, call, fork, takeLatest, takeEvery } from "redux-saga/effects";
import { ALERT_TYPES } from "../../constants";
import {
  callRequest,
  CREATE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  GET_QUESTIONS_LIST,
  GET_ONE_QUESTION,
  GET_QUESTION_OPTIONS,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";
import {
  getOneQuestionRequest,
  getOneQuestionSuccess,
  getQuestionsRequest,
  getQuestionsSuccess,
  createQuestionRequest,
  deleteQuestionRequest,
  updateQuestionRequest,
  getQuestionOptionsRequest,
} from "../slicers/questions";

function* createQuestion() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createQuestionRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_QUESTION,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateQuestion() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateQuestionRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_QUESTION,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteQuestion() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteQuestionRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_QUESTION,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getQuestionList(action) {
  const {
    payload: { queryParams, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: GET_QUESTIONS_LIST,
      queryParams,
    });
    if (response.status) {
      yield put(getQuestionsSuccess(response.data));
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

function* getOneQuestion() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getOneQuestionRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ONE_QUESTION,
        pathParams,
      });
      if (response.status) {
        yield put(getOneQuestionSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getQuestionOptions(action) {
  const {
    payload: { pathParams, responseCallback },
  } = action;

  try {
    const response = yield call(callRequest, {
      url: GET_QUESTION_OPTIONS,
      pathParams,
    });
    if (response.status) {
      responseCallback?.(true, response);
    } else {
      responseCallback?.(false, response);
      response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}
export default function* root() {
  yield fork(createQuestion);
  yield fork(updateQuestion);
  yield fork(deleteQuestion);
  yield takeLatest(getQuestionsRequest.type, getQuestionList);
  yield fork(getOneQuestion);
  yield takeEvery(getQuestionOptionsRequest.type, getQuestionOptions);
}
