import { take, put, call, fork } from "redux-saga/effects";
import { ALERT_TYPES } from "../../constants";
import {
  callRequest,
  CREATE_ADVISORY_ROLE,
  UPDATE_ADVISORY_ROLE,
  CHANGE_ADVISORY_STATUS,
  GET_ADVISORY_ROLE_LIST,
  GET_ONE_ADVISORY_ROLE,
  DELETE_ADVISORY_ROLE,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";
import {
  createAdvisoryRoleRequest,
  changeAdvisoryStatusRequest,
  getOneAdvisoryRoleRequest,
  getOneAdvisoryRoleSuccess,
  getAdvisoryRoleListRequest,
  getAdvisoryRoleListSuccess,
  updateAdvisoryRoleRequest,
  deleteAdvisoryRoleRequest,
} from "../slicers/advisoryroles";

function* createAdvisoryRole() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(createAdvisoryRoleRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_ADVISORY_ROLE,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateAdvisoryRole() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(updateAdvisoryRoleRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_ADVISORY_ROLE,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* changeAdvisoryRoleStatus() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(changeAdvisoryStatusRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CHANGE_ADVISORY_STATUS,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteAdvisoryRole() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteAdvisoryRoleRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_ADVISORY_ROLE,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getAdvisoryRoleList() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(getAdvisoryRoleListRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ADVISORY_ROLE_LIST,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(getAdvisoryRoleListSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getOneAdvisoryRole() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(getOneAdvisoryRoleRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ONE_ADVISORY_ROLE,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(getOneAdvisoryRoleSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(createAdvisoryRole);
  yield fork(updateAdvisoryRole);
  yield fork(changeAdvisoryRoleStatus);
  yield fork(getAdvisoryRoleList);
  yield fork(getOneAdvisoryRole);
  yield fork(deleteAdvisoryRole);
}
