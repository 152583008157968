import { take, put, call, fork } from "redux-saga/effects";
import { ALERT_TYPES } from "../../constants";
import {
  callRequest,
  DELETE_CONTACT,
  GET_CONTACTS,
  GET_CONTACT_DETAIL,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";
import {
  deleteContactRequest,
  getContactDetailRequest,
  getContactDetailSuccess,
  getContactsListRequest,
  getContactsListSuccess,
} from "../slicers/contact";

function* deleteContact() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteContactRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_CONTACT,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getContactsList() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getContactsListRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_CONTACTS,
        queryParams,
      });
      if (response.status) {
        yield put(getContactsListSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getContactDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getContactDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_CONTACT_DETAIL,
        pathParams,
      });
      if (response.status) {
        yield put(getContactDetailSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(deleteContact);
  yield fork(getContactsList);
  yield fork(getContactDetail);
}
