import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Error } from "../modules";
import { PAGE_ROUTES, ACCESS_TYPES } from "../constants";
import Helmet from "react-helmet";
import {
  AuthPrivateRoute,
  DashboardPrivateRoute,
} from "../config/privateRoute";

const renderRouteSharedLayout = (title, access, component) => (
  <React.Fragment>
    <Helmet>
      <title>
        {title ? `${title} |` : ""} {process.env.REACT_APP_WEB_TITLE}
      </title>
    </Helmet>
    {access === ACCESS_TYPES.PRIVATE ? (
      <DashboardPrivateRoute>{component}</DashboardPrivateRoute>
    ) : (
      <AuthPrivateRoute>{component}</AuthPrivateRoute>
    )}
  </React.Fragment>
);

const PageRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        {PAGE_ROUTES.map((item, index) => (
          <Route
            path={item.route}
            element={renderRouteSharedLayout(
              item.title,
              item.access,
              item.component
            )}
            key={index}
          />
        ))}
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default PageRoutes;
