import React from "react";
import { Col } from "antd";
const PartyRoleDetailView = ({ data }) => {
  return (
    <>
      <Col xs={24}>
        <label className="form-lbl">Role Name</label>
        <p className="view-input">{data?.name}</p>
      </Col>
      {/* <Col span={24}>
        <h2>Permissions</h2>
        <p>Participants with this role can perform the following actions.</p>
      </Col>{" "}
      {data?.rolePermissions.map((item) => (
        <Col lg={8}>
          <p className="view-input">{item.key}</p>
        </Col>
      ))} */}
    </>
  );
};

export default PartyRoleDetailView;
