// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  data: {},
};

const QuestiontypeReducer = createSlice({
  name: "questions",
  initialState,
  reducers: {
    // CREATE QUESTION
    createQuestionRequest() {},

    // UPDATE QUESTION
    updateQuestionRequest() {},

    // DELETE QUESTION
    deleteQuestionRequest() {},

    // GET QUESTIONS
    getQuestionsRequest() {},
    getQuestionsSuccess(state, action) {
      state.list = action.payload;
    },

    // GET ONE QUESTION
    getOneQuestionRequest() {},
    getOneQuestionSuccess(state, action) {
      state.data = action.payload;
    },

    // GET QUESTION OPTIONS
    getQuestionOptionsRequest() {},
  },
});

export const {
  createQuestionRequest,
  updateQuestionRequest,
  deleteQuestionRequest,
  getQuestionsRequest,
  getQuestionsSuccess,
  getOneQuestionRequest,
  getOneQuestionSuccess,
  getQuestionOptionsRequest,
} = QuestiontypeReducer.actions;

export default QuestiontypeReducer.reducer;
