import React from "react";
import { Form, Input, Select } from "antd";
import { WEB_STRINGS } from "../../../constants";

function QuestionFilter({
  transactiontypes,
  isTypesLoading,
  setTypeSearchText,
}) {
  const { NAME, TRANSACTION_TYPE } = WEB_STRINGS.QUESTIONS.FILTER.FIELDS;

  return (
    <>
      <label className="form-lbl">{NAME.LABEL}</label>
      <Form.Item name={NAME.NAME} rules={NAME.RULES}>
        <Input
          name={NAME.NAME}
          placeholder={NAME.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">{TRANSACTION_TYPE.LABEL}</label>
      <Form.Item name={TRANSACTION_TYPE.NAME}>
        <Select
          placeholder={TRANSACTION_TYPE.PLACEHOLDER}
          name={TRANSACTION_TYPE.NAME}
          className="form-select"
          showSearch
          loading={isTypesLoading}
          onSearch={(val) => {
            setTypeSearchText(val);
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }>
          {transactiontypes?.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
}

export default QuestionFilter;
