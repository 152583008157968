import React from "react";
import {
  FacebookFilled,
  LinkedinFilled,
  TwitterOutlined,
} from "@ant-design/icons";

function SocialBox({ data }) {
  return (
    <ul className="social-box">
      {data?.facebook && (
        <li className="fb">
          <a href={data?.facebook} target="_blank" referrerPolicy="no-referrer">
            <FacebookFilled />
          </a>
        </li>
      )}
      {data?.twitter && (
        <li className="twitter">
          <a href={data?.twitter} target="_blank" referrerPolicy="no-referrer">
            <TwitterOutlined />
          </a>
        </li>
      )}
      {data?.linkedIn && (
        <li className="linkedin">
          <a href={data?.linkedIn} target="_blank" referrerPolicy="no-referrer">
            <LinkedinFilled />
          </a>
        </li>
      )}
    </ul>
  );
}

export default SocialBox;
