import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography, Tooltip } from "antd";
import { DataTable, ProfileImage } from "../../../../components";
import { EyeOutlined } from "@ant-design/icons";
import { WEB_STRINGS, ENTERPRISES_ROUTE } from "../../../../constants";
function DashboardEnterprises({ isLoading }) {
  const { Title } = Typography;
  const { TABLE } = WEB_STRINGS.ENTERPRISES;
  const { COLUMN, TOOLTIP } = TABLE;

  // REDUX DATA
  const data = useSelector((state) => state.enterprises.list);

  const columns = [
    {
      title: COLUMN.NO,
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => <b>{i + 1}</b>,
    },
    {
      title: COLUMN.NAME,
      dataIndex: "",
      key: "2",
      render: (data, value) => {
        return (
          <span className="tbl-detail-box sm">
            <ProfileImage
              color={data?.bgcolor}
              image={data?.logo}
              username={data.name}
            />
            <span className="detail">{data.name}</span>
          </span>
        );
      },
    },
    {
      title: COLUMN.ACTION,
      key: "3",
      render: (data, record) => {
        return (
          <div className="actions" style={{ paddingLeft: 15 }}>
            <Link
              to={ENTERPRISES_ROUTE.DETAIL.replace(":dataid", data.id)}
              className="update">
              <Tooltip title={TOOLTIP.DETAIL}>
                <EyeOutlined />
              </Tooltip>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="project-ant" style={{ marginBottom: 10 }}>
        <Title level={5}>{TABLE.TITLE}</Title>
      </div>
      <DataTable
        isLoading={isLoading}
        data={data}
        columns={columns}
        pagination={false}
      />
    </>
  );
}

export default DashboardEnterprises;
