// @flow
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  list: [],
  data: null,
};

const EnterpriseReducer = createSlice({
  name: "enterprise",
  initialState,
  reducers: {
    // CREATE ENTERPISE
    createEnterpriseRequest() {},
    createEnterpriseSuccess(state, action) {},

    // GET ENTERPISES
    getEnterpriseListRequest() {},
    getEnterpriseListSuccess(state, action) {
      state.list = action.payload;
    },

    // GET ONE ENTERPISE
    getOneEnterpriseRequest() {},
    getOneEnterpriseSuccess(state, action) {
      state.data = action.payload;
    },

    // BLOCK UNBLOCK ENTERPISE
    blockUnblockEnterpriseRequest() {},
    blockUnblockEnterpriseSuccess(state, action) {
      const dataIndex = _.findIndex(state.list, {
        id: action.payload.id,
      });
      state.list[dataIndex] = action.payload;
      state.data = action.payload;
    },

    // UPDATE ENTERPISE
    updateEnterpriseRequest() {},
    updateEnterpriseSuccess(state, action) {},
  },
});

export const {
  createEnterpriseRequest,
  createEnterpriseSuccess,
  getEnterpriseListRequest,
  getEnterpriseListSuccess,
  getOneEnterpriseRequest,
  getOneEnterpriseSuccess,
  blockUnblockEnterpriseRequest,
  blockUnblockEnterpriseSuccess,
  updateEnterpriseRequest,
  updateEnterpriseSuccess,
} = EnterpriseReducer.actions;

export default EnterpriseReducer.reducer;
