import { call, fork, put, take } from "redux-saga/effects";
import {
  callRequest,
  GET_DASHBOARD_STATS,
  GET_PERMISSIONS,
  UPLOAD_IMAGE,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../services/utils";
import {
  getPermissionsRequest,
  getPermissionsSuccess,
  getDashboardStatsRequest,
  uploadImageRequest,
} from "../slicers/general";

function* uploadImage() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(uploadImageRequest.type);
    try {
      const response = yield call(callRequest, {
        url: UPLOAD_IMAGE,
        data: payload,
        header: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getPermissions() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getPermissionsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_PERMISSIONS,
      });
      if (response.status) {
        yield put(getPermissionsSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getDashboardStats() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getDashboardStatsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_DASHBOARD_STATS,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
export default function* root() {
  yield fork(uploadImage);
  yield fork(getDashboardStats);
  yield fork(getPermissions);
}
