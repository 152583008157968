import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterFilled,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  Switch,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import {
  ButtonComponent,
  ConfirmationModal,
  DataTable,
  FilterDrawer,
} from "../../../components";
import {
  WEB_STRINGS,
  TABLE_PERPAGE_SIZE,
  DATE_TIME,
  ADVISORY_ROLE_ROUTE,
} from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  changeAdvisoryStatusRequest,
  deleteAdvisoryRoleRequest,
  getAdvisoryRoleListRequest,
} from "../../../redux/slicers/advisoryroles";
import { getFormattedDateTime, toastAlert } from "../../../services/utils";
import { Link } from "react-router-dom";
const { Title } = Typography;

function AdvisoryRoles() {
  // WEBSTRING
  const { FILTER, TABLE, CONFIRMATION_MODAL } = WEB_STRINGS.ADVISORY_ROLE;
  const { NAME, STATUS } = FILTER.FIELDS;
  const { COLUMN, TOOLTIP, ACTIVE, INACTIVE, DELETE } = TABLE;
  // STATES
  const [selectedData, setSelectedData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState({
    status: ACTIVE,
    preview: false,
  });
  const [isAnotherCall, setAnotherCall] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();

  //REDUX DATA
  const { list } = useSelector((state) => state.advisoryroles);

  //CUSTOM DISPATCH
  const [getRoles, isLoading] = useCustomDispatch(getAdvisoryRoleListRequest);
  const [changeStatus, statusLoader] = useCustomDispatch(
    changeAdvisoryStatusRequest
  );
  const [deleteRole, deleteLoader] = useCustomDispatch(
    deleteAdvisoryRoleRequest
  );

  const paginationConfig = {
    pageSize: TABLE_PERPAGE_SIZE,
    total: total,
  };

  useEffect(() => {
    const queryParams = {
      limit: TABLE_PERPAGE_SIZE,
      offset: (page - 1) * TABLE_PERPAGE_SIZE,
      ...filteredData,
    };

    getRoles({
      queryParams,
      logic({ total }) {
        setTotal(total);
        setFilterOpen(false);
      },
    });
  }, [page, isAnotherCall, filteredData]);

  const handleFormSubmit = () => {
    const credentials = form.getFieldsValue();
    Object.keys(credentials).forEach(
      (key) => credentials[key] === undefined && delete credentials[key]
    );
    setFilteredData(credentials);
  };

  const handleResetForm = () => {
    setFilteredData(null);
    form.resetFields();
    setFilterOpen(false);
  };

  const drawerSec = () => (
    <Form form={form} className="form" onFinish={handleFormSubmit}>
      <label className="form-lbl">{NAME.LABEL}</label>
      <Form.Item name={NAME.NAME}>
        <Input
          name={NAME.NAME}
          placeholder={NAME.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">{STATUS.LABEL}</label>
      <Form.Item name={STATUS.NAME}>
        <Select
          placeholder={STATUS.PLACEHOLDER}
          name={STATUS.NAME}
          className="form-select"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {STATUS.OPTIONS.map((data, index) => (
            <Select.Option value={data.key} key={index}>
              {data.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item style={{ marginTop: 25 }}>
        <ButtonComponent
          text={FILTER.BTN}
          isLoading={isLoading}
          className="w-100"
        />
      </Form.Item>
      <span className="form-rm-btn" onClick={handleResetForm}>
        Reset Filters
      </span>
    </Form>
  );

  const columns = [
    {
      title: COLUMN.NO,
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * TABLE_PERPAGE_SIZE) + (i + 1)}</b>
      ),
    },
    {
      title: COLUMN.NAME,
      dataIndex: "name",
      key: "2",
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },
    {
      title: COLUMN.STATUS,
      dataIndex: "",
      key: "3",
      render: (data, value) => {
        return (
          <span className="custom-switch">
            <Switch
              disabled={data.isDefault}
              checkedChildren="Activated"
              unCheckedChildren="Deactivated"
              checked={data.status}
              onClick={() => {
                handleConfirmModal(data.id, data?.status ? INACTIVE : ACTIVE);
              }}
            />
          </span>
        );
      },
    },
    {
      title: COLUMN.CREATED,
      dataIndex: "createdAt",
      key: "4",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data, DATE_TIME)}</span>;
      },
    },
    {
      title: COLUMN.ACTION,
      key: "5",
      render: (data, record) => {
        return (
          <div className="actions" style={{ paddingLeft: 15 }}>
            <Link
              to={ADVISORY_ROLE_ROUTE.DETAIL.replace(":dataid", data.id)}
              className="update"
            >
              <Tooltip title={TOOLTIP.DETAIL}>
                <EyeOutlined />
              </Tooltip>
            </Link>
            <Link
              to={
                data.isDefault
                  ? "#"
                  : ADVISORY_ROLE_ROUTE.UPDATE.replace(":id", data.id)
              }
              className="update"
              style={{
                opacity: data.isDefault ? 0.3 : 1,
              }}
            >
              <Tooltip title={!data.isDefault && TOOLTIP.EDIT}>
                <EditOutlined
                  style={{
                    cursor: data.isDefault ? "default" : "pointer",
                  }}
                />
              </Tooltip>
            </Link>
            <span
              style={{
                opacity: data.isDefault ? 0.3 : 1,
              }}
            >
              <Tooltip title={!data.isDefault && TOOLTIP.DELETE}>
                <DeleteOutlined
                  style={{
                    cursor: data.isDefault ? "default" : "pointer",
                  }}
                  onClick={() =>
                    !data.isDefault && handleConfirmModal(data.id, DELETE)
                  }
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  //HELPERS
  const requestHelper = (request) => {
    request({
      pathParams: selectedData,
      logic(response) {
        setAnotherCall(!isAnotherCall);
        toastAlert(response.message);
        setShowConfirmationModal({ preview: false, status: INACTIVE });
        setSelectedData(null);
      },
    });
  };

  //HANDLERS

  const handleConfirmModal = (data, status = INACTIVE) => {
    setSelectedData(data);
    setShowConfirmationModal({ preview: true, status });
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal({ preview: false, status: INACTIVE });
  };

  const handleDeleteData = () => {
    requestHelper(
      showConfirmationModal.status === DELETE ? deleteRole : changeStatus
    );
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <ButtonComponent
                isLink
                link={ADVISORY_ROLE_ROUTE.CREATE}
                text={TABLE.BTN}
              />
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody "
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>{TABLE.TITLE}</Title>
                </div>
                <ButtonComponent
                  onClick={filterHandler}
                  text={FILTER.TITLE}
                  icon={<FilterFilled />}
                />
              </div>
              <div className="unwrap-table">
                <DataTable
                  isLoading={isLoading}
                  data={list}
                  columns={columns}
                  pagination={{
                    ...paginationConfig,
                    onChange: handlePageChange,
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <ConfirmationModal
        modalPreview={showConfirmationModal.preview}
        handleClose={handleConfirmationModalClose}
        title={CONFIRMATION_MODAL[showConfirmationModal.status + "_TITLE"]}
        description={
          CONFIRMATION_MODAL[showConfirmationModal.status + "_DESCRIPTION"]
        }
        handelConfirm={handleDeleteData}
        isLoading={deleteLoader}
      />
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
    </>
  );
}

export default AdvisoryRoles;
