import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from "../constants";
import AdminSharedLayout from "../sharedLayout";

const DashboardPrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.user.data);
  useEffect(() => {
    if (!accessToken) {
      navigate(LOGIN_ROUTE);
    }
  }, [accessToken]);

  if (!accessToken) return "";
  return <AdminSharedLayout>{children}</AdminSharedLayout>;
};
const AuthPrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.user.data);
  useEffect(() => {
    if (accessToken) {
      navigate(DASHBOARD_ROUTE);
    }
  }, [accessToken]);

  return children;
};
export { DashboardPrivateRoute, AuthPrivateRoute };
