// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  data: null,
  types: [],
};

const ChecklistTemplateReducer = createSlice({
  name: "checklisttemplate",
  initialState,
  reducers: {
    // CREATE TEMPLATE
    createTemplateRequest() {},

    // UPDATE TEMPLATE
    updateTemplateRequest() {},

    // DELETE TEMPLATE
    deleteTemplateRequest() {},

    // GET TEMPLATE
    getTemplateListRequest() {},
    getTemplateListSuccess(state, action) {
      state.list = action.payload;
    },

    // GET TEMPLATE DETAIL
    getTemplateDetailRequest() {},
    getTemplateDetailSuccess(state, action) {
      state.data = action.payload;
    },

    // GET CHECKLIST TYPES
    getChecklistTypesRequest() {},
    getChecklistTypesSuccess(state, action) {
      state.types = action.payload;
    },
  },
});

export const {
  createTemplateRequest,
  updateTemplateRequest,
  deleteTemplateRequest,
  getTemplateListRequest,
  getTemplateListSuccess,
  getTemplateDetailRequest,
  getTemplateDetailSuccess,
  getChecklistTypesRequest,
  getChecklistTypesSuccess,
} = ChecklistTemplateReducer.actions;

export default ChecklistTemplateReducer.reducer;
