import { combineReducers } from "@reduxjs/toolkit";
import general from "./general";
import user from "./auth";
import users from "./user";
import transactiontypes from "./transactiontype";
import enterprises from "./enterprise";
import advisoryroles from "./advisoryroles";
import partyroles from "./partyroles";
import questions from "./questions";
import resources from "./resources";
import checklisttemplate from "./template";
import contact from "./contact";
import reviews from "./reviews";

export default combineReducers({
  general,
  users,
  transactiontypes,
  enterprises,
  user,
  advisoryroles,
  partyroles,
  questions,
  resources,
  checklisttemplate,
  contact,
  reviews,
});
