import { take, put, call, fork } from "redux-saga/effects";
import { ALERT_TYPES } from "../../constants";
import {
  callRequest,
  CREATE_CHECKLIST_TEMPLATE,
  UPDATE_CHECKLIST_TEMPLATE,
  DELETE_CHECKLIST_TEMPLATE,
  GET_CHECKLIST_TEMPLATES,
  GET_CHECKLIST_TEMPLATE_DETAIL,
  GET_CHECKLIST_TYPES,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";
import {
  createTemplateRequest,
  updateTemplateRequest,
  deleteTemplateRequest,
  getTemplateDetailRequest,
  getTemplateDetailSuccess,
  getTemplateListRequest,
  getTemplateListSuccess,
  getChecklistTypesRequest,
  getChecklistTypesSuccess,
} from "../slicers/template";

function* createTemplate() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createTemplateRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_CHECKLIST_TEMPLATE,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateTemplate() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateTemplateRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_CHECKLIST_TEMPLATE,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteTemplate() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteTemplateRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_CHECKLIST_TEMPLATE,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getTemplates() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getTemplateListRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_CHECKLIST_TEMPLATES,
        queryParams,
      });
      if (response.status) {
        yield put(getTemplateListSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getTemplateDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getTemplateDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_CHECKLIST_TEMPLATE_DETAIL,
        pathParams,
      });
      if (response.status) {
        yield put(getTemplateDetailSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getChecklistTypes() {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getChecklistTypesRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_CHECKLIST_TYPES,
      });
      if (response.status) {
        yield put(getChecklistTypesSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
export default function* root() {
  yield fork(createTemplate);
  yield fork(updateTemplate);
  yield fork(deleteTemplate);
  yield fork(getTemplates);
  yield fork(getTemplateDetail);
  yield fork(getChecklistTypes);
}
