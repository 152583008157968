import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { toastAlert, imageValidation } from "../../services/utils";
import { useCustomDispatch } from "../../helper/customDispatch";
import { uploadImageRequest } from "../../redux/slicers/general";
import { ALERT_TYPES, ENTERPIRSE_PREFIX } from "../../constants";

const ImageUploader = ({
  setImage,
  setPreviewImage,
  directory = ENTERPIRSE_PREFIX,
}) => {
  const [uploadImage, isLoading] = useCustomDispatch(uploadImageRequest);

  const setErrorToggle = (error) => {
    toastAlert(error, ALERT_TYPES.ERROR);
  };

  const handleChange = (e) => {
    if (isLoading) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (imageValidation(file, setErrorToggle)) {
        const data = new FormData();
        data.append("file", file);
        data.append("directory", directory);
        uploadImage({
          payload: data,
          logic({ key, url }) {
            setImage(key);
            setPreviewImage(url);
          },
        });
      }
    }
  };
  return (
    <div className="upload-box">
      <input type="file" onChange={handleChange} value="" />
      <span className="label">
        {isLoading ? <BeatLoader color="#6F7CED" size={12} /> : "Upload photo"}
      </span>
    </div>
  );
};

export default ImageUploader;
