import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  getContactsListRequest,
  deleteContactRequest,
} from "../../../redux/slicers/contact";
import { Card, Typography, Col, Row, Tooltip, Form } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { DataTable, ConfirmationModal } from "../../../components";
import { Link } from "react-router-dom";
import {
  WEB_STRINGS,
  TABLE_PERPAGE_SIZE,
  DATE_TIME,
  CONTACT_US_ROUTE,
} from "../../../constants";
import { getFormattedDateTime, toastAlert } from "../../../services/utils";
import PhoneInput from "react-phone-input-2";

const { Title } = Typography;

function Contacts() {
  // WEBSTRING
  const { TABLE, CONFIRMATION_MODAL } = WEB_STRINGS.CONTACTS;
  const { COLUMN, TOOLTIP } = TABLE;

  // STATES
  const [isDataUpdated, setDataUpdated] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  // REDUX DATA
  const { list: data } = useSelector((state) => state.contact);

  // DISPATCH CALLS
  const [getContacts, isDataLoading] = useCustomDispatch(
    getContactsListRequest
  );
  const [deleteContact, blockLoading] = useCustomDispatch(deleteContactRequest);

  // CONST VALS
  const paginationConfig = {
    pageSize: TABLE_PERPAGE_SIZE,
    total: total,
  };

  //HANDLERS
  const handleConfirmModal = (data) => {
    setSelectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };
  const handleDeleteData = () => {
    deleteContact({
      pathParams: selectedData,
      logic(res) {
        toastAlert(res.message);
        setShowConfirmationModal(false);
        setDataUpdated(!isDataUpdated);
      },
    });
  };
  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  // HOOKS
  useEffect(() => {
    const queryParams = {
      limit: TABLE_PERPAGE_SIZE,
      offset: (page - 1) * TABLE_PERPAGE_SIZE,
    };
    getContacts({
      queryParams,
      logic(response) {
        setTotal(response.total);
      },
    });
  }, [page, isDataUpdated]);

  // TABLE COLUMNS
  const columns = [
    {
      title: COLUMN.NO,
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * TABLE_PERPAGE_SIZE) + (i + 1)}</b>
      ),
    },
    {
      title: COLUMN.NAME,
      dataIndex: "",
      key: "2",
      render: (data) => {
        return (
          <span className="data-elipse" style={{ width: 200 }}>
            {data.firstName + " " + data.lastName}
          </span>
        );
      },
    },
    {
      title: COLUMN.EMAIL,
      dataIndex: "email",
      key: "3",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      title: COLUMN.PHONE,
      dataIndex: "phone",
      key: "4",
      render: (data) => {
        return (
          <span>
            <PhoneInput containerClass="list-num-input" value={data} disabled />
          </span>
        );
      },
    },
    {
      title: COLUMN.CREATED,
      dataIndex: "createdAt",
      key: "4",
      render: (data) => {
        return <span>{getFormattedDateTime(data, DATE_TIME)}</span>;
      },
    },
    {
      title: COLUMN.ACTION,
      key: "5",
      render: (data) => {
        return (
          <div className="actions">
            <Link
              to={CONTACT_US_ROUTE.DETAIL.replace(":dataid", data.id)}
              className="update">
              <Tooltip title={TOOLTIP.DETAIL}>
                <EyeOutlined />
              </Tooltip>
            </Link>
            <span style={{ width: 25 }}>
              <Tooltip title={TOOLTIP.DELETE}>
                <DeleteOutlined
                  // style={{ height: 17 }}
                  onClick={() => handleConfirmModal(data.id)}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}>
              <div className="project-ant">
                <div>
                  <Title level={5}>{TABLE.TITLE}</Title>
                </div>
              </div>
              <DataTable
                isLoading={isDataLoading}
                data={data}
                columns={columns}
                pagination={{
                  ...paginationConfig,
                  onChange: handlePageChange,
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <ConfirmationModal
        handleClose={handleConfirmationModalClose}
        modalPreview={showConfirmationModal}
        title={CONFIRMATION_MODAL.TITLE}
        description={CONFIRMATION_MODAL.DESCRIPTION}
        handelConfirm={handleDeleteData}
        isLoading={blockLoading}
      />
    </>
  );
}

export default Contacts;
