// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  data: null,
};

const ReviewsReducer = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    // CREATE REVIEW
    createReviewRequest() {},

    // UPDATE REVIEW
    updateReviewRequest() {},

    // DELETE REVIEW
    deleteReviewRequest() {},

    // GET REVIEW
    getReviewListRequest() {},
    getReviewListSuccess(state, action) {
      state.list = action.payload;
    },

    // GET REVIEW DETAIL
    getReviewDetailRequest() {},
    getReviewDetailSuccess(state, action) {
      state.data = action.payload;
    },
  },
});

export const {
  createReviewRequest,
  updateReviewRequest,
  deleteReviewRequest,
  getReviewDetailRequest,
  getReviewDetailSuccess,
  getReviewListRequest,
  getReviewListSuccess,
} = ReviewsReducer.actions;

export default ReviewsReducer.reducer;
