import React from "react";
import { Col, Row } from "antd";

const questioManipulator = (data) => {
  let questions = [];
  data?.answers?.forEach((e) => {
    const questionId = e.question?.id;
    const answerobj = {
      options: [{ id: e.id, value: e.answer }],
      id: questionId,
      label: e.question?.question,
    };
    const isExsist = questions.findIndex((x) => x.id === questionId);
    if (isExsist >= 0) {
      questions[isExsist].options.push({ id: e.id, value: e.answer });
    } else {
      questions.push(answerobj);
    }
  });
  return questions;
};
const TemplateDetailView = ({ data }) => {
  const questions = questioManipulator(data);
  return (
    <>
      <Col xs={24} md={12}>
        <label className="form-lbl">Name</label>
        <p className="view-input">{data?.name}</p>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Type</label>
        <p className="view-input">{data?.type?.value}</p>
      </Col>
      <Col xs={24}>
        <label className="form-lbl">Notes</label>
        <p className="view-input">{data?.notes}</p>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Party Role</label>
        <p className="view-input">
          {data?.partyRoles?.map((item, index) => {
            return `${item.name} ${
              index + 1 !== data?.partyRoles.length ? " , " : ""
            }`;
          })}
        </p>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Advisor Role</label>
        <p className="view-input">
          {data?.advisorRole?.map((item, index) => {
            return `${item.name} ${
              index + 1 !== data?.advisorRole.length ? " , " : ""
            }`;
          })}
        </p>
      </Col>
      <Col xs={24}>
        <label className="form-lbl">Transaction Types</label>
        <p className="view-input">
          {data?.transactionTypes?.map((item, index) => {
            return `${item.title} ${
              index + 1 !== data?.transactionTypes.length ? " , " : ""
            }`;
          })}
        </p>
      </Col>
      {questions?.map((item, index) => (
        <React.Fragment key={index}>
          <Col xs={24}>
            <label className="form-lbl">Question {index + 1}</label>
            <p className="view-input">{item?.label}</p>
          </Col>
          {item?.options.map((it, ind) => (
            <Col xs={24} md={12} key={ind}>
              <label className="form-lbl">Option {ind + 1}</label>
              <p className="view-input">{it.value}</p>
            </Col>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default TemplateDetailView;
