import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import { useSelector } from "react-redux";
import { ButtonComponent } from "../../../components";
import { BeatLoader } from "react-spinners";
import {
  DROPDOWN_DATA_SIZE,
  SELECT_PERPAGE_SIZE,
  WEB_STRINGS,
} from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  createTransactionTypeRequest,
  updateTransactionTypeRequest,
} from "../../../redux/slicers/transactiontype";
import { getQuestionsRequest } from "../../../redux/slicers/questions";
import { inputFieldRule, toastAlert } from "../../../services/utils";
const TransactionTypeForm = ({ data, toggle, onSuccess }) => {
  // WEB STRINGS
  const { CREATE_BTN, UPDATE_BTN, CLOSE_BTN, CREATE_TITLE, UPDATE_TITLE } =
    WEB_STRINGS.TRANSACTIONTYPE_FORM;

  // STATES
  const [questionSearchText, setQuestionSearchText] = useState("");
  const [isQuestionLoading, setQuestionLoading] = useState(true);

  // REDUX DATA
  const questions = useSelector((state) => state.questions.list);

  // DISPATCH CALLS
  const [getQuestions, questionLoader] = useCustomDispatch(getQuestionsRequest);
  const [createTransReq, createTransReqLoader] = useCustomDispatch(
    createTransactionTypeRequest
  );
  const [updateTransReq, updateTransReqLoader] = useCustomDispatch(
    updateTransactionTypeRequest
  );

  // DEFAULT CONFIG
  const isLoading = createTransReqLoader || updateTransReqLoader;
  const [form] = Form.useForm();

  // HELPERS
  const getQuestionsHandler = (isSearched) => {
    const queryParams = {
      limit: SELECT_PERPAGE_SIZE,
      offset: 0,
      ids: data?.Question?.id,
    };
    if (isSearched) queryParams["search"] = questionSearchText;

    getQuestions({
      queryParams,
      logic() {
        setQuestionLoading(false);
      },
    });
  };
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic(response) {
        onSuccess();
        toastAlert(response.message);
        toggle();
      },
    });
  };

  //HANDLERS
  const handleFormFinish = ({ title, question }) => {
    const payload = { title, baseQuestionId: question };
    if (data) {
      requestHelper(updateTransReq, payload, data.id);
    } else {
      requestHelper(createTransReq, payload);
    }
  };

  // HOOKS
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        title: data.title,
        question: data?.Question?.id,
      });
    }
  }, [data]);

  useEffect(() => {
    getQuestionsHandler(questionSearchText !== "");
  }, [questionSearchText]);

  const isDataLoading = data && isQuestionLoading;
  return (
    <Modal
      visible={true}
      title={<span>{data ? UPDATE_TITLE : CREATE_TITLE}</span>}
      centered
      onCancel={toggle}
      footer={null}
      className="modal"
    >
      <Form form={form} className="form" onFinish={handleFormFinish}>
        <div className="modal-wrapper">
          {isDataLoading ? (
            <div className="loader-wrapper" style={{ height: 140 }}>
              <BeatLoader color="#6F7CED" />
            </div>
          ) : (
            <div className="modal-body">
              <label className="form-lbl"> Name</label>
              <Form.Item
                name="title"
                rules={inputFieldRule({
                  name: "Name",
                  isMax: true,
                  max: 80,
                })}
              >
                <Input
                  placeholder="Enter transaction type"
                  className="form-input"
                />
              </Form.Item>
              <label className="form-lbl">Base Question</label>
              <Form.Item name="question">
                <Select
                  placeholder="Select base question"
                  name="question"
                  className="form-select"
                  showSearch
                  loading={questionLoader}
                  onSearch={(val) => {
                    setQuestionSearchText(val);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {questions.map((data, index) => (
                    <Select.Option value={data.id} key={index}>
                      {data.question}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
          <div className="modal-footer">
            <label className="close" onClick={toggle}>
              {CLOSE_BTN}
            </label>
            <ButtonComponent
              isLoading={isLoading}
              disabled={isLoading}
              text={data ? UPDATE_BTN : CREATE_BTN}
              style={{ width: 120, marginTop: 10, padding: 7 }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default TransactionTypeForm;
