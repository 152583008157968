import { Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { inputFieldRule, toastAlert } from "../../services/utils";
import ButtonComponent from "../ButtonComponent";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../helper/customDispatch";
import { confirmOtpRequest, resendOtpRequest } from "../../redux/slicers/auth";
import { DASHBOARD_ROUTE } from "../../constants";
import { useNavigate } from "react-router-dom";

function TwoFactorModal({ preview, handleClose, email }) {
  //STATES
  const [enableResendBtn, setEnableResendBtn] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [_otp, setOtp] = useState("");

  //CONST VALS
  const [form] = Form.useForm();
  const navigate = useNavigate();

  //CUSTOM DISPATCH
  const [confirmOtp, confirmLoader] = useCustomDispatch(confirmOtpRequest);
  const [resendOtp] = useCustomDispatch(resendOtpRequest);

  //HANDLERS
  const handleFormFinish = () => {
    const payload = {};
    payload["otp"] = _otp;
    payload["deviceToken"] = uuidv4();
    payload["email"] = email;
    confirmOtp({
      payload,
      logic(response) {
        closeHandler();
        toastAlert(response.message);
        navigate(DASHBOARD_ROUTE);
      },
    });
  };

  const sendOtpHandler = () => {
    resendOtp({
      payload: { email },
      logic(res) {
        setEnableResendBtn(false);
        setSeconds(60);
      },
    });
  };

  const closeHandler = () => {
    setSeconds(60);
    handleClose();
    setOtp("");
    form.resetFields();
  };

  //HOOKS
  useEffect(() => {
    if (preview) {
      if (seconds === 0) return setEnableResendBtn(true);
      const interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds, preview]);

  useEffect(() => {
    if (_otp?.length === 6) {
      handleFormFinish();
    }
  }, [_otp]);

  return (
    <Modal
      visible={preview}
      title={<span>Two-Factor Authentication</span>}
      centered
      onCancel={closeHandler}
      footer={null}
      className="modal"
    >
      <Form form={form} className="form" onFinish={handleFormFinish}>
        <div className="modal-wrapper">
          <div className="modal-body">
            <label className="form-lbl">
              Please enter 6 digit code sent to you
            </label>
            <Form.Item
              name="otp"
              rules={inputFieldRule({
                name: "Otp",
                isWhiteSpace: false,
              })}
            >
              <OtpInput
                isInputNum={true}
                shouldAutoFocus={true}
                value={_otp}
                className={`otpCodeInput custom-otp-input`}
                onChange={(e) => setOtp(e)}
                numInputs={6}
                separator={<span></span>}
              />
            </Form.Item>
            <div className="resend-wrapper">
              <span
                onClick={() => {
                  sendOtpHandler();
                }}
                style={
                  enableResendBtn
                    ? {}
                    : {
                        pointerEvents: "none",
                        opacity: 0.5,
                      }
                }
                className="resend-text"
              >
                Resend Code
              </span>
              <span>00:{`${seconds}`.padStart(2, 0)}</span>
            </div>
          </div>
          <div className="modal-footer">
            <ButtonComponent
              isLoading={confirmLoader}
              disabled={confirmLoader}
              text="Confirm"
              style={{ width: 120, marginTop: 10, padding: 7 }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default TwoFactorModal;
