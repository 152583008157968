import { call, fork, put, take } from "redux-saga/effects";
import {
  BLOCK_UNBLOCK_USER,
  callRequest,
  GET_ONE_USER,
  GET_USERS_LIST,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../services/utils";
import {
  blockUnblockUserRequest,
  blockUnblockUserSuccess,
  getOneUserRequest,
  getOneUserSuccess,
  getUsersListRequest,
  getUsersListSuccess,
} from "../slicers/user";

function* getUsersList() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(getUsersListRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_USERS_LIST,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(getUsersListSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getOneUser() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(getOneUserRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_ONE_USER,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(getOneUserSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* blockUnblockUser() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(blockUnblockUserRequest.type);

    try {
      const response = yield call(callRequest, {
        url: BLOCK_UNBLOCK_USER,
        queryParams,
      });
      if (response.status) {
        yield put(blockUnblockUserSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(getUsersList);
  yield fork(getOneUser);
  yield fork(blockUnblockUser);
}
