import React from "react";
import { Form, Input, Select } from "antd";
import { WEB_STRINGS } from "../../../constants";

function TemplateFilter({
  transactiontypes,
  isTypesLoading,
  setTypeSearchText,
  checklistTypes,
  checklisttypeLoader,
}) {
  const { NAME, TRANSACTION_TYPE, CHECKLIST_TYPE } =
    WEB_STRINGS.CHECKLIST_TEMPLATES.FILTER.FIELDS;
  return (
    <>
      <label className="form-lbl">{NAME.LABEL}</label>
      <Form.Item name={NAME.NAME} rules={NAME.RULES}>
        <Input
          name={NAME.NAME}
          placeholder={NAME.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">{CHECKLIST_TYPE.LABEL}</label>
      <Form.Item name={CHECKLIST_TYPE.NAME}>
        <Select
          placeholder={CHECKLIST_TYPE.PLACEHOLDER}
          name={CHECKLIST_TYPE.NAME}
          className="form-select"
          loading={checklisttypeLoader}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }>
          {checklistTypes?.map((data, index) => (
            <Select.Option value={data.key} key={index}>
              {data.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/* <label className="form-lbl">{TRANSACTION_TYPE.LABEL}</label>
      <Form.Item name={TRANSACTION_TYPE.NAME}>
        <Select
          placeholder={TRANSACTION_TYPE.PLACEHOLDER}
          name={TRANSACTION_TYPE.NAME}
          className="form-select"
          showSearch
          loading={isTypesLoading}
          onSearch={(val) => {
            setTypeSearchText(val);
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }>
          {transactiontypes?.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}
    </>
  );
}

export default TemplateFilter;
