import React from "react";
import { useSelector } from "react-redux";
import { Typography, Tooltip } from "antd";
import { DataTable, ProfileImage } from "../../../../components";
import { WEB_STRINGS, USERS_ROUTE } from "../../../../constants";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
function DashboardUsers({ isLoading }) {
  const { Title } = Typography;
  const { TABLE } = WEB_STRINGS.USERS;
  const { COLUMN, TOOLTIP } = TABLE;

  // REDUX DATA
  const { usersList } = useSelector((state) => state.users);

  const columns = [
    {
      title: COLUMN.NO,
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => <b>{i + 1}</b>,
    },
    {
      title: COLUMN.NAME,
      dataIndex: "",
      key: "2",
      render: (data) => {
        return (
          <span className="tbl-detail-box sm">
            <ProfileImage
              size={10}
              color={data?.bgcolor}
              image={data?.image}
              username={data.firstName + " " + data?.lastName}
            />
            <span className="detail">
              {data.firstName} {data?.lastName}
            </span>
          </span>
        );
      },
    },
    {
      title: COLUMN.EMAIL,
      dataIndex: "email",
      key: "3",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      title: COLUMN.STATUS,
      dataIndex: "isActive",
      key: "7",
      render: (data, value) => {
        return <span>{data ? "Active" : "Inactive"}</span>;
      },
    },
    {
      title: COLUMN.ACTION,
      dataIndex: "",
      key: "6",
      render: (data, value) => {
        return (
          <div className="actions" style={{ paddingLeft: 12 }}>
            <Link
              to={USERS_ROUTE.DETAIL.replace(":dataId", data.id)}
              className="update">
              <Tooltip title={TOOLTIP.DETAIL}>
                <EyeOutlined />
              </Tooltip>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="project-ant" style={{ marginBottom: 10 }}>
        <Title level={5}>{TABLE.TITLE}</Title>
      </div>
      <DataTable
        isLoading={isLoading}
        data={usersList}
        columns={columns}
        pagination={false}
      />
    </>
  );
}

export default DashboardUsers;
