// @flow
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissions: [],
};

const GeneralReducer = createSlice({
  name: "general",
  initialState,
  reducers: {
    // UPLOAD IMAGE
    uploadImageRequest() {},

    getDashboardStatsRequest() {},

    getPermissionsRequest() {},
    getPermissionsSuccess(state, action) {
      state.permissions = action.payload;
    },
  },
});

export const {
  uploadImageRequest,
  getPermissionsRequest,
  getDashboardStatsRequest,
  getPermissionsSuccess,
} = GeneralReducer.actions;

export default GeneralReducer.reducer;
