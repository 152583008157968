import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  FilterFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import { Card, Col, Form, Input, Row, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  ButtonComponent,
  ConfirmationModal,
  DataTable,
  FilterDrawer,
} from "../../../components";
import {
  WEB_STRINGS,
  TABLE_PERPAGE_SIZE,
  DATE_TIME,
  RESOURCES_ROUTE,
} from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  getFileListRequest,
  deleteFileRequest,
  getFolderDetailRequest,
  deleteFolderRequest,
} from "../../../redux/slicers/resources";
import { getFormattedDateTime, toastAlert } from "../../../services/utils";
import FileForm from "./fileform";
import FolderForm from "./folderform";
import { BeatLoader } from "react-spinners";
import { downloadImage } from "../../../helper/downloadImage";

const { Title } = Typography;

function ResourcesFolderDetail() {
  // WEBSTRING
  const {
    FILTER,
    TABLE,
    UPDATE_BTN,
    DELETE_BTN,
    CONFIRMATION_MODAL: FILE_MODAL,
  } = WEB_STRINGS.RESOURCES_DETAIL;
  const { CONFIRMATION_MODAL: RESOURCE_MODAL } = WEB_STRINGS.RESOURCES;
  const { NAME } = FILTER.FIELDS;
  const { COLUMN, TOOLTIP } = TABLE;

  // STATES
  const [selectedData, setSelectedData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isAnotherCall, setAnotherCall] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [uploadFileModalPreview, setUploadFileModalPreview] = useState(false);
  const [updateFolderModalPreview, setupdateFolderModalPreview] =
    useState(false);

  // REDUX DATA
  const { fileList, folderdata } = useSelector((state) => state.resources);

  // DISPATCH CALLS
  const [getFolderDetail, folderLoading] = useCustomDispatch(
    getFolderDetailRequest
  );
  const [getFileListReq, isLoading] = useCustomDispatch(getFileListRequest);
  const [deleteFile, filedeleteLoader] = useCustomDispatch(deleteFileRequest);
  const [deleteFolder, folderdeleteLoader] =
    useCustomDispatch(deleteFolderRequest);

  //CONST VALS
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { dataid } = useParams();
  const deleteLoader = filedeleteLoader || folderdeleteLoader;
  const CONFIRMATION_MODAL = showDeleteFolderModal
    ? RESOURCE_MODAL
    : FILE_MODAL;
  const paginationConfig = {
    pageSize: TABLE_PERPAGE_SIZE,
    total: total,
  };

  // HOOKS
  useEffect(() => {
    const queryParams = {
      limit: TABLE_PERPAGE_SIZE,
      offset: (page - 1) * TABLE_PERPAGE_SIZE,
      ...filteredData,
    };

    getFileListReq({
      queryParams,
      pathParams: dataid,
      logic(response) {
        setTotal(response.total);
        setFilterOpen(false);
      },
    });
  }, [page, isAnotherCall, filteredData]);

  useEffect(() => {
    if (uploadFileModalPreview) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [uploadFileModalPreview]);

  useEffect(() => {
    getFolderDetail({
      pathParams: dataid,
    });
  }, []);

  // CUSTOM COMPONENT
  const drawerSec = () => (
    <Form form={form} className="form" onFinish={handleFormSubmit}>
      <label className="form-lbl">{NAME.LABEL}</label>
      <Form.Item name={NAME.NAME}>
        <Input
          name={NAME.NAME}
          placeholder={NAME.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>

      <Form.Item style={{ marginTop: 25 }}>
        <ButtonComponent
          text={FILTER.BTN}
          isLoading={isLoading}
          className="w-100"
        />
      </Form.Item>
      <span className="form-rm-btn" onClick={handleResetForm}>
        Reset Filters
      </span>
    </Form>
  );

  // TABLE COLUMN
  const columns = [
    {
      title: COLUMN.NO,
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * TABLE_PERPAGE_SIZE) + (i + 1)}</b>
      ),
    },
    {
      title: COLUMN.NAME,
      dataIndex: "filename",
      key: "2",
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },
    {
      title: COLUMN.CREATED,
      dataIndex: "createdAt",
      key: "3",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data, DATE_TIME)}</span>;
      },
    },
    {
      title: COLUMN.ACTION,
      key: "4",
      render: (data, record) => {
        return (
          <div className="actions">
            <span className="update">
              <Tooltip title={TOOLTIP.DOWNLOAD}>
                <DownloadOutlined onClick={() => downloadFileHandler(data)} />
              </Tooltip>
            </span>
            <span className="update">
              <Tooltip title={TOOLTIP.EDIT}>
                <EditOutlined onClick={() => handleUploadFileModal(data)} />
              </Tooltip>
            </span>
            <span>
              <Tooltip title={TOOLTIP.DELETE}>
                <DeleteOutlined
                  onClick={() => handleConfirmFileDeleteModal(data.id)}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  //HANDLERS
  const handleFormSubmit = () => {
    const credentials = form.getFieldsValue();
    Object.keys(credentials).forEach(
      (key) => credentials[key] === undefined && delete credentials[key]
    );
    setFilteredData(credentials);
  };

  const handleResetForm = () => {
    setFilteredData(null);
    form.resetFields();
    setFilterOpen(false);
  };

  const handleUploadFileModal = (data) => {
    setSelectedData(data);
    setUploadFileModalPreview(true);
  };

  const handleUploadFileModalClose = () => {
    setUploadFileModalPreview(false);
    setSelectedData(null);
  };

  const handleConfirmFileDeleteModal = (data) => {
    setSelectedData(data);
    setShowDeleteFileModal(true);
  };

  const handleConfirmFolderDeleteModal = () => {
    setShowDeleteFolderModal(!showDeleteFolderModal);
  };

  const handleUpdateFolderModal = () => {
    setupdateFolderModalPreview(!updateFolderModalPreview);
  };

  const handleConfirmationModalClose = () => {
    setShowDeleteFileModal(false);
    setShowDeleteFolderModal(false);
  };

  const handleDeleteFolder = () => {
    deleteFolder({
      pathParams: folderdata?.slug,
      logic(response) {
        toastAlert(response.message);
        setShowDeleteFolderModal(false);
        navigate(RESOURCES_ROUTE.GET);
      },
    });
  };

  const handleDeleteFile = () => {
    deleteFile({
      pathParams: selectedData,
      logic(response) {
        setAnotherCall(!isAnotherCall);
        toastAlert(response.message);
        setShowDeleteFileModal(false);
        setSelectedData(null);
      },
    });
  };

  const handleDeleteData = () => {
    showDeleteFolderModal ? handleDeleteFolder() : handleDeleteFile();
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  const downloadFileHandler = (data) => {
    downloadImage(data.url);
  };

  return (
    <>
      <div className="layout-content">
        {folderLoading ? (
          <div className="loader-wrapper" style={{ height: "75vh" }}>
            <BeatLoader color="#6F7CED" size={18} />
          </div>
        ) : (
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <div className="add-btn">
                <ButtonComponent
                  onClick={() => {
                    setUploadFileModalPreview(true);
                  }}
                  text={TABLE.BTN}
                />
                <button
                  className="block-btn un"
                  onClick={handleUpdateFolderModal}
                  style={{ marginLeft: 10 }}>
                  {UPDATE_BTN}
                </button>
                <button
                  className="block-btn"
                  onClick={handleConfirmFolderDeleteModal}
                  style={{ marginLeft: 10 }}>
                  {DELETE_BTN}
                </button>
              </div>
              <Card
                bordered={false}
                className="criclebox cardbody "
                style={{ minHeight: 360 }}>
                <div className="project-ant">
                  <div>
                    <Title level={5}>{TABLE.TITLE}</Title>
                  </div>
                  <ButtonComponent
                    onClick={filterHandler}
                    text={FILTER.TITLE}
                    icon={<FilterFilled />}
                  />
                </div>
                <div className="unwrap-table">
                  <DataTable
                    isLoading={isLoading}
                    data={fileList}
                    columns={columns}
                    pagination={{
                      ...paginationConfig,
                      onChange: handlePageChange,
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
      {uploadFileModalPreview && (
        <FileForm
          toggle={handleUploadFileModalClose}
          data={selectedData}
          onSuccess={() => {
            setAnotherCall(!isAnotherCall);
          }}
        />
      )}
      {updateFolderModalPreview && (
        <FolderForm toggle={handleUpdateFolderModal} data={folderdata} />
      )}
      <ConfirmationModal
        modalPreview={showDeleteFileModal || showDeleteFolderModal}
        handleClose={handleConfirmationModalClose}
        title={CONFIRMATION_MODAL.TITLE}
        description={CONFIRMATION_MODAL.DESCRIPTION}
        handelConfirm={handleDeleteData}
        isLoading={deleteLoader}
      />
    </>
  );
}

export default ResourcesFolderDetail;
