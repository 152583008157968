import {
  faSignOutAlt,
  faCog,
  faTag,
  faUsers,
  faProjectDiagram,
  faQuestion,
  faChartLine,
  faBuilding,
  faExchangeAlt,
  faFlag,
  faFolder,
  faTh,
  faStar,
  faMailBulk,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import {
  AdminDashboard,
  SignIn,
  Users,
  UsersForm,
  ForgotPass,
  ResetPassword,
  Enterprises,
  EnterprisesForm,
  TransactionType,
  Questions,
  QuestionForm,
  AdvisoryRoles,
  AdvisoryRoleForm,
  PartyRoles,
  PartRoleForm,
  Resources,
  ResourcesFolderDetail,
  ChecklistTemplates,
  ChecklistTemplateForm,
  Contacts,
  ContactDetail,
  Reviews,
  ReviewForm,
} from "../modules";

export const ALERT_TIMEOUT = 3000;
export const DEV_ENV = "dev";
export const PROD_ENV = "prod";
export const API_LOG = process.env.REACT_APP_ENV === DEV_ENV;
export const API_TIMEOUT = 30000;

export const IMAGE_HOST = "https://dowabh634chzq.cloudfront.net/";
export const DEFAULT_IMAGE = "user-default-image.png";
export const DEFAULT_COMPANY_LOGO = "enterprise_default_logo.png";
export const RESOURCE_PREFIX = "resources";
export const ENTERPIRSE_PREFIX = "enterprises";
export const REVIEWS_PREFIX = "reviews";
export const DEFAULT_PROFILE_COLOR = "#6f7ced";

// DATE FORMATS
export const DATE_FORMAT1 = "DD MMM, YYYY";
export const DATE_FORMAT2 = "DD, MMM YY";
export const DATE_FORMAT3 = "YYYY-MM-DD";
export const DATE_FORMAT4 = "DD-MM-YYYY";
export const TIME_FORMAT1 = "hh:mm a";
export const TIME_FORMAT2 = "hh:mm";
export const TIME_FORMAT3 = "hh";
export const DATE_TIME = "DD-MM-YYYY hh:mm a";

// SUCCESS ALERTS
export const SUCCESS_ALERTS = {};

// TABLE PERPAGE SIZE
export const TABLE_PERPAGE_SIZE = 10;
export const SELECT_PERPAGE_SIZE = 100;

// DROPDOWN PERPAGE SIZE
export const DROPDOWN_DATA_SIZE = 30;

// DASHOBOARD PAGES CONFIG
export const ACCESS_TYPES = {
  PRIVATE: "private",
  AUTH: "auth",
};
// DASHOBOARD ROLES CONFIG
export const ROLES_ACCESS_TYPES = {
  ADMIN: "admin",
  PROJECT_MANAGER: "project_manager",
};
// ALERT CONFIG
export const ALERT_POSITIONS = {
  TOP_RIGHT: "top-right",
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_CENTER: "bottom-center",
};
export const ALERT_THEMES = {
  DARK: "dark",
  COLORED: "colored",
  LIGHT: "light",
};
export const ALERT_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  DEFAULT: "default",
};

// AUTH ROUTE
export const LOGIN_ROUTE = "/";
export const FORGETPASS_ROUTE = "/forget-password";
export const RESETPASS_ROUTE = "/reset-password/:token";
// DASHBOARD ROUTE
export const DASHBOARD_ROUTE = "/dashboard";
// USERS ROUTES
export const USERS_ROUTE = {
  GET: "/users",
  CREATE: "/create-user",
  UPDATE: "/update-user/:id",
  DETAIL: "/user-detail/:dataId",
};
// ENTERPRISES ROUTES
export const ENTERPRISES_ROUTE = {
  GET: "/enterprises",
  CREATE: "/create-enterprise",
  UPDATE: "/update-enterprise/:id",
  DETAIL: "/enterprise-detail/:dataid",
};
// TRANSACTION TYPE ROUTE
export const TRANSACTION_TYPE_ROUTE = "/transaction-types";

// ADVISORY ROLE ROUTE
export const ADVISORY_ROLE_ROUTE = {
  GET: "/advisory-roles",
  CREATE: "/create-advisory-role",
  UPDATE: "/update-advisory-role/:id",
  DETAIL: "/advisory-roles-detail/:dataid",
};

// PARTY ROLE ROUTE
export const PARTY_ROLE_ROUTE = {
  GET: "/party-roles",
  CREATE: "/create-party-role",
  UPDATE: "/update-party-role/:id",
  DETAIL: "/party-roles-detail/:dataid",
};

// QUESTIONS ROUTES
export const QUESTIONS_ROUTE = {
  GET: "/questions",
  CREATE: "/create-question",
  UPDATE: "/update-question/:id",
  DETAIL: "/question-detail/:dataid",
};

// RESOURCES ROUTES
export const RESOURCES_ROUTE = {
  GET: "/resources",
  DETAIL: "/resource-detail/:dataid",
};

// CHECKLIST TEMPLATES ROUTES
export const CHECKLIST_TEMPLATES_ROUTE = {
  GET: "/checklist-templates",
  CREATE: "/create-checklist-templates",
  UPDATE: "/update-checklist-templates/:id",
  DETAIL: "/checklist-template/:dataid",
};

//REVIEWS ROUTES
export const REVIEWS_ROUTE = {
  GET: "/reviews",
  CREATE: "/create-review",
  UPDATE: "/update-review/:id",
  DETAIL: "/review/:dataid",
};

//CONTACT US ROUTES
export const CONTACT_US_ROUTE = {
  GET: "/contact-us",
  DETAIL: "/contact-us/:dataid",
};

export const PAGE_ROUTES = [
  // DASHBOARD ROUTE
  {
    title: "Dashboard",
    route: DASHBOARD_ROUTE,
    access: ACCESS_TYPES.PRIVATE,
    component: <AdminDashboard />,
  },
  // AUTH ROUTE
  {
    title: "Login",
    route: LOGIN_ROUTE,
    access: ACCESS_TYPES.AUTH,
    component: <SignIn />,
  },
  {
    title: "Forgot Password",
    route: FORGETPASS_ROUTE,
    access: ACCESS_TYPES.AUTH,
    component: <ForgotPass />,
  },
  {
    title: "Reset Password",
    route: RESETPASS_ROUTE,
    access: ACCESS_TYPES.AUTH,
    component: <ResetPassword />,
  },
  // USERS ROUTE
  {
    title: "Users",
    route: USERS_ROUTE.GET,
    access: ACCESS_TYPES.PRIVATE,
    component: <Users />,
  },
  {
    title: "Create User",
    route: USERS_ROUTE.CREATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <UsersForm />,
  },
  {
    title: "Update User",
    route: USERS_ROUTE.UPDATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <UsersForm />,
  },
  {
    title: "User Detail",
    route: USERS_ROUTE.DETAIL,
    access: ACCESS_TYPES.PRIVATE,
    component: <UsersForm />,
  },
  // ENTERPRISES ROUTE
  {
    title: "Enterprises",
    route: ENTERPRISES_ROUTE.GET,
    access: ACCESS_TYPES.PRIVATE,
    component: <Enterprises />,
  },
  {
    title: "Create Enterprise",
    route: ENTERPRISES_ROUTE.CREATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <EnterprisesForm />,
  },
  {
    title: "Update Enterprise",
    route: ENTERPRISES_ROUTE.UPDATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <EnterprisesForm />,
  },
  {
    title: "Enterprise Detail",
    route: ENTERPRISES_ROUTE.DETAIL,
    access: ACCESS_TYPES.PRIVATE,
    component: <EnterprisesForm />,
  },
  // TRANSACTION TYPE ROUTE
  {
    title: "Transaction types",
    route: TRANSACTION_TYPE_ROUTE,
    access: ACCESS_TYPES.PRIVATE,
    component: <TransactionType />,
  },
  // ADVISORY ROLE ROUTE
  {
    title: "Advisory roles",
    route: ADVISORY_ROLE_ROUTE.GET,
    access: ACCESS_TYPES.PRIVATE,
    component: <AdvisoryRoles />,
  },
  {
    title: "Create advisory role",
    route: ADVISORY_ROLE_ROUTE.CREATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <AdvisoryRoleForm />,
  },
  {
    title: "Update advisory roles",
    route: ADVISORY_ROLE_ROUTE.UPDATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <AdvisoryRoleForm />,
  },
  {
    title: "Advisory role detail",
    route: ADVISORY_ROLE_ROUTE.DETAIL,
    access: ACCESS_TYPES.PRIVATE,
    component: <AdvisoryRoleForm />,
  },
  // PARTY ROLE ROUTE
  {
    title: "Party roles",
    route: PARTY_ROLE_ROUTE.GET,
    access: ACCESS_TYPES.PRIVATE,
    component: <PartyRoles />,
  },
  {
    title: "Create party role",
    route: PARTY_ROLE_ROUTE.CREATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <PartRoleForm />,
  },
  {
    title: "Update party roles",
    route: PARTY_ROLE_ROUTE.UPDATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <PartRoleForm />,
  },
  {
    title: "Party role detail",
    route: PARTY_ROLE_ROUTE.DETAIL,
    access: ACCESS_TYPES.PRIVATE,
    component: <PartRoleForm />,
  },
  // QUESTIONS ROUTE
  {
    title: "Transaction Questions",
    route: QUESTIONS_ROUTE.GET,
    access: ACCESS_TYPES.PRIVATE,
    component: <Questions />,
  },
  {
    title: "Create Question",
    route: QUESTIONS_ROUTE.CREATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <QuestionForm />,
  },
  {
    title: "Update Question",
    route: QUESTIONS_ROUTE.UPDATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <QuestionForm />,
  },
  {
    title: "Question Detail",
    route: QUESTIONS_ROUTE.DETAIL,
    access: ACCESS_TYPES.PRIVATE,
    component: <QuestionForm />,
  },
  // RESOURCES ROUTE
  {
    title: "Resources",
    route: RESOURCES_ROUTE.GET,
    access: ACCESS_TYPES.PRIVATE,
    component: <Resources />,
  },
  {
    title: "Folder Detail",
    route: RESOURCES_ROUTE.DETAIL,
    access: ACCESS_TYPES.PRIVATE,
    component: <ResourcesFolderDetail />,
  },
  // CHECKLIST TEMPLATE ROUTE
  {
    title: "Checklist Templates",
    route: CHECKLIST_TEMPLATES_ROUTE.GET,
    access: ACCESS_TYPES.PRIVATE,
    component: <ChecklistTemplates />,
  },
  {
    title: "Create Template",
    route: CHECKLIST_TEMPLATES_ROUTE.CREATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <ChecklistTemplateForm />,
  },
  {
    title: "Update Template",
    route: CHECKLIST_TEMPLATES_ROUTE.UPDATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <ChecklistTemplateForm />,
  },
  {
    title: "Template Detail",
    route: CHECKLIST_TEMPLATES_ROUTE.DETAIL,
    access: ACCESS_TYPES.PRIVATE,
    component: <ChecklistTemplateForm />,
  },

  // REVIEW ROUTE
  {
    title: "Reviews",
    route: REVIEWS_ROUTE.GET,
    access: ACCESS_TYPES.PRIVATE,
    component: <Reviews />,
  },
  {
    title: "Create Review",
    route: REVIEWS_ROUTE.CREATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <ReviewForm />,
  },
  {
    title: "Update Review",
    route: REVIEWS_ROUTE.UPDATE,
    access: ACCESS_TYPES.PRIVATE,
    component: <ReviewForm />,
  },
  {
    title: "Review Detail",
    route: REVIEWS_ROUTE.DETAIL,
    access: ACCESS_TYPES.PRIVATE,
    component: <ReviewForm />,
  },

  // CONTACT US ROUTE
  {
    title: "Contacts",
    route: CONTACT_US_ROUTE.GET,
    access: ACCESS_TYPES.PRIVATE,
    component: <Contacts />,
  },
  {
    title: "Contact Detail",
    route: CONTACT_US_ROUTE.DETAIL,
    access: ACCESS_TYPES.PRIVATE,
    component: <ContactDetail />,
  },
];

export const SIDEBAR_LINKS = [
  {
    title: "Dashboard",
    key: "dashboard",
    icon: faChartLine,
    route: DASHBOARD_ROUTE,
  },
  {
    title: "Users",
    key: "user",
    icon: faUsers,
    route: USERS_ROUTE.GET,
  },
  {
    title: "Enterprises",
    key: "enterprise",
    icon: faBuilding,
    route: ENTERPRISES_ROUTE.GET,
  },
  {
    title: "Transaction types",
    key: "transaction-type",
    icon: faProjectDiagram,
    route: TRANSACTION_TYPE_ROUTE,
  },
  {
    title: "Party Roles",
    key: "party",
    icon: faFlag,
    route: PARTY_ROLE_ROUTE.GET,
  },
  {
    title: "Advisory Roles",
    key: "advisory",
    icon: faTag,
    route: ADVISORY_ROLE_ROUTE.GET,
  },
  {
    title: "Questions",
    key: "question",
    icon: faQuestion,
    route: QUESTIONS_ROUTE.GET,
  },
  {
    title: "Resources",
    key: "resource",
    icon: faFolder,
    route: RESOURCES_ROUTE.GET,
  },
  {
    title: "Checklist Templates",
    key: "template",
    icon: faTh,
    route: CHECKLIST_TEMPLATES_ROUTE.GET,
  },
  {
    title: "Reviews",
    key: "review",
    icon: faStar,
    route: REVIEWS_ROUTE.GET,
  },
  {
    title: "Contact Us",
    key: "contact",
    icon: faComment,
    route: CONTACT_US_ROUTE.GET,
  },
];

export const DASHBOARD_ANALYTICS = [
  {
    key: "users",
    title: "Users",
    icon: faUsers,
  },
  {
    key: "enterprises",
    title: "Enterprises",
    icon: faBuilding,
  },
  {
    key: "transactions",
    title: "Transactions",
    icon: faExchangeAlt,
  },
  {
    key: "transactionTypes",
    title: "Transaction Types",
    icon: faProjectDiagram,
  },
];
export const ACTIVE_STATUS_FILTER = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 2,
    name: "Inactive",
  },
];

export const BLOCKED_STATUS_FILTER = [
  {
    id: 1,
    name: "Blocked",
  },
  {
    id: 2,
    name: "Unblocked",
  },
];

export const WEB_STRINGS = {
  HEADER: {
    MESSAGE: "Hello! Welcome Back",
    TITLE: "Dashboard",
    USERMENU: {
      USERNAME: "username",
      ACCOUNT: "Account",
      LOGOUT: "Logout",
      ACCICON: faCog,
      LOGOUTICON: faSignOutAlt,
    },
    NOTI: {
      TITLE: "Notifications",
      NOTI1: "Notification 1",
      NOTI2: "Notification 2",
      NOTI3: "Notification 3",
    },
  },
  ERRORPAGE: {
    TITLE: "404",
    SUBTITLE: "Oops! Page not found",
    DESCRIPTION:
      "The page you are looking was doesn't exsist. You may have mistyped the address or the page may have been moved",
    BUTTON: "Back to Home",
  },
  CONFIRMATION_MODAL: {
    CLOSE: "Close",
    CONFIRM: "Yes, confirm",
  },
  SIGNIN: {
    TITLE: "Login",
    FORGETPASS: "Forgot Password?",
    FIELDS: {
      EMAIL: {
        NAME: "email",
        PLACEHOLDER: "Email Address",
        ERRORMESSAGE: {
          REQUIRED: "Email Address is required",
          TYPE: "Invalid Email Address",
        },
      },
      PASSWORD: {
        NAME: "password",
        PLACEHOLDER: "Password",
        ERRORMESSAGE: {
          REQUIRED: "Password is required",
        },
      },
    },
    BTN: "Login",
  },
  FORGOTPASS: {
    TITLE: "Forget Password",
    BTN: "Send Email",
    FIELDS: {
      EMAIL: {
        NAME: "email",
        PLACEHOLDER: "Email Address",
        ERRORMESSAGE: {
          REQUIRED: "Email Address is required",
          TYPE: "Invalid Email Address",
        },
      },
    },
    EMAILSEND:
      "Please check your inbox, a reset password link has been sent to your email Address",
  },
  RESETPASS: {
    TITLE: "Reset Password",
    BTN: "Reset Password",
    FIELDS: {
      PASSWORD: {
        NAME: "password",
        PLACEHOLDER: "New Password",
        MINLENGTH: 8,
        ERRORMESSAGE: {
          REQUIRED: "Password is required",
          MIN: "Minimum 8 characters",
        },
      },
      CONFIRMPASSWORD: {
        NAME: "confirmpassword",
        PLACEHOLDER: "Confirm New Password",
        minLength: 8,
        ERRORMESSAGE: {
          REQUIRED: "Confirm Password is required",
          MIN: "Minimum 8 characters",
          MATCH: "Password doesn't match",
        },
      },
    },
  },
  USERS: {
    FILTER: {
      TITLE: "Filter",
      FIELDS: {
        NAME: {
          NAME: "name",
          LABEL: "Name:",
          PLACEHOLDER: "Filter by user name",
          RULES: [
            {
              whitespace: true,
              message: "Invalid username",
            },
          ],
        },
        FIRST_NAME: {
          NAME: "firstName",
          LABEL: "First Name:",
          PLACEHOLDER: "Filter by first name",
          RULES: [
            {
              whitespace: true,
              message: "Invalid first name",
            },
          ],
        },
        LAST_NAME: {
          NAME: "lastName",
          LABEL: "Last Name:",
          PLACEHOLDER: "Filter by last name",
          RULES: [
            {
              whitespace: true,
              message: "Invalid last name",
            },
          ],
        },
        EMAIL: {
          NAME: "email",
          LABEL: "Email:",
          PLACEHOLDER: "Filter by email address",
          RULES: [
            {
              type: "email",
              message: "Invalid email address",
            },
          ],
        },
        COMPANY: {
          NAME: "company",
          LABEL: "Enterprise:",
          PLACEHOLDER: "Filter by enterprise",
        },
        ENT_STATUS: {
          NAME: "isActive",
          LABEL: "Status:",
          PLACEHOLDER: "Filter by status",
        },
        BLOCK_STATUS: {
          NAME: "isBlocked",
          LABEL: "Status:",
          PLACEHOLDER: "Filter by status",
        },
      },
      BTN: "Apply Filters",
    },
    TABLE: {
      TITLE: "Users",
      BTN: "Create User",
      COLUMN: {
        NO: "No",
        NAME: "Name",
        EMAIL: "Email",
        PHONE: "Phone No",
        COMPANY: "Enterprise",
        JOB: "Job title",
        ROLE: "Role",
        STATUS: "Status",
        CREATED: "Created At",
        UPDATED: "Updated At",
        ACTION: "Action",
      },
      TOOLTIP: {
        EDIT: "Edit User",
        BLOCK: "Block User",
        UNBLOCK: "Unblock User",
        COMPANY_BLOCK: "Enterprise is blocked",
        DETAIL: "User Detail",
        DELETE: "Delete User",
      },
    },
    CONFIRMATION_MODAL: {
      BLOCK_TITLE: "Block User?",
      UNBLOCK_TITLE: "Unblock User?",
      BLOCK_DESCRIPTION: "Are you sure you want to Block this user?",
      UNBLOCK_DESCRIPTION: "Are you sure you want to Unblock this user?",
    },
  },
  USERS_FORM: {
    EDIT_BTN: "Edit User",
    CREATE_BTN: "Create",
    UPDATE_BTN: "Update",
  },
  ENTERPRISES: {
    FILTER: {
      TITLE: "Filter",
      FIELDS: {
        NAME: {
          NAME: "name",
          LABEL: "Name:",
          PLACEHOLDER: "Filter by enterprise name",
          RULES: [
            {
              whitespace: true,
              message: "Invalid enterprise name",
            },
          ],
        },
        COUNTRY: {
          NAME: "country",
          LABEL: "Country:",
          PLACEHOLDER: "Filter by country",
          RULES: [
            {
              whitespace: true,
              message: "Invalid country",
            },
          ],
        },
        BLOCK_STATUS: {
          NAME: "isBlocked",
          LABEL: "Status:",
          PLACEHOLDER: "Filter by status",
        },
      },
      BTN: "Apply Filters",
    },
    TABLE: {
      TITLE: "Enterprises",
      BTN: "Create Enterprise",
      COLUMN: {
        NO: "No",
        NAME: "Name",
        CITY: "City",
        COUNTRY: "Country",
        STATE: "State",
        PHONE: "Phone Number",
        CREATED: "Created At",
        UPDATED: "Updated At",
        ACTION: "Action",
      },
      TOOLTIP: {
        EDIT: "Edit Enterprise",
        DETAIL: "Enterprise Detail",
        BLOCK: "Block Enterprise",
        UNBLOCK: "Unblock Enterprise",
      },
    },
    CONFIRMATION_MODAL: {
      BLOCK_TITLE: "Block Enterprise?",
      UNBLOCK_TITLE: "Unblock Enterprise?",
      BLOCK_DESCRIPTION: "Are you sure you want to Block this enterprise?",
      UNBLOCK_DESCRIPTION: "Are you sure you want to Unblock this enterprise?",
    },
  },
  ENTERPRISES_FORM: {
    EDIT_BTN: "Edit Enterprise",
    CREATE_BTN: "Create",
    UPDATE_BTN: "Update",
  },
  TRANSACTIONTYPE: {
    FILTER: {
      TITLE: "Filter",
      FIELDS: {
        NAME: {
          NAME: "name",
          LABEL: "Name:",
          PLACEHOLDER: "Filter by user name",
        },
        STATUS: {
          NAME: "status",
          LABEL: "Status:",
          PLACEHOLDER: "Filter by status",
          OPTIONS: [
            {
              key: true,
              value: "Activated",
            },
            {
              key: false,
              value: "Deactivated",
            },
          ],
        },
      },
      BTN: "Apply Filters",
    },
    TABLE: {
      TITLE: "Transaction types",
      BTN: "Create",
      ACTIVE: "ACTIVE",
      INACTIVE: "INACTIVE",
      DELETE: "DELETE",
      COLUMN: {
        NO: "No",
        NAME: "Name",
        BASE_QUESTION: "Base question",
        STATUS: "Status",
        ACTIVE: "activated",
        INACTIVE: "deactivated",
        CREATED: "Created At",
        UPDATED: "Updated At",
        ACTION: "Action",
      },
      TOOLTIP: {
        EDIT: "Edit Type",
        DELETE: "Delete Type",
      },
    },
    CONFIRMATION_MODAL: {
      ACTIVE_TITLE: "Activate Tansaction Type?",
      ACTIVE_DESCRIPTION: "Are you sure you want to activate this type?",
      INACTIVE_TITLE: "Deactivate Transaction Type?",
      INACTIVE_DESCRIPTION: "Are you sure you want to deactivate this type?",
      DELETE_DESCRIPTION: "Are you sure you want to delete this type?",
      DELETE_TITLE: "Delete Tansaction Type?",
    },
  },
  TRANSACTIONTYPE_FORM: {
    CREATE_BTN: "Create",
    UPDATE_BTN: "Update",
    CREATE_TITLE: "Create transaction type",
    UPDATE_TITLE: "Update transaction type",
    CLOSE_BTN: "Close",
  },
  ADVISORY_ROLE: {
    FILTER: {
      TITLE: "Filter",
      FIELDS: {
        NAME: {
          NAME: "name",
          LABEL: "Name:",
          PLACEHOLDER: "Filter by name",
        },
        STATUS: {
          NAME: "status",
          LABEL: "Status:",
          PLACEHOLDER: "Filter by status",
          OPTIONS: [
            {
              key: true,
              value: "Activated",
            },
            {
              key: false,
              value: "Deactivated",
            },
          ],
        },
      },
      BTN: "Apply Filters",
    },
    TABLE: {
      TITLE: "Advisory Roles",
      BTN: "Create Role",
      ACTIVE: "ACTIVE",
      INACTIVE: "INACTIVE",
      DELETE: "DELETE",
      COLUMN: {
        NO: "No",
        NAME: "Name",
        STATUS: "Status",
        ACTIVE: "activated",
        INACTIVE: "deactivated",
        CREATED: "Created At",
        UPDATED: "Updated At",
        ACTION: "Action",
      },
      TOOLTIP: {
        EDIT: "Edit Role",
        DELETE: "Delete Role",
        DETAIL: "Role Detail",
      },
    },
    CONFIRMATION_MODAL: {
      ACTIVE_TITLE: "Activate Role?",
      ACTIVE_DESCRIPTION:
        "Are you sure you want to activate this advisory role?",
      INACTIVE_TITLE: "Deactivate Role?",
      INACTIVE_DESCRIPTION:
        "Are you sure you want to deactivate this advisory role?",
      DELETE_TITLE: "Delete Role?",
      DELETE_DESCRIPTION: "Are you sure you want to delete this advisory role?",
    },
  },
  PARTY_ROLE: {
    FILTER: {
      TITLE: "Filter",
      FIELDS: {
        NAME: {
          NAME: "name",
          LABEL: "Name:",
          PLACEHOLDER: "Filter by name",
        },
        STATUS: {
          NAME: "status",
          LABEL: "Status:",
          PLACEHOLDER: "Filter by status",
          OPTIONS: [
            {
              key: true,
              value: "Activated",
            },
            {
              key: false,
              value: "Deactivated",
            },
          ],
        },
      },
      BTN: "Apply Filters",
    },
    TABLE: {
      TITLE: "Party Roles",
      BTN: "Create Role",
      ACTIVE: "ACTIVE",
      INACTIVE: "INACTIVE",
      DELETE: "DELETE",
      COLUMN: {
        NO: "No",
        NAME: "Name",
        STATUS: "Status",
        ACTIVE: "activated",
        INACTIVE: "deactivated",
        CREATED: "Created At",
        UPDATED: "Updated At",
        ACTION: "Action",
      },
      TOOLTIP: {
        EDIT: "Edit Role",
        DELETE: "Delete Role",
        DETAIL: "Role Detail",
      },
    },
    CONFIRMATION_MODAL: {
      ACTIVE_TITLE: "Activate Role?",
      ACTIVE_DESCRIPTION: "Are you sure you want to activate this party role?",
      INACTIVE_TITLE: "Deactivate Role?",
      INACTIVE_DESCRIPTION:
        "Are you sure you want to deactivate this party role?",
      DELETE_TITLE: "Delete Role?",
      DELETE_DESCRIPTION: "Are you sure you want to delete this party role?",
    },
  },
  ADVISORY_ROLE_FORM: {
    CREATE_BTN: "Create",
    UPDATE_BTN: "Update",
    EDIT_BTN: "Edit Role",
    CREATE_TITLE: "Create Advisory type",
    UPDATE_TITLE: "Update Advisory type",
    CLOSE_BTN: "Close",
  },
  PARTY_ROLE_FORM: {
    CREATE_BTN: "Create",
    UPDATE_BTN: "Update",
    EDIT_BTN: "Edit Role",
    CREATE_TITLE: "Create Party type",
    UPDATE_TITLE: "Update Party type",
    CLOSE_BTN: "Close",
  },
  QUESTIONS: {
    FILTER: {
      TITLE: "Filter",
      FIELDS: {
        NAME: {
          NAME: "search",
          LABEL: "Name:",
          PLACEHOLDER: "Filter by question name",
          RULES: [
            {
              whitespace: true,
              message: "Invalid question name",
            },
          ],
        },
        TRANSACTION_TYPE: {
          NAME: "transactiontype",
          LABEL: "Transaction Type:",
          PLACEHOLDER: "Filter by transaction type",
        },
      },
      BTN: "Apply Filters",
    },
    TABLE: {
      TITLE: "Questions",
      BTN: "Create Question",
      COLUMN: {
        NO: "No",
        NAME: "Question",
        TRANSACTION_TYPE: "Transaction types",
        CREATED: "Created At",
        ACTION: "Action",
      },
      TOOLTIP: {
        EDIT: "Edit Question",
        DETAIL: "Question Detail",
        DELETE: "Delete Question",
        UNBLOCK: "Unblock Enterprise",
      },
    },
    CONFIRMATION_MODAL: {
      TITLE: "Delete Question?",
      DESCRIPTION: "Are you sure you want to delete this question?",
    },
  },
  QUESTIONS_FORM: {
    EDIT_BTN: "Edit Question",
    DELETE_BTN: "Delete Question",
    CREATE_BTN: "Create",
    UPDATE_BTN: "Update",
  },
  RESOURCES: {
    FILTER: {
      TITLE: "Filter",
      FIELDS: {
        NAME: {
          NAME: "search",
          LABEL: "Name:",
          PLACEHOLDER: "Filter by name",
        },
        TRANSACTION_TYPE: {
          NAME: "transactionType",
          LABEL: "Transaction Type:",
          PLACEHOLDER: "Filter by transaction type",
        },
      },
      BTN: "Apply Filters",
    },
    TABLE: {
      TITLE: "Folders",
      BTN: "Create",
      COLUMN: {
        NO: "No",
        NAME: "Name",
        TRANSACTION_TYPE: "Transaction Type",
        CREATED: "Created At",
        UPDATED: "Updated At",
        ACTION: "Action",
      },
      TOOLTIP: {
        EDIT: "Edit Resource",
        DELETE: "Delete Resource",
        DETAIL: "Resource Details",
      },
    },
    CONFIRMATION_MODAL: {
      TITLE: "Delete Resource?",
      DESCRIPTION: "Are you sure you want to delete this resource?",
    },
  },
  RESOURCES_FORM: {
    CREATE_BTN: "Create",
    UPDATE_BTN: "Update",
    CREATE_TITLE: "Create Resource",
    UPDATE_TITLE: "Update Resource",
    CLOSE_BTN: "Close",
  },
  RESOURCES_DETAIL: {
    UPDATE_BTN: "Update Resource",
    DELETE_BTN: "Delete Resource",
    FILTER: {
      TITLE: "Filter",
      FIELDS: {
        NAME: {
          NAME: "search",
          LABEL: "Name:",
          PLACEHOLDER: "Filter by name",
        },
      },
      BTN: "Apply Filters",
    },
    TABLE: {
      TITLE: "Files",
      BTN: "Upload File",
      COLUMN: {
        NO: "No",
        NAME: "Name",
        CREATED: "Created At",
        UPDATED: "Updated At",
        ACTION: "Action",
      },
      TOOLTIP: {
        EDIT: "Edit File",
        DELETE: "Delete File",
        DOWNLOAD: "Download File",
      },
    },
    CONFIRMATION_MODAL: {
      TITLE: "Delete File?",
      DESCRIPTION: "Are you sure you want to delete this file?",
    },
  },
  FILE_FORM: {
    CREATE_BTN: "Upload",
    UPDATE_BTN: "Update",
    CREATE_TITLE: "Upload file",
    UPDATE_TITLE: "Update file",
    CLOSE_BTN: "Close",
  },
  CHECKLIST_TEMPLATES: {
    FILTER: {
      TITLE: "Filter",
      FIELDS: {
        NAME: {
          NAME: "name",
          LABEL: "Name:",
          PLACEHOLDER: "Filter by template name",
          RULES: [
            {
              whitespace: true,
              message: "Invalid template name",
            },
          ],
        },
        TRANSACTION_TYPE: {
          NAME: "transactiontype",
          LABEL: "Transaction Type:",
          PLACEHOLDER: "Filter by transaction type",
        },
        CHECKLIST_TYPE: {
          NAME: "type",
          LABEL: "Type:",
          PLACEHOLDER: "Filter by checklist type",
        },
      },
      BTN: "Apply Filters",
    },
    TABLE: {
      TITLE: "Checklist Templates",
      BTN: "Create Template",
      COLUMN: {
        NO: "No",
        NAME: "Name",
        TYPE: "Type",
        END_DATE: "End Date",
        CREATED: "Created At",
        ACTION: "Action",
      },
      TOOLTIP: {
        EDIT: "Edit Template",
        DETAIL: "Template Detail",
        DELETE: "Delete Template",
      },
    },
    DELETE_MODAL: {
      TITLE: "Delete Template?",
      DESCRIPTION: "Are you sure you want to delete this template?",
    },
    CONFIRMATION_MODAL: {
      TITLE: "Update Templete?",
      DESCRIPTION:
        "Are you sure you want to update this template? Changes will affect the suggestions list.",
    },
  },
  CHECKLIST_TEMPLATES_FORM: {
    EDIT_BTN: "Edit Template",
    DELETE_BTN: "Delete Template",
    CREATE_BTN: "Create",
    UPDATE_BTN: "Update",
  },
  REVIEWS: {
    FILTER: {
      TITLE: "Filter",
      FIELDS: {
        NAME: {
          NAME: "name",
          LABEL: "Name:",
          PLACEHOLDER: "Filter by reviewer name",
          RULES: [
            {
              whitespace: true,
              message: "Invalid reviewer name",
            },
          ],
        },
      },
      BTN: "Apply Filters",
    },
    TABLE: {
      TITLE: "Reviews",
      BTN: "Create Review",
      COLUMN: {
        NO: "No",
        NAME: "Name",
        ROLE: "Job title",
        RATING: "Rating",
        CREATED: "Created At",
        ACTION: "Action",
      },
      TOOLTIP: {
        EDIT: "Edit Review",
        DETAIL: "Review Detail",
        DELETE: "Delete Review",
      },
    },
    CONFIRMATION_MODAL: {
      TITLE: "Delete Review?",
      DESCRIPTION: "Are you sure you want to delete this review?",
    },
  },
  REVIEWS_FORM: {
    EDIT_BTN: "Edit Review",
    DELETE_BTN: "Delete Review",
    CREATE_BTN: "Create",
    UPDATE_BTN: "Update",
  },
  CONTACTS: {
    TABLE: {
      TITLE: "Contacts",
      COLUMN: {
        NO: "No",
        NAME: "Name",
        EMAIL: "Email",
        PHONE: "Phone",
        MESSAGE: "Message",
        CREATED: "Created At",
        ACTION: "Action",
      },
      TOOLTIP: {
        DETAIL: "Contact Detail",
        DELETE: "Delete Contact",
      },
    },
    CONFIRMATION_MODAL: {
      TITLE: "Delete Contact?",
      DESCRIPTION: "Are you sure you want to delete this contact?",
    },
  },
  CONTACTS_FORM: {
    DELETE_BTN: "Delete Contact",
  },
};
