import React from "react";
import PhoneInput from "react-phone-input-2";
import { Col, Form, Input, Select } from "antd";
import countryList from "react-select-country-list";
import { inputFieldRule } from "../../../services/utils";
const EnterprisesFormFields = () => {
  const countryOptions = countryList().getData();
  return (
    <>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Enterprise Name</label>
        <Form.Item
          name="name"
          rules={inputFieldRule({
            name: "Enterprise name",
            isMax: true,
            max: 80,
          })}
        >
          <Input placeholder="Enter Enterprise name" className="form-input" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Phone Number</label>
        <Form.Item
          name="workPhone"
          rules={inputFieldRule({
            name: "Phone number",
            isWhiteSpace: false,
          })}
        >
          <PhoneInput
            className="phone-field"
            placeholder=""
            country={"us"}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={6}>
        <label className="form-lbl">Website</label>
        <Form.Item
          name="website"
          rules={inputFieldRule({
            name: "Website",
            isMax: true,
            max: 80,
            isRequired: false,
            isWhiteSpace: true,
          })}
        >
          <Input type="url" placeholder="Website link" className="form-input" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={6}>
        <label className="form-lbl">LinkedIn</label>
        <Form.Item
          name="linkedIn"
          rules={inputFieldRule({
            name: "LinkedIn",
            isMax: true,
            max: 80,
            isRequired: false,
            isWhiteSpace: true,
          })}
        >
          <Input
            type="url"
            placeholder="LinkedIn link"
            className="form-input"
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={6}>
        <label className="form-lbl">Facebook</label>
        <Form.Item
          name="facebook"
          rules={inputFieldRule({
            name: "Facebook",
            isMax: true,
            max: 80,
            isRequired: false,
            isWhiteSpace: true,
          })}
        >
          <Input
            type="url"
            placeholder="Facebook link"
            className="form-input"
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={6}>
        <label className="form-lbl">Twitter</label>
        <Form.Item
          name="twitter"
          rules={inputFieldRule({
            name: "Twitter",
            isMax: true,
            max: 80,
            isRequired: false,
            isWhiteSpace: true,
          })}
        >
          <Input type="url" placeholder="Twitter link" className="form-input" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={24}>
        <label className="form-lbl">Default Office Address</label>
        <Form.Item
          name="address"
          rules={inputFieldRule({
            name: "Address",
            isMax: true,
            max: 255,
          })}
        >
          <Input placeholder="Enter address" className="form-input" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={6}>
        <label className="form-lbl">City</label>
        <Form.Item
          name="city"
          rules={inputFieldRule({
            name: "City",
            isMax: true,
            max: 80,
          })}
        >
          <Input placeholder="Enter city" className="form-input" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={6}>
        <label className="form-lbl">State</label>
        <Form.Item
          name="state"
          rules={inputFieldRule({
            name: "State",
            isMax: true,
            max: 80,
          })}
        >
          <Input placeholder="Enter state" className="form-input" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={6}>
        <label className="form-lbl">Postal/Zip Code</label>
        <Form.Item
          name="zipcode"
          rules={inputFieldRule({
            name: "Postal/Zip code",
            isMax: true,
            max: 80,
          })}
        >
          <Input
            type="text"
            placeholder="Enter postal/zip code"
            className="form-input"
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={6}>
        <label className="form-lbl">Country</label>
        <Form.Item
          name="country"
          rules={inputFieldRule({
            name: "Country",
            isWhiteSpace: false,
          })}
        >
          <Select
            placeholder="Country"
            className="form-select"
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          >
            {countryOptions.map((data, index) => (
              <Select.Option value={data.label} key={index} label={data.label}>
                {data.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default EnterprisesFormFields;
