import { fork } from "redux-saga/effects";
import general from "./general";
import user from "./user";
import auth from "./auth";
import transactiontype from "./transactiontype";
import enterprise from "./enterprise";
import advisoryrole from "./advisoryrole";
import partyroles from "./partyroles";
import questions from "./questions";
import resources from "./resources";
import template from "./template";
import contact from "./contact";
import reviews from "./reviews";

export default function* root() {
  yield fork(general);
  yield fork(auth);
  yield fork(user);
  yield fork(transactiontype);
  yield fork(enterprise);
  yield fork(advisoryrole);
  yield fork(partyroles);
  yield fork(questions);
  yield fork(resources);
  yield fork(template);
  yield fork(contact);
  yield fork(reviews);
}
