// @flow
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  data: null,
};

const ContactUsReducer = createSlice({
  name: "contact",
  initialState,
  reducers: {
    // DELETE TEMPLATE
    deleteContactRequest() {},

    // GET TEMPLATE
    getContactsListRequest() {},
    getContactsListSuccess(state, action) {
      state.list = action.payload;
    },

    // GET TEMPLATE DETAIL
    getContactDetailRequest() {},
    getContactDetailSuccess(state, action) {
      state.data = action.payload;
    },
  },
});

export const {
  deleteContactRequest,
  getContactsListRequest,
  getContactsListSuccess,
  getContactDetailRequest,
  getContactDetailSuccess,
} = ContactUsReducer.actions;

export default ContactUsReducer.reducer;
