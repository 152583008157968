import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "antd";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  ButtonComponent,
  ImageUploader,
  ProfileImage,
  ConfirmationModal,
  SocialBox,
} from "../../../components";
import {
  DEFAULT_COMPANY_LOGO,
  ENTERPRISES_ROUTE,
  IMAGE_HOST,
  WEB_STRINGS,
} from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  createEnterpriseRequest,
  updateEnterpriseRequest,
  getOneEnterpriseRequest,
  blockUnblockEnterpriseRequest,
} from "../../../redux/slicers/enterprise";
import { toastAlert } from "../../../services/utils";
import EnterprisesFormFields from "./fields";
import EnterprisesDetailView from "./view";

const EnterprisesForm = () => {
  // WEB STRINGS
  const { CREATE_BTN, EDIT_BTN, UPDATE_BTN } = WEB_STRINGS.ENTERPRISES_FORM;
  const { FILTER, TABLE, CONFIRMATION_MODAL } = WEB_STRINGS.ENTERPRISES;

  // STATES
  const [companyLogo, setcompanyLogo] = useState(null);
  const [logoPreview, setlogoPreview] = useState(null);
  const [confirmationModalPreview, setConfirmationModalPreview] =
    useState(false);

  // REDUX DATA
  const enterpriseData = useSelector((state) => state.enterprises.data);
  // DISPATCH CALLS
  const [getEnterpriseReq, dataLoading] = useCustomDispatch(
    getOneEnterpriseRequest
  );
  const [createEnterpriseReq, createLoading] = useCustomDispatch(
    createEnterpriseRequest
  );
  const [updateEnterpriseReq, updateLoading] = useCustomDispatch(
    updateEnterpriseRequest
  );
  const [blockEnterpriseReq, blockLoading] = useCustomDispatch(
    blockUnblockEnterpriseRequest
  );

  // CONST VALS
  const { id, dataid } = useParams();
  const paramid = id || dataid;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const BLOCK_STATUS = `${enterpriseData?.block ? "UN" : ""}BLOCK`;

  // HELPERS

  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic(response) {
        toastAlert(response.message);
        navigate(ENTERPRISES_ROUTE.GET);
      },
    });
  };

  // HANDLERS
  const setcompanyLogoHandler = (logo) => {
    setcompanyLogo(logo);
  };
  const setLogoPreviewHandler = (logo) => {
    setlogoPreview(logo);
  };
  const handleFormFinish = (values) => {
    const payload = {
      companyName: values.name,
      workPhone: values.workPhone,
      facebook: values.facebook,
      website: values.website,
      twitter: values.twitter,
      linkedIn: values.linkedIn,
      address: {
        address: values.address,
        state: values.state,
        city: values.city,
        zip: values.zipcode,
        country: values.country,
      },
    };
    if (companyLogo) {
      payload["logo"] = companyLogo;
    }
    if (id) {
      payload.address["id"] = enterpriseData?.addresses[0]?.id;
      requestHelper(updateEnterpriseReq, payload, enterpriseData.id);
    } else {
      requestHelper(createEnterpriseReq, payload);
    }
  };

  const setConfirmationModalPreviewHandler = () => {
    setConfirmationModalPreview(!confirmationModalPreview);
  };

  const blockUnblockHandler = () => {
    blockEnterpriseReq({
      queryParams: { id: enterpriseData.id },
      logic({ message }) {
        toastAlert(message);
        setConfirmationModalPreviewHandler();
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (paramid) {
      getEnterpriseReq({ pathParams: paramid });
    }
  }, []);

  useEffect(() => {
    if (enterpriseData && paramid) {
      const address = enterpriseData?.addresses[0] || {};
      form.setFieldsValue({
        name: enterpriseData.name,
        workPhone: enterpriseData.workPhone,
        address: address?.address,
        state: address?.state,
        city: address?.city,
        zipcode: address?.zip,
        country: address?.country,
        website: enterpriseData?.website,
        facebook: enterpriseData?.facebook,
        twitter: enterpriseData?.twitter,
        linkedIn: enterpriseData?.linkedIn,
      });
      setcompanyLogo(enterpriseData?.logoKey);
      setlogoPreview(enterpriseData?.logo);
    }
  }, [paramid, enterpriseData]);

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {dataid && (
            <div style={{ textAlign: "right" }}>
              <Link to={ENTERPRISES_ROUTE.UPDATE.replace(":id", paramid)}>
                <button className="filter-btn">{EDIT_BTN}</button>
              </Link>
            </div>
          )}
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 25px" }}>
            {dataLoading ? (
              <div className="loader-wrapper" style={{ height: 320 }}>
                <BeatLoader color="#6F7CED" />
              </div>
            ) : (
              <Form
                form={form}
                className="form"
                disabled={dataid}
                onFinish={handleFormFinish}>
                <Row gutter={[24, 0]}>
                  <Col xs={24} md={24} lg={24}>
                    <div className="image-uploader">
                      <div className="social-logo-wrapper">
                        <ProfileImage
                          color={enterpriseData?.bgcolor}
                          image={
                            logoPreview ?? IMAGE_HOST + DEFAULT_COMPANY_LOGO
                          }
                          username={enterpriseData?.name}
                        />
                        {dataid && <SocialBox data={enterpriseData} />}
                      </div>
                      <div className="action-btns">
                        {!dataid && (
                          <ImageUploader
                            setImage={setcompanyLogoHandler}
                            setPreviewImage={setLogoPreviewHandler}
                          />
                        )}
                        {paramid && (
                          <div
                            onClick={setConfirmationModalPreviewHandler}
                            className={`block-btn ${
                              enterpriseData?.block ? "un" : ""
                            }`}>
                            {enterpriseData?.block ? "Unblock " : "Block "}
                            Enterprise
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  {dataid ? (
                    <EnterprisesDetailView data={enterpriseData} />
                  ) : (
                    <EnterprisesFormFields />
                  )}
                  {!dataid && (
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item>
                        <ButtonComponent
                          isLoading={createLoading || updateLoading}
                          text={id ? UPDATE_BTN : CREATE_BTN}
                          style={{ width: 220, marginTop: 10, padding: 10 }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        title={CONFIRMATION_MODAL[BLOCK_STATUS + "_TITLE"]}
        description={CONFIRMATION_MODAL[BLOCK_STATUS + "_DESCRIPTION"]}
        handleClose={setConfirmationModalPreviewHandler}
        modalPreview={confirmationModalPreview}
        handelConfirm={blockUnblockHandler}
        isLoading={blockLoading}
      />
    </div>
  );
};

export default EnterprisesForm;
