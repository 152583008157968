import React from "react";
import { Col, Row } from "antd";
const QuestionsDetailView = ({ data, questions }) => {
  return (
    <>
      <Col xs={24}>
        <label className="form-lbl">Question</label>
        <p className="view-input">{data?.question}</p>
        <label className="form-lbl">Transaction Types</label>
        <p className="view-input">
          {data?.transactionType?.map((item, index) => {
            return `${item.title} ${
              index + 1 !== data?.transactionType.length ? " , " : ""
            }`;
          })}
        </p>
      </Col>
      {data?.answer?.map((item, index) => (
        <React.Fragment key={index}>
          <Col xs={24} md={item?.nextQuestionId ? 12 : 24}>
            <label className="form-lbl">Option {index + 1}</label>
            <p className="view-input">{item?.answer}</p>
          </Col>
          {item?.nextQuestionId && (
            <Col xs={24} md={12}>
              <label className="form-lbl">Dependent question </label>
              <p className="view-input">
                {item?.nextQuestion?.question}
              </p>
            </Col>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default QuestionsDetailView;
