import React, { useEffect, useState } from "react";
import { EyeOutlined, FilterFilled } from "@ant-design/icons";
import { Card, Col, Form, Row, Tooltip, Typography } from "antd";
import { Images } from "../../../themes";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  ButtonComponent,
  ConfirmationModal,
  DataTable,
  FilterDrawer,
  ProfileImage,
} from "../../../components";
import {
  USERS_ROUTE,
  WEB_STRINGS,
  TABLE_PERPAGE_SIZE,
  DATE_TIME,
  SELECT_PERPAGE_SIZE,
} from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  blockUnblockUserRequest,
  getUsersListRequest,
} from "../../../redux/slicers/user";
import { getEnterpriseListRequest } from "../../../redux/slicers/enterprise";
import UsersFilter from "./filter";
import { getFormattedDateTime, toastAlert } from "../../../services/utils";
const { Title } = Typography;

function Users() {
  // WEB STRINGS
  const { FILTER, TABLE, CONFIRMATION_MODAL } = WEB_STRINGS.USERS;
  const { COLUMN, TOOLTIP } = TABLE;

  // STATES
  const [selectedData, setSelectedData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    preview: false,
    status: "BLOCK",
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setsearchText] = useState("");

  // DISPATCH CALLS
  const [getUsersList, isDataLoading] = useCustomDispatch(getUsersListRequest);
  const [getEnterprises, enterpriseLoader] = useCustomDispatch(
    getEnterpriseListRequest
  );
  const [blockUnblockUserReq, blockUnblockUserReqLoader] = useCustomDispatch(
    blockUnblockUserRequest
  );
  // REDUX DATA
  const { usersList } = useSelector((state) => state.users);
  const enterprises = useSelector((state) => state.enterprises.list);
  const [form] = Form.useForm();

  const paginationConfig = {
    pageSize: TABLE_PERPAGE_SIZE,
    total: total,
  };

  useEffect(() => {
    const queryParams = {
      limit: TABLE_PERPAGE_SIZE,
      offset: (page - 1) * TABLE_PERPAGE_SIZE,
      ...filteredData,
    };
    getUsersList({
      queryParams,
      logic(response) {
        setTotal(response.total);
        setFilterOpen(false);
      },
    });
  }, [page, filteredData]);

  const handleFormSubmit = () => {
    const credentials = form.getFieldsValue();
    Object.keys(credentials).forEach(
      (key) => credentials[key] === undefined && delete credentials[key]
    );
    setFilteredData(credentials);
  };
  const handleResetForm = () => {
    setFilteredData(null);
    form.resetFields();
    setFilterOpen(false);
  };

  const drawerSec = () => (
    <Form form={form} className="form" onFinish={handleFormSubmit}>
      <UsersFilter
        enterprises={enterprises}
        isEnterpriseLoading={enterpriseLoader}
        setSearchText={setSearchTextHandler}
      />
      <Form.Item style={{ marginTop: 25 }}>
        <ButtonComponent
          text={FILTER.BTN}
          isLoading={isDataLoading}
          disabled={isDataLoading}
          className="w-100"
        />
      </Form.Item>
      <span className="form-rm-btn" onClick={handleResetForm}>
        Reset Filters
      </span>
    </Form>
  );

  const columns = [
    {
      title: COLUMN.NO,
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * TABLE_PERPAGE_SIZE) + (i + 1)}</b>
      ),
    },
    {
      title: COLUMN.NAME,
      dataIndex: "",
      key: "2",
      render: (data) => {
        return (
          <span className="tbl-detail-box">
            <ProfileImage
              color={data?.bgcolor}
              size={14}
              image={data?.image}
              username={data.firstName + " " + data?.lastName}
            />
            <span className="detail">
              {data.firstName} {data?.lastName}
            </span>
          </span>
        );
      },
    },
    {
      title: COLUMN.EMAIL,
      dataIndex: "email",
      key: "3",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    // {
    //   title: COLUMN.PHONE,
    //   dataIndex: "phone",
    //   key: "4",
    //   render: (data, value) => {
    //     return <span>{data}</span>;
    //   },
    // },
    // {
    //   title: COLUMN.JOB,
    //   dataIndex: "jobTitle",
    //   key: "5",
    //   render: (data, value) => {
    //     return <span>{data}</span>;
    //   },
    // },
    {
      title: COLUMN.COMPANY,
      dataIndex: "company",
      key: "6",
      render: (data, value) => {
        return <span>{data?.name}</span>;
      },
    },
    {
      title: COLUMN.ROLE,
      dataIndex: "isAdmin",
      key: "7",
      render: (data, value) => {
        return <span>{data ? "Enterprise Admin" : "Participant"}</span>;
      },
    },
    {
      title: COLUMN.STATUS,
      dataIndex: "isActive",
      key: "7",
      render: (data, value) => {
        return <span>{data ? "Active" : "Inactive"}</span>;
      },
    },
    {
      title: COLUMN.CREATED,
      dataIndex: "createdAt",
      key: "7",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data, DATE_TIME)}</span>;
      },
    },
    {
      title: COLUMN.ACTION,
      key: "8",
      render: (data, record) => {
        return (
          <div className="actions">
            <Link
              to={USERS_ROUTE.DETAIL.replace(":dataId", data.id)}
              className="update"
            >
              <Tooltip title={TOOLTIP.DETAIL}>
                <EyeOutlined />
              </Tooltip>
            </Link>
            <span style={{ width: 25 }}>
              {data?.isBlock || data?.company?.block ? (
                <Tooltip
                  title={
                    data?.company?.block
                      ? TOOLTIP.COMPANY_BLOCK
                      : TOOLTIP.UNBLOCK
                  }
                >
                  <img
                    src={Images.UnblockIcon}
                    alt=""
                    style={{
                      height: 17,
                      width: 17,
                      cursor: data?.company?.block ? "auto" : "pointer",
                    }}
                    onClick={() => {
                      !data?.company?.block &&
                        handleUnblockBlockConfirmModal(data, "UNBLOCK");
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={TOOLTIP.BLOCK}>
                  <img
                    src={Images.BlockIcon}
                    alt=""
                    style={{ height: 17 }}
                    onClick={() =>
                      handleUnblockBlockConfirmModal(data, "BLOCK")
                    }
                  />
                </Tooltip>
              )}
            </span>
          </div>
        );
      },
    },
  ];

  // HANDLERS
  const handleUnblockBlockConfirmModal = (data, status = "BLOCK") => {
    setSelectedData(data);
    setConfirmationModal({ preview: true, status });
  };

  const userBlockUnblockHandler = () => {
    blockUnblockUserReq({
      queryParams: { id: selectedData.id },
      logic(res) {
        toastAlert(res.message);
        setConfirmationModal({ preview: false, status: "BLOCK" });
      },
    });
  };

  const setSearchTextHandler = (text) => {
    setsearchText(text);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };
  const getEnterprisesHandler = (isSearched) => {
    const queryParams = {
      limit: SELECT_PERPAGE_SIZE,
      offset: 0,
    };
    if (isSearched) queryParams["search"] = searchText;

    getEnterprises({ queryParams });
  };
  useEffect(() => {
    getEnterprisesHandler(searchText !== "");
  }, [searchText]);

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card
              bordered={false}
              className="criclebox cardbody "
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>{TABLE.TITLE}</Title>
                </div>
                <ButtonComponent
                  onClick={filterHandler}
                  text={FILTER.TITLE}
                  icon={<FilterFilled />}
                />
              </div>
              <DataTable
                isLoading={isDataLoading}
                data={usersList}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <ConfirmationModal
        title={CONFIRMATION_MODAL[confirmationModal.status + "_TITLE"]}
        description={
          CONFIRMATION_MODAL[confirmationModal.status + "_DESCRIPTION"]
        }
        handleClose={() =>
          setConfirmationModal({ preview: false, status: "block" })
        }
        modalPreview={confirmationModal.preview}
        handelConfirm={userBlockUnblockHandler}
        isLoading={blockUnblockUserReqLoader}
      />
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
    </>
  );
}

export default Users;
