import { take, put, call, fork } from "redux-saga/effects";
import { ALERT_TYPES } from "../../constants";
import {
  callRequest,
  CREATE_REVIEW,
  UPDATE_REVIEW,
  DELETE_REVIEW,
  GET_REVIEWS,
  GET_REVIEW_DETAIL,
} from "../../config/webService";
import { toastAlert } from "../../services/utils";
import {
  createReviewRequest,
  updateReviewRequest,
  deleteReviewRequest,
  getReviewDetailRequest,
  getReviewDetailSuccess,
  getReviewListRequest,
  getReviewListSuccess,
} from "../slicers/reviews";

function* createReview() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(createReviewRequest.type);

    try {
      const response = yield call(callRequest, {
        url: CREATE_REVIEW,
        data: payload,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* updateReview() {
  while (true) {
    const {
      payload: { payload, pathParams, responseCallback },
    } = yield take(updateReviewRequest.type);

    try {
      const response = yield call(callRequest, {
        url: UPDATE_REVIEW,
        data: payload,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* deleteReview() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(deleteReviewRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_REVIEW,
        pathParams,
      });
      if (response.status) {
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getReviews() {
  while (true) {
    const {
      payload: { queryParams, responseCallback },
    } = yield take(getReviewListRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_REVIEWS,
        queryParams,
      });
      if (response.status) {
        yield put(getReviewListSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* getReviewDetail() {
  while (true) {
    const {
      payload: { pathParams, responseCallback },
    } = yield take(getReviewDetailRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GET_REVIEW_DETAIL,
        pathParams,
      });
      if (response.status) {
        yield put(getReviewDetailSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(createReview);
  yield fork(updateReview);
  yield fork(deleteReview);
  yield fork(getReviews);
  yield fork(getReviewDetail);
}
