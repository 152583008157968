import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ButtonComponent, ConfirmationModal } from "../../../components";
import {
  CHECKLIST_TEMPLATES_ROUTE,
  DROPDOWN_DATA_SIZE,
  SELECT_PERPAGE_SIZE,
  WEB_STRINGS,
} from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import { getQuestionsRequest } from "../../../redux/slicers/questions";
import { getPartyRoleListRequest } from "../../../redux/slicers/partyroles";
import { getAdvisoryRoleListRequest } from "../../../redux/slicers/advisoryroles";
import { getTransactionTypeListRequest } from "../../../redux/slicers/transactiontype";
import {
  getTemplateDetailRequest,
  getChecklistTypesRequest,
  createTemplateRequest,
  updateTemplateRequest,
  deleteTemplateRequest,
} from "../../../redux/slicers/template";
import { cloneDeepItem, toastAlert } from "../../../services/utils";
import TemplateFormFields from "./fields";
import TemplateDetailView from "./view";
import TemplateQuestionFields from "./questionfield";
import { PlusOutlined } from "@ant-design/icons";

const TemplateForm = () => {
  const { CREATE_BTN, EDIT_BTN, UPDATE_BTN, DELETE_BTN } =
    WEB_STRINGS.CHECKLIST_TEMPLATES_FORM;
  const { CONFIRMATION_MODAL, DELETE_MODAL } = WEB_STRINGS.CHECKLIST_TEMPLATES;

  // CONST VALS
  const [form] = Form.useForm();
  const { id, dataid } = useParams();
  const paramid = id || dataid;
  const navigate = useNavigate();
  const initalQuestion = "new";

  // STATES
  const [isFieldChanged, setFieldChanged] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [selectedQuestions, setselectedQuestions] = useState([initalQuestion]);
  const [typeSearchText, setTypeSearchText] = useState("");
  const [isTypesLoading, setTypesLoading] = useState(true);
  const [partyRoleSearchText, setPartyRoleSearchText] = useState("");
  const [isPartyRoleLoading, setPartyRoleLoading] = useState(true);
  const [advisoryRoleSearchText, setAdvisoryRoleSearchText] = useState("");
  const [isAdvisoryRoleLoading, setAdvisoryRoleLoading] = useState(true);
  const [isQuestionLoading, setQuestionLoading] = useState(true);
  const [questionSearchText, setQuestionSearchText] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // CONST VALS
  const selectedQuestionsLength = selectedQuestions.filter(
    (x) => x !== null
  )?.length;
  // REDUX DATA
  const questions = useSelector((state) => state.questions.list);
  const { transactionsList } = useSelector((state) => state.transactiontypes);
  const { list: advisoryroles } = useSelector((state) => state.advisoryroles);
  const { list: partyroles } = useSelector((state) => state.partyroles);
  const { types, data } = useSelector((state) => state.checklisttemplate);

  // DISPATCH CALLS
  const [getQuestions, questionLoader] = useCustomDispatch(getQuestionsRequest);
  const [getTemplateDetail, dataLoading] = useCustomDispatch(
    getTemplateDetailRequest
  );
  const [getTransactiontypes, typesLoader] = useCustomDispatch(
    getTransactionTypeListRequest
  );
  const [getPartyRoles, partyLoader] = useCustomDispatch(
    getPartyRoleListRequest
  );
  const [getAdvisoryRoles, advisoryLoader] = useCustomDispatch(
    getAdvisoryRoleListRequest
  );
  const [getChecklistTypes, checklisttypeLoader] = useCustomDispatch(
    getChecklistTypesRequest
  );
  const [createTemplate, createLoading] = useCustomDispatch(
    createTemplateRequest
  );
  const [updateTemplate, updateLoading] = useCustomDispatch(
    updateTemplateRequest
  );
  const [deleteTemplate, deleteLoading] = useCustomDispatch(
    deleteTemplateRequest
  );

  // HELPERS
  const getTransactiontypesHelper = (isSearched) => {
    const types = data?.transactionTypes.map((x) => x.id);
    const queryParams = {
      limit: SELECT_PERPAGE_SIZE,
      offset: 0,
      status: true,
      sort: true,
    };
    if (types?.length >= 1) queryParams["ids"] = types.toString();

    if (isSearched) queryParams["name"] = typeSearchText;

    getTransactiontypes({
      queryParams,
      logic() {
        setTypesLoading(false);
      },
    });
  };

  const getPartyRolesHelper = (isSearched) => {
    const roles = data?.partyRoles.map((x) => x.id);
    const queryParams = {
      limit: SELECT_PERPAGE_SIZE,
      offset: 0,
      status: true,
      sort: true,
    };
    if (roles?.length >= 1) queryParams["ids"] = types.toString();

    if (isSearched) queryParams["name"] = partyRoleSearchText;

    getPartyRoles({
      queryParams,
      logic() {
        setPartyRoleLoading(false);
      },
    });
  };

  const getAdvisoryRolesHelper = (isSearched) => {
    const roles = data?.advisorRole.map((x) => x.id);
    const queryParams = {
      limit: SELECT_PERPAGE_SIZE,
      offset: 0,
      status: true,
      sort: true,
    };
    if (roles?.length >= 1) queryParams["ids"] = types.toString();

    if (isSearched) queryParams["name"] = advisoryRoleSearchText;

    getAdvisoryRoles({
      queryParams,
      logic() {
        setAdvisoryRoleLoading(false);
      },
    });
  };

  const getQuestionsHelper = (isSearched) => {
    const queryParams = {
      // limit: DROPDOWN_DATA_SIZE,
      offset: 0,
    };
    if (selectedTypes?.length < 1) {
      return;
    }
    queryParams["types"] = selectedTypes?.toString();
    if (isSearched) queryParams["search"] = questionSearchText;

    getQuestions({
      queryParams,
      logic() {
        setQuestionLoading(false);
      },
    });
  };

  const requestHelper = (request, payload, pathParams) => {
    Object.keys(payload).forEach(
      (key) => payload[key] === undefined && delete payload[key]
    );
    request({
      payload,
      pathParams,
      logic(response) {
        toastAlert(response.message);
        navigate(CHECKLIST_TEMPLATES_ROUTE.GET);
      },
    });
  };

  const manuplateDetailResponse = () => {
    setselectedQuestions([initalQuestion]);
    form.resetFields();
    let questions = [];
    let answers = [];
    const transactiontypes = data.transactionTypes.map((x) => x.id);
    form.setFieldsValue({
      name: data.name,
      notes: data.notes,
      type: data.type.key,
      transactioTypes: transactiontypes,
      PartyRoles: data.partyRoles.map((x) => x.id),
      AdvisorRoles: data.advisorRole.map((x) => x.id),
    });
    setSelectedTypes(transactiontypes);
    data?.answers?.forEach((e) => {
      const questionId = e.question?.id;
      const answerobj = {
        options: [e.id],
        questionId,
      };
      const isAnsExsist = answers.findIndex((x) => x.questionId === questionId);
      if (isAnsExsist >= 0) {
        answers[isAnsExsist].options.push(e.id);
      } else {
        answers.push(answerobj);
      }
      const isQuesExsist = questions.find((x) => x === questionId);
      if (!isQuesExsist) {
        questions.push(questionId);
      }
    });
    questions.forEach((e, i) => {
      if (i === 0) setselectedQuestions(questions);
      const questionanswers = answers.find((x) => x.questionId === e)?.options;
      form.setFieldsValue({
        [`question_${i}`]: e,
        [`answer_${i}`]: questionanswers,
      });
    });
  };

  // HOOKS
  useEffect(() => {
    getChecklistTypes();
  }, []);
  useEffect(() => {
    if (paramid) {
      getTemplateDetail({ pathParams: paramid });
    }
  }, [paramid]);

  useEffect(() => {
    if (data && paramid) {
      manuplateDetailResponse();
    }
  }, [paramid, data]);

  useEffect(() => {
    getTransactiontypesHelper(typeSearchText !== "");
  }, [data, typeSearchText]);

  useEffect(() => {
    getPartyRolesHelper(partyRoleSearchText !== "");
  }, [data, partyRoleSearchText]);

  useEffect(() => {
    getAdvisoryRolesHelper(advisoryRoleSearchText !== "");
  }, [data, advisoryRoleSearchText]);

  useEffect(() => {
    getQuestionsHelper(questionSearchText !== "");
  }, [selectedTypes, questionSearchText]);

  // CONST VALS
  const isDataLoading =
    paramid &&
    (dataLoading ||
      isTypesLoading ||
      isPartyRoleLoading ||
      isAdvisoryRoleLoading);

  // HANDLERS
  const setSelectedTypesHandler = (val) => {
    const tempQuestions = cloneDeepItem(selectedQuestions);
    const filteredQuestions = [...questions]
      .filter((obj) =>
        val.some((value) =>
          obj.transactionType.some((typeObj) => typeObj.id === value)
        )
      )
      .map((obj) => obj.id);
    const replacedQuestions = tempQuestions.map((value) =>
      filteredQuestions.includes(value)
        ? value
        : value === initalQuestion
        ? initalQuestion
        : null
    );
    setselectedQuestions(replacedQuestions);
    setSelectedTypes(val);
  };

  const setPartyRoleSearchTextHandler = (text) => {
    setPartyRoleSearchText(text);
  };

  const setAdvisoryRoleSearchTextHandler = (text) => {
    setAdvisoryRoleSearchText(text);
  };

  const setTypeSearchTextHandler = (text) => {
    setTypeSearchText(text);
  };

  const setFieldChangedHandler = () => {
    setFieldChanged(true);
  };

  const setQuestionSearchTextHandler = (text) => {
    setQuestionSearchText(text);
  };

  const handleAddquestion = () => {
    setselectedQuestions((selectedQuestions) => [
      ...selectedQuestions,
      initalQuestion,
    ]);
  };

  const handleAddSelectedQuestion = (value, index) => {
    let tempdata = cloneDeepItem(selectedQuestions);
    tempdata[index] = value;
    form.setFieldsValue({
      [`answer_${index}`]: undefined,
    });
    setselectedQuestions(tempdata);
  };

  const handleRemovequestion = (index) => {
    let tempdata = cloneDeepItem(selectedQuestions);
    tempdata[index] = null;
    setselectedQuestions(tempdata);
  };

  const handleConfirmModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteData = () => {
    deleteTemplate({
      pathParams: paramid,
      logic(res) {
        toastAlert(res.message);
        setShowConfirmationModal(false);
        navigate(CHECKLIST_TEMPLATES_ROUTE.GET);
      },
    });
  };

  const handleFormFinish = (values) => {
    let answers = [];
    Object.keys(values).forEach(function (key) {
      if (key.includes("answer")) {
        values[key]?.forEach((x) => {
          answers.push(x);
        });
      }
    });
    const payload = {
      name: values.name,
      type: values.type,
      notes: values.notes,
      transactioTypes: values.transactioTypes ?? [],
      AdvisorRoles: values.AdvisorRoles ?? [],
      PartyRoles: values.PartyRoles ?? [],
      answers,
    };
    setselectedData(payload);
    if (isFieldChanged && id) {
      handleConfirmModal();
      return;
    }
    const request = id ? updateTemplate : createTemplate;
    requestHelper(request, payload, id);
  };

  const handleApproveUpdate = () => {
    requestHelper(updateTemplate, selectedData, id);
  };

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ textAlign: "right" }}>
            {dataid && (
              <Link
                to={CHECKLIST_TEMPLATES_ROUTE.UPDATE.replace(":id", paramid)}
              >
                <button className="filter-btn">{EDIT_BTN}</button>
              </Link>
            )}
            {paramid && (
              <button
                className="block-btn"
                onClick={handleConfirmModal}
                style={{ marginLeft: 10, marginBottom: 15 }}
              >
                {DELETE_BTN}
              </button>
            )}
          </div>
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 25px" }}
          >
            {isDataLoading ? (
              <div className="loader-wrapper" style={{ height: 320 }}>
                <BeatLoader color="#6F7CED" />
              </div>
            ) : (
              <Form
                form={form}
                className="form"
                disabled={dataid}
                onFinish={handleFormFinish}
              >
                <Row gutter={[24, 0]}>
                  {dataid ? (
                    <TemplateDetailView data={data} />
                  ) : (
                    <>
                      <TemplateFormFields
                        checklistTypes={types}
                        isChecklistTypesLoading={checklisttypeLoader}
                        setSelectedTypes={setSelectedTypesHandler}
                        isTypesLoading={typesLoader}
                        transactiontypes={transactionsList}
                        setTypeSearchText={setTypeSearchTextHandler}
                        isPartyRoleLoading={partyLoader}
                        partyroles={partyroles}
                        setPartySearchText={setPartyRoleSearchTextHandler}
                        isAdvisoryRoleLoading={advisoryLoader}
                        advisoryroles={advisoryroles}
                        setAdvisorySearchText={setAdvisoryRoleSearchTextHandler}
                        setFieldChangedHandler={setFieldChangedHandler}
                      />
                      <Col xs={24}>
                        <label className="form-lbl">Questions</label>
                      </Col>
                      {selectedQuestions.map((item, index) => (
                        <React.Fragment key={index}>
                          {item && (
                            <TemplateQuestionFields
                              index={index}
                              isUpdate={id}
                              isQuestionLoading={questionLoader}
                              addSelectedQuestion={handleAddSelectedQuestion}
                              removeQuestion={handleRemovequestion}
                              selectedQuestion={selectedQuestions}
                              selectedQuestionsLength={selectedQuestionsLength}
                              setFieldChangedHandler={setFieldChangedHandler}
                              setQuestionSearchText={
                                setQuestionSearchTextHandler
                              }
                              questions={
                                selectedTypes.length >= 1 ? questions : []
                              }
                            />
                          )}
                        </React.Fragment>
                      ))}
                      <Col xs={24}>
                        {selectedQuestionsLength < 20 && (
                          <Tooltip title="Add another option">
                            <Button
                              type="dashed"
                              onClick={handleAddquestion}
                              className="add-fields-btn icon-btn"
                            >
                              <PlusOutlined />
                            </Button>
                          </Tooltip>
                        )}
                      </Col>
                    </>
                  )}
                  {!dataid && (
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item>
                        <ButtonComponent
                          isLoading={createLoading || updateLoading}
                          text={id ? UPDATE_BTN : CREATE_BTN}
                          style={{ width: 220, marginTop: 10, padding: 10 }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        handleClose={handleDeleteModal}
        modalPreview={showDeleteModal}
        title={DELETE_MODAL.TITLE}
        description={DELETE_MODAL.DESCRIPTION}
        handelConfirm={handleDeleteData}
        isLoading={deleteLoading}
      />
      <ConfirmationModal
        handleClose={handleConfirmModal}
        modalPreview={showConfirmationModal}
        title={CONFIRMATION_MODAL.TITLE}
        description={CONFIRMATION_MODAL.DESCRIPTION}
        handelConfirm={handleApproveUpdate}
        isLoading={updateLoading}
      />
    </div>
  );
};

export default TemplateForm;
