import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterFilled,
} from "@ant-design/icons";
import { Card, Col, Form, Input, Row, Select, Tooltip, Typography } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  ButtonComponent,
  ConfirmationModal,
  DataTable,
  FilterDrawer,
} from "../../../components";
import {
  WEB_STRINGS,
  TABLE_PERPAGE_SIZE,
  DATE_TIME,
  RESOURCES_ROUTE,
  SELECT_PERPAGE_SIZE,
} from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  deleteFolderRequest,
  getFolderListRequest,
} from "../../../redux/slicers/resources";
import { getTransactionTypeListRequest } from "../../../redux/slicers/transactiontype";
import { getFormattedDateTime, toastAlert } from "../../../services/utils";
import FolderForm from "./folderform";
const { Title } = Typography;

function Resources() {
  // WEBSTRING
  const { FILTER, TABLE, CONFIRMATION_MODAL } = WEB_STRINGS.RESOURCES;
  const { NAME, TRANSACTION_TYPE } = FILTER.FIELDS;
  const { COLUMN, TOOLTIP } = TABLE;
  // STATES
  const [selectedData, setSelectedData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isAnotherCall, setAnotherCall] = useState(false);
  const [updateModalPreview, setupdateModalPreview] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [transactionTypeSearchText, setTransactionTypeSearchText] =
    useState("");

  //REDUX DATA
  const { folderList } = useSelector((state) => state.resources);
  const { transactionsList } = useSelector((state) => state.transactiontypes);

  //DISPATCH CALLS
  const [getFolderList, isLoading] = useCustomDispatch(getFolderListRequest);
  const [deleteFolderReq, deleteLoader] =
    useCustomDispatch(deleteFolderRequest);
  const [getTransactionType, transactionTypeLoader] = useCustomDispatch(
    getTransactionTypeListRequest
  );

  //CONST VALS
  const [form] = Form.useForm();
  const paginationConfig = {
    pageSize: TABLE_PERPAGE_SIZE,
    total: total,
  };

  // HOOKS
  useEffect(() => {
    const queryParams = {
      limit: TABLE_PERPAGE_SIZE,
      offset: (page - 1) * TABLE_PERPAGE_SIZE,
      ...filteredData,
    };

    getFolderList({
      queryParams,
      logic(response) {
        setTotal(response.total);
        setFilterOpen(false);
      },
    });
  }, [page, isAnotherCall, filteredData]);

  useEffect(() => {
    if (updateModalPreview) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [updateModalPreview]);

  useEffect(() => {
    if (isFilterOpen)
      getTransactionTypeHandler(transactionTypeSearchText !== "");
  }, [transactionTypeSearchText, isFilterOpen]);

  const handleFormSubmit = () => {
    const credentials = form.getFieldsValue();
    credentials["transactionType"] = credentials?.transactionType?.toString();
    Object.keys(credentials).forEach(
      (key) => credentials[key] === undefined && delete credentials[key]
    );
    setFilteredData(credentials);
  };

  // CUSTOM COMPONENT
  const drawerSec = () => (
    <Form form={form} className="form" onFinish={handleFormSubmit}>
      <label className="form-lbl">{NAME.LABEL}</label>
      <Form.Item name={NAME.NAME}>
        <Input
          name={NAME.NAME}
          placeholder={NAME.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">{TRANSACTION_TYPE.LABEL}</label>
      <Form.Item name={TRANSACTION_TYPE.NAME}>
        <Select
          mode="multiple"
          placeholder="Select transaction type"
          className="form-select"
          showSearch
          loading={transactionTypeLoader}
          onSearch={(val) => {
            setTransactionTypeSearchText(val);
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          maxTagCount={4}
          maxTagTextLength={12}
        >
          {transactionsList.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item style={{ marginTop: 25 }}>
        <ButtonComponent
          text={FILTER.BTN}
          isLoading={isLoading}
          className="w-100"
        />
      </Form.Item>
      <span className="form-rm-btn" onClick={handleResetForm}>
        Reset Filters
      </span>
    </Form>
  );
  // TABLE CONFIG
  const columns = [
    {
      title: COLUMN.NO,
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * TABLE_PERPAGE_SIZE) + (i + 1)}</b>
      ),
    },
    {
      title: COLUMN.NAME,
      dataIndex: "name",
      key: "2",
      render: (data, value) => {
        return <span>{data}</span>;
      },
    },
    {
      title: COLUMN.TRANSACTION_TYPE,
      dataIndex: "transactionTypes",
      key: "3",
      render: (data, value) => {
        return (
          <span>
            {data.map(
              (item, index) =>
                item.title + `${index + 1 == data.length ? "" : ","}`
            )}
          </span>
        );
      },
    },
    {
      title: COLUMN.CREATED,
      dataIndex: "createdAt",
      key: "4",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data, DATE_TIME)}</span>;
      },
    },
    {
      title: COLUMN.ACTION,
      key: "5",
      render: (data, record) => {
        return (
          <div className="actions">
            <span className="update">
              <Link
                to={RESOURCES_ROUTE.DETAIL.replace(":dataid", data.id)}
                className="update"
              >
                <Tooltip title={TOOLTIP.DETAIL}>
                  <EyeOutlined />
                </Tooltip>
              </Link>
              <Tooltip title={TOOLTIP.EDIT}>
                <EditOutlined onClick={() => handleUpdateModal(data)} />
              </Tooltip>
            </span>
            <span style={{ width: 25 }}>
              <Tooltip title={TOOLTIP.DELETE}>
                <DeleteOutlined
                  // style={{ height: 17 }}
                  onClick={() => handleConfirmModal(data.slug)}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  //HANDLERS
  const handleResetForm = () => {
    setFilteredData(null);
    form.resetFields();
    setFilterOpen(false);
  };

  const handleUpdateModal = (data) => {
    setSelectedData(data);
    setupdateModalPreview(true);
  };

  const handleUpdateModalClose = () => {
    setupdateModalPreview(false);
    setSelectedData(null);
  };

  const handleConfirmModal = (data) => {
    setSelectedData(data);
    setShowConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleDeleteData = () => {
    deleteFolderReq({
      pathParams: selectedData,
      logic(response) {
        setAnotherCall(!isAnotherCall);
        toastAlert(response.message);
        setShowConfirmationModal(false);
        setSelectedData(null);
      },
    });
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  const getTransactionTypeHandler = (isSearched) => {
    const queryParams = {
      limit: SELECT_PERPAGE_SIZE,
      offset: 0,
    };
    if (isSearched) queryParams["name"] = transactionTypeSearchText;

    getTransactionType({
      queryParams,
    });
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <ButtonComponent
                onClick={() => {
                  setupdateModalPreview(true);
                }}
                text={TABLE.BTN}
              />
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody "
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>{TABLE.TITLE}</Title>
                </div>
                <ButtonComponent
                  onClick={filterHandler}
                  text={FILTER.TITLE}
                  icon={<FilterFilled />}
                />
              </div>
              <div className="unwrap-table">
                <DataTable
                  isLoading={isLoading}
                  data={folderList}
                  columns={columns}
                  pagination={{
                    ...paginationConfig,
                    onChange: handlePageChange,
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <ConfirmationModal
        modalPreview={showConfirmationModal}
        handleClose={handleConfirmationModalClose}
        title={CONFIRMATION_MODAL.TITLE}
        description={CONFIRMATION_MODAL.DESCRIPTION}
        handelConfirm={handleDeleteData}
        isLoading={deleteLoader}
      />
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
      {updateModalPreview && (
        <FolderForm
          toggle={handleUpdateModalClose}
          data={selectedData}
          onSuccess={() => {
            setAnotherCall(!isAnotherCall);
          }}
        />
      )}
    </>
  );
}

export default Resources;
