import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card } from "antd";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ConfirmationModal } from "../../../components";
import UsersDetailView from "./view";
import { WEB_STRINGS } from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  getOneUserRequest,
  blockUnblockUserRequest,
} from "../../../redux/slicers/user";
import { toastAlert } from "../../../services/utils";

const UsersForm = () => {
  // WEB STRINGS
  const { CONFIRMATION_MODAL } = WEB_STRINGS.USERS;

  // STATES
  const [confirmationModalPreview, setConfirmationModalPreview] =
    useState(false);

  // DISPATCH CALLS
  const [getSingleUserReq, dataLoading] = useCustomDispatch(getOneUserRequest);
  const [blockUnblockUserReq, isLoading] = useCustomDispatch(
    blockUnblockUserRequest
  );

  // REDUX DATA
  const { singleUser } = useSelector((state) => state.users);

  // CONST VALS
  const { dataId: userId } = useParams();
  const BLOCK_STATUS = `${singleUser?.isBlock ? "UN" : ""}BLOCK`;

  // HOOKS
  useEffect(() => {
    if (!userId) return;
    getSingleUserReq({
      pathParams: userId,
    });
  }, [userId]);

  // HANDLERS
  const setConfirmationModalPreviewHandler = () => {
    setConfirmationModalPreview(!confirmationModalPreview);
  };
  const userBlockUnblockHandler = () => {
    blockUnblockUserReq({
      queryParams: { id: singleUser.id },
      logic({ message }) {
        toastAlert(message);
        setConfirmationModalPreviewHandler();
      },
    });
  };
  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox cardbody"
            style={{ padding: "20px 25px" }}>
            {dataLoading ? (
              <div className="loader-wrapper" style={{ height: 320 }}>
                <BeatLoader color="#6F7CED" />
              </div>
            ) : (
              <Row gutter={[24, 0]}>
                <UsersDetailView
                  data={singleUser}
                  blockHandler={setConfirmationModalPreviewHandler}
                />
              </Row>
            )}
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        title={CONFIRMATION_MODAL[BLOCK_STATUS + "_TITLE"]}
        description={CONFIRMATION_MODAL[BLOCK_STATUS + "_DESCRIPTION"]}
        handleClose={setConfirmationModalPreviewHandler}
        modalPreview={confirmationModalPreview}
        handelConfirm={userBlockUnblockHandler}
        isLoading={isLoading}
      />
    </div>
  );
};

export default UsersForm;
