import React from "react";
import { Col } from "antd";
import { Link } from "react-router-dom";
import { ProfileImage, SocialBox } from "../../../components";
import PhoneInput from "react-phone-input-2";
import { ENTERPRISES_ROUTE } from "../../../constants";

const UsersDetailView = ({ data, blockHandler }) => {
  return (
    <>
      <Col xs={24} md={24} lg={24}>
        <div className="image-uploader">
          <ProfileImage
            color={data?.bgcolor}
            size={50}
            image={data?.image}
            username={data.firstName + " " + data?.lastName}
          />
          <button
            className={`block-btn ${
              data?.isBlock ? "un" : data?.company?.block ? "cun" : ""
            }`}
            onClick={() => {
              !data?.company?.block && blockHandler();
            }}
          >
            {data?.isBlock
              ? "Unblock user"
              : data?.company?.block
              ? "User enterprise is blocked"
              : "Block user"}
          </button>
        </div>
        <SocialBox data={data} />
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">First Name</label>
        <p className="view-input">{data?.firstName}</p>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Last Name</label>
        <p className="view-input">{data?.lastName}</p>
      </Col>
      <Col xs={24} md={12} lg={24}>
        <label className="form-lbl">Email Address</label>
        <p className="view-input">{data?.email}</p>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Personal Phone Number</label>
        <PhoneInput
          containerClass="view-num-input"
          value={data?.phone}
          disabled
        />
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Work Phone Number</label>
        <PhoneInput
          containerClass="view-num-input"
          value={data?.workPhone}
          disabled
          placeholder="-----------"
        />
      </Col>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Company Name</label>
        <Link
          to={ENTERPRISES_ROUTE.DETAIL.replace(":dataid", data?.company?.id)}
        >
          <p className="view-input">{data?.company?.name}</p>
        </Link>
      </Col>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Job Title</label>
        <p className="view-input">{data?.jobTitle}</p>
      </Col>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Role</label>
        <p className="view-input">
          {data?.isAdmin ? "Enterprise Admin" : "Participant"}
        </p>
      </Col>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Status</label>
        <p className="view-input">{data?.isActive ? "Active" : "Inactive"}</p>
      </Col>
    </>
  );
};

export default UsersDetailView;
