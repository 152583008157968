import React from "react";
import { Col, Rate, Row } from "antd";

const ReviewDetailView = ({ data }) => {
  return (
    <>
      <Col span={24}>
        <label className="form-lbl">Rating</label>
        <br />
        <Rate
          defaultValue={data?.rating}
          disabled
          style={{ marginBottom: "1em" }}
        />
      </Col>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">First Name</label>
        <p className="view-input">{data?.firstName}</p>
      </Col>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Last Name</label>
        <p className="view-input">{data?.lastName}</p>
      </Col>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Email</label>
        <p className="view-input">{data?.email}</p>
      </Col>
      <Col xs={24} md={12} lg={12}>
        <label className="form-lbl">Job title</label>
        <p className="view-input">{data?.role}</p>
      </Col>
      <Col span={24}>
        <label className="form-lbl">Review</label>
        <p className="view-input">{data?.description}</p>
      </Col>
    </>
  );
};

export default ReviewDetailView;
