import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  getTemplateListRequest,
  getChecklistTypesRequest,
  deleteTemplateRequest,
} from "../../../redux/slicers/template";
import { getTransactionTypeListRequest } from "../../../redux/slicers/transactiontype";
import { Card, Typography, Col, Row, Tooltip, Form } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterFilled,
} from "@ant-design/icons";
import {
  DataTable,
  FilterDrawer,
  ConfirmationModal,
  ButtonComponent,
} from "../../../components";
import { Link } from "react-router-dom";
import {
  CHECKLIST_TEMPLATES_ROUTE,
  WEB_STRINGS,
  TABLE_PERPAGE_SIZE,
  DATE_TIME,
} from "../../../constants";
import { getFormattedDateTime, toastAlert } from "../../../services/utils";
import TemplateFilter from "./filter";
const { Title } = Typography;

function ChecklistTemplates() {
  // WEBSTRING
  const { FILTER, TABLE, DELETE_MODAL } = WEB_STRINGS.CHECKLIST_TEMPLATES;
  const { COLUMN, TOOLTIP } = TABLE;
  // STATES
  const [typeSearchText, setTypeSearchText] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  // REDUX DATA
  const { list: data, types } = useSelector((state) => state.checklisttemplate);
  const { transactionsList } = useSelector((state) => state.transactiontypes);

  // DISPATCH CALLS
  const [getTransactiontypes, typesLoader] = useCustomDispatch(
    getTransactionTypeListRequest
  );
  const [getChecklistTypes, checklisttypeLoader] = useCustomDispatch(
    getChecklistTypesRequest
  );
  const [getTemplates, isDataLoading] = useCustomDispatch(
    getTemplateListRequest
  );
  const [deleteTemplate, blockLoading] = useCustomDispatch(
    deleteTemplateRequest
  );

  // CONST VALS
  const [form] = Form.useForm();
  const paginationConfig = {
    pageSize: TABLE_PERPAGE_SIZE,
    total: total,
  };

  // HELPERS
  const getTransactiontypesHandler = (isSearched) => {
    const queryParams = {
      limit: TABLE_PERPAGE_SIZE,
      offset: 0,
      sort: true,
    };
    if (isSearched) queryParams["name"] = typeSearchText;
    getTransactiontypes({ queryParams });
  };

  //HANDLERS
  const setTypeSearchTextHandler = (text) => {
    setTypeSearchText(text);
  };
  const handleFormSubmit = () => {
    const credentials = form.getFieldsValue();
    Object.keys(credentials).forEach(
      (key) => credentials[key] === undefined && delete credentials[key]
    );
    setFilteredData(credentials);
  };
  const handleResetForm = () => {
    setFilteredData(null);
    form.resetFields();
    setFilterOpen(false);
  };
  const handleConfirmModal = (data) => {
    setSelectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };
  const handleDeleteData = () => {
    deleteTemplate({
      pathParams: selectedData,
      logic(res) {
        toastAlert(res.message);
        setShowConfirmationModal(false);
        setFilteredData({ ...filteredData });
      },
    });
  };
  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };
  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };

  // HOOKS
  useEffect(() => {
    const queryParams = {
      limit: TABLE_PERPAGE_SIZE,
      offset: (page - 1) * TABLE_PERPAGE_SIZE,
      ...filteredData,
    };
    getTemplates({
      queryParams,
      logic(response) {
        setTotal(response.total);
        setFilterOpen(false);
      },
    });
  }, [page, filteredData]);

  useEffect(() => {
    getTransactiontypesHandler(typeSearchText !== "");
  }, [typeSearchText]);

  useEffect(() => {
    getChecklistTypes();
  }, []);

  // FILTER DRAWER
  const drawerSec = () => (
    <Form form={form} className="form" onFinish={handleFormSubmit}>
      <TemplateFilter
        isTypesLoading={typesLoader}
        transactiontypes={transactionsList}
        setTypeSearchText={setTypeSearchTextHandler}
        checklistTypes={types}
        isChecklistTypesLoading={checklisttypeLoader}
      />
      <Form.Item style={{ marginTop: 25 }}>
        <ButtonComponent
          text={FILTER.BTN}
          isLoading={isDataLoading}
          disabled={isDataLoading}
          className="w-100"
        />
      </Form.Item>
      <span className="form-rm-btn" onClick={handleResetForm}>
        Reset Filters
      </span>
    </Form>
  );

  // TABLE COLUMNS
  const columns = [
    {
      title: COLUMN.NO,
      dataIndex: "No",
      key: "1",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * TABLE_PERPAGE_SIZE) + (i + 1)}</b>
      ),
    },
    {
      title: COLUMN.NAME,
      dataIndex: "name",
      key: "2",
      render: (data) => {
        return (
          <span className="data-elipse" style={{ width: 200 }}>
            {data}
          </span>
        );
      },
    },
    {
      title: COLUMN.TYPE,
      dataIndex: "type",
      key: "2",
      render: (data) => {
        return <span>{data.value}</span>;
      },
    },
    {
      title: COLUMN.CREATED,
      dataIndex: "createdAt",
      key: "3",
      render: (data) => {
        return <span>{getFormattedDateTime(data, DATE_TIME)}</span>;
      },
    },
    {
      title: COLUMN.ACTION,
      key: "4",
      render: (data) => {
        return (
          <div className="actions">
            <Link
              to={CHECKLIST_TEMPLATES_ROUTE.DETAIL.replace(
                ":dataid",
                data.slug
              )}
              className="update">
              <Tooltip title={TOOLTIP.DETAIL}>
                <EyeOutlined />
              </Tooltip>
            </Link>
            <Link
              to={CHECKLIST_TEMPLATES_ROUTE.UPDATE.replace(":id", data.slug)}
              className="update">
              <Tooltip title={TOOLTIP.EDIT}>
                <EditOutlined />
              </Tooltip>
            </Link>
            <span style={{ width: 25 }}>
              <Tooltip title={TOOLTIP.DELETE}>
                <DeleteOutlined
                  // style={{ height: 17 }}
                  onClick={() => handleConfirmModal(data.slug)}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="add-btn">
              <ButtonComponent
                isLink
                link={CHECKLIST_TEMPLATES_ROUTE.CREATE}
                text={TABLE.BTN}
              />
            </div>
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}>
              <div className="project-ant">
                <div>
                  <Title level={5}>{TABLE.TITLE}</Title>
                </div>
                <ButtonComponent
                  onClick={filterHandler}
                  text={FILTER.TITLE}
                  icon={<FilterFilled />}
                />
              </div>
              <DataTable
                isLoading={isDataLoading}
                data={data}
                columns={columns}
                pagination={{
                  ...paginationConfig,
                  onChange: handlePageChange,
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <ConfirmationModal
        handleClose={handleConfirmationModalClose}
        modalPreview={showConfirmationModal}
        title={DELETE_MODAL.TITLE}
        description={DELETE_MODAL.DESCRIPTION}
        handelConfirm={handleDeleteData}
        isLoading={blockLoading}
      />
      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />
    </>
  );
}

export default ChecklistTemplates;
