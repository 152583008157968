import React, { useState } from "react";
import "../styles.scss";
import { Form, Input } from "antd";
import { Images } from "../../../themes";
import { ButtonComponent } from "../../../components";
import { WEB_STRINGS } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { inputFieldRule } from "../../../services/utils";

const ForgotPass = () => {
  const { FORGOTPASS } = WEB_STRINGS;
  const { EMAIL } = FORGOTPASS.FIELDS;
  const [isLoading, setLoading] = useState(false);
  const [isSend, setSend] = useState(false);
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    setLoading(true);
    const credentials = form.getFieldsValue();
    // adminLogin(credentials, (res) => {
    setSend(true);
    // if (res.status) {
    //   window.location.replace(DASHBOARD_ROUTE);
    // }
    //   setLoading(false);
    // });
  };

  return (
    <section className="signin-wrapper">
      <div className="logo-wrapper">
        <img src={Images.Logo} alt="Dealtru" />
      </div>
      <div className="form-wrapper">
        <h3 className="title forget-ttl">{FORGOTPASS.TITLE}</h3>
        <Form form={form} className="form" onFinish={handleFormFinish}>
          {!isSend ? (
            <>
              <Form.Item
                name={EMAIL.NAME}
                rules={inputFieldRule({
                  name: EMAIL.PLACEHOLDER,
                  isEmail: true,
                })}>
                <Input name={EMAIL.NAME} placeholder={EMAIL.PLACEHOLDER} />
              </Form.Item>
              <Form.Item>
                <ButtonComponent isLoading={isLoading} text={FORGOTPASS.BTN} />
              </Form.Item>
            </>
          ) : (
            <p className="email-send">{FORGOTPASS.EMAILSEND}</p>
          )}
        </Form>
      </div>
    </section>
  );
};

export default ForgotPass;
