import React from "react";
import { Form, Input, Select } from "antd";
import { WEB_STRINGS, BLOCKED_STATUS_FILTER } from "../../../constants";

function EnterpriseFilter() {
  const { NAME, COUNTRY, BLOCK_STATUS } = WEB_STRINGS.ENTERPRISES.FILTER.FIELDS;

  return (
    <>
      <label className="form-lbl">{NAME.LABEL}</label>
      <Form.Item name={NAME.NAME} rules={NAME.RULES}>
        <Input
          name={NAME.NAME}
          placeholder={NAME.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">{COUNTRY.LABEL}</label>
      <Form.Item name={COUNTRY.NAME} rules={COUNTRY.RULES}>
        <Input
          name={COUNTRY.NAME}
          placeholder={COUNTRY.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">{BLOCK_STATUS.LABEL}</label>
      <Form.Item name={BLOCK_STATUS.NAME}>
        <Select
          placeholder={BLOCK_STATUS.PLACEHOLDER}
          name={BLOCK_STATUS.NAME}
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }>
          {BLOCKED_STATUS_FILTER?.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
}

export default EnterpriseFilter;
