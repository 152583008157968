import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "antd";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ButtonComponent, ConfirmationModal } from "../../../components";
import {
  DROPDOWN_DATA_SIZE,
  QUESTIONS_ROUTE,
  SELECT_PERPAGE_SIZE,
  WEB_STRINGS,
} from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  createQuestionRequest,
  updateQuestionRequest,
  getOneQuestionRequest,
  getQuestionsRequest,
  deleteQuestionRequest,
} from "../../../redux/slicers/questions";
import { getTransactionTypeListRequest } from "../../../redux/slicers/transactiontype";
import { toastAlert } from "../../../services/utils";
import QuestionFormFields from "./fields";
import QuestionsDetailView from "./view";
import _ from "lodash";

const QuestionForm = () => {
  const { CREATE_BTN, EDIT_BTN, UPDATE_BTN, DELETE_BTN } =
    WEB_STRINGS.QUESTIONS_FORM;
  const { CONFIRMATION_MODAL } = WEB_STRINGS.QUESTIONS;
  // CONST VALS
  const [form] = Form.useForm();
  const { id, dataid } = useParams();
  const paramid = id || dataid;
  const navigate = useNavigate();

  // STATES
  const [typeSearchText, setTypeSearchText] = useState("");
  const [isTypesLoading, setTypesLoading] = useState(true);
  const [isQuestionLoading, setQuestionLoading] = useState(true);
  const [questionSearchText, setQuestionSearchText] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [questionOptions, setquestionOptions] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // REDUX DATA
  const questionData = useSelector((state) => state.questions.data);
  const questions = useSelector((state) => state.questions.list);
  const { transactionsList } = useSelector((state) => state.transactiontypes);

  // DISPATCH CALLS
  const [getQuestions, questionLoader] = useCustomDispatch(getQuestionsRequest);
  const [getQuestionDetail, dataLoading] = useCustomDispatch(
    getOneQuestionRequest
  );
  const [getTransactiontypes, typesLoader] = useCustomDispatch(
    getTransactionTypeListRequest
  );
  const [createQuestion, createLoading] = useCustomDispatch(
    createQuestionRequest
  );
  const [updateQuestion, updateLoading] = useCustomDispatch(
    updateQuestionRequest
  );
  const [deleteQuestion, deleteLoading] = useCustomDispatch(
    deleteQuestionRequest
  );

  // HANDLERS

  const setSelectedTypesHandler = (val) => {
    setSelectedTypes(val);
  };
  const setTypeSearchTextHandler = (text) => {
    setTypeSearchText(text);
  };

  const setQuestionSearchTextHandler = (text) => {
    setQuestionSearchText(text);
  };

  const handleAddquestionOption = (value) => {
    setquestionOptions((questionOptions) => [...questionOptions, value]);
  };

  const handleRemovequestionOption = (value) => {
    let tempdata = [...questionOptions].filter((x) => x !== value);
    setquestionOptions([...tempdata]);
    const fields = form.getFieldsValue();
    const { answer } = fields;
    answer[value].nextQuestionId = null;
    form.setFieldsValue({ answer });
  };

  const handleMauplatequestionOption = (value) => {
    let tempdata = [];
    questionOptions.forEach((element) => {
      if (element > value) tempdata.push(element - 1);
      if (element < value) tempdata.push(element);
    });
    setquestionOptions(tempdata);
  };

  const handleFormFinish = ({ answer, question, transactionTypeId }) => {
    let deleted = [];
    questionData?.answer?.forEach((element) => {
      const idInArrayIdx = answer?.findIndex((a) => a.id === element?.id);
      if (idInArrayIdx < 0) {
        deleted.push(element.id);
      }
    });
    const payload = {
      question: question,
      transactionTypeId: transactionTypeId,
    };
    if (id) {
      payload["updatedAnswer"] = [...answer].filter((x) => {
        return x.id !== undefined;
      });
      payload["createdAnswer"] = [...answer].filter((x) => {
        return x.id === undefined;
      });
      payload["deletedAnswer"] = deleted;
      requestHelper(updateQuestion, payload, questionData.id);
    } else {
      payload["answer"] = answer;
      requestHelper(createQuestion, payload);
    }
  };

  const handleConfirmModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };
  const handleDeleteData = () => {
    deleteQuestion({
      pathParams: questionData?.id,
      logic(res) {
        toastAlert(res.message);
        setShowConfirmationModal(false);
        navigate(QUESTIONS_ROUTE.GET);
      },
    });
  };

  // HELPERS
  const getTransactiontypesHandler = (isSearched) => {
    const queryParams = {
      limit: SELECT_PERPAGE_SIZE,
      offset: 0,
      status: true,
      sort: true,
    };
    if (questionData?.transactionTypeId)
      queryParams["ids"] = questionData?.transactionTypeId?.toString();

    if (isSearched) queryParams["name"] = typeSearchText;

    getTransactiontypes({
      queryParams,
      logic() {
        setTypesLoading(false);
      },
    });
  };

  const getQuestionsHandler = (isSearched) => {
    const queryParams = {
      limit: DROPDOWN_DATA_SIZE,
      offset: 0,
      exclude: paramid,
    };
    if (selectedTypes?.length >= 1)
      queryParams["types"] = selectedTypes?.toString();

    if (isSearched) queryParams["search"] = questionSearchText;

    if (questionData?.answer) {
      const questions = questionData?.answer.map((obj) => obj.nextQuestionId);
      queryParams["ids"] = questions?.toString();
    }

    getQuestions({
      queryParams,
      logic() {
        setQuestionLoading(false);
      },
    });
  };

  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic(response) {
        toastAlert(response.message);
        navigate(QUESTIONS_ROUTE.GET);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (paramid) {
      getQuestionDetail({ pathParams: paramid });
    }
  }, []);

  useEffect(() => {
    if (questionData && paramid) {
      let dependentquestions = [];
      questionData?.answer?.forEach((element, index) => {
        if (element.nextQuestionId) {
          dependentquestions.push(index);
        }
      });
      setquestionOptions(dependentquestions);
      if (!isQuestionLoading && !isTypesLoading) {
        setSelectedTypes(questionData.transactionTypeId);
        form.setFieldsValue({
          question: questionData.question,
          answer: questionData.answer,
          transactionTypeId: questionData.transactionTypeId,
        });
      }
    }
  }, [paramid, questionData, isQuestionLoading, isTypesLoading]);
  useEffect(() => {
    getTransactiontypesHandler(typeSearchText !== "");
  }, [questionData, typeSearchText]);

  useEffect(() => {
    getQuestionsHandler(questionSearchText !== "");
  }, [questionData, selectedTypes, questionSearchText]);

  // CONST VALS
  const isDataLoading =
    paramid && (dataLoading || isQuestionLoading || isTypesLoading);

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ textAlign: "right" }}>
            {dataid && (
              <Link to={QUESTIONS_ROUTE.UPDATE.replace(":id", paramid)}>
                <button className="filter-btn">{EDIT_BTN}</button>
              </Link>
            )}
            {paramid && (
              <button
                className="block-btn"
                onClick={handleConfirmModal}
                style={{ marginLeft: 10, marginBottom: 15 }}
              >
                {DELETE_BTN}
              </button>
            )}
          </div>
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 25px" }}
          >
            {isDataLoading ? (
              <div className="loader-wrapper" style={{ height: 320 }}>
                <BeatLoader color="#6F7CED" />
              </div>
            ) : (
              <Form
                form={form}
                className="form"
                disabled={dataid}
                onFinish={handleFormFinish}
              >
                <Row gutter={[24, 0]}>
                  {dataid ? (
                    <QuestionsDetailView
                      data={questionData}
                      questions={questions}
                    />
                  ) : (
                    <QuestionFormFields
                      setSelectedTypes={setSelectedTypesHandler}
                      isTypesLoading={typesLoader}
                      transactiontypes={transactionsList}
                      setTypeSearchText={setTypeSearchTextHandler}
                      questions={questions}
                      isQuestionLoading={questionLoader}
                      setQuestionSearchText={setQuestionSearchTextHandler}
                      addQuestionOption={handleAddquestionOption}
                      removeQuestionOption={handleRemovequestionOption}
                      mauplatequestionOption={handleMauplatequestionOption}
                      questionOptions={questionOptions}
                    />
                  )}
                  {!dataid && (
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item>
                        <ButtonComponent
                          isLoading={createLoading || updateLoading}
                          text={id ? UPDATE_BTN : CREATE_BTN}
                          style={{ width: 220, marginTop: 10, padding: 10 }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        handleClose={handleConfirmModal}
        modalPreview={showConfirmationModal}
        title={CONFIRMATION_MODAL.TITLE}
        description={CONFIRMATION_MODAL.DESCRIPTION}
        handelConfirm={handleDeleteData}
        isLoading={deleteLoading}
      />
    </div>
  );
};

export default QuestionForm;
