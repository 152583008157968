import React, { useEffect, useState } from "react";
import { Form, Row, Col, Card } from "antd";
import { useSelector } from "react-redux";
import { ButtonComponent } from "../../../components";
import { BeatLoader } from "react-spinners";
import { WEB_STRINGS, PARTY_ROLE_ROUTE } from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  createPartyRoleRequest,
  updatePartyRoleRequest,
  getOneRoleRequest,
} from "../../../redux/slicers/partyroles";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toastAlert } from "../../../services/utils";
import { getPermissionsRequest } from "../../../redux/slicers/general";
import PartyRoleDetailView from "./view";
import PartyRolesFormFields from "./fields";
const PartRoleForm = () => {
  // WEBSTRINGS
  const { CREATE_BTN, UPDATE_BTN, EDIT_BTN } = WEB_STRINGS.ADVISORY_ROLE_FORM;

  const { id, dataid } = useParams();
  const paramid = id || dataid;
  const navigate = useNavigate();

  //STATES
  const [permissionList, setPermissionList] = useState([]);

  // REDUX STATE DATA
  const { data } = useSelector((state) => state.partyroles);
  const { permissions } = useSelector((state) => state.general);

  // CUSTOM DISPATCH
  const [getRole, dataLoading] = useCustomDispatch(getOneRoleRequest);
  const [createPartyRole, createLoader] = useCustomDispatch(
    createPartyRoleRequest
  );
  const [updatePartyRole, updateLoader] = useCustomDispatch(
    updatePartyRoleRequest
  );
  const [getPermissions, permissionsLoader] = useCustomDispatch(
    getPermissionsRequest
  );
  // CONSTANT VALS
  const isLoading = createLoader || updateLoader;
  const [form] = Form.useForm();

  // HELPERS
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic(response) {
        toastAlert(response.message);
        navigate(PARTY_ROLE_ROUTE.GET);
      },
    });
  };

  const handleFormFinish = ({ name, permissions }) => {
    const payload = { name, permissions: [] };
    if (paramid) {
      requestHelper(updatePartyRole, payload, data.id);
    } else {
      requestHelper(createPartyRole, payload);
    }
  };
  // HOOKS
  useEffect(() => {
    if (paramid) {
      getRole({
        pathParams: paramid,
        logic: (res) => {
          form.setFieldsValue({ permissions: res.data.permissions });
        },
      });
    }
    if (!dataid) {
      getPermissions();
    }
  }, []);

  useEffect(() => {
    if (data && paramid) {
      form.setFieldsValue({
        name: data.name,
      });
    }
  }, [data]);

  // useEffect(() => {
  //   if (permissions.length >= 1) {
  //     let temp = [];
  //     [...permissions].map((item, i) => {
  //       temp.push({ value: item.id, label: item.key });
  //     });
  //     setPermissionList(temp);
  //   }
  // }, [permissions]);

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {dataid && (
            <div style={{ textAlign: "right" }}>
              <Link to={PARTY_ROLE_ROUTE.UPDATE.replace(":id", paramid)}>
                <button className="filter-btn">{EDIT_BTN}</button>
              </Link>
            </div>
          )}
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 25px" }}>
            {dataLoading || permissionsLoader ? (
              <div className="loader-wrapper" style={{ height: 320 }}>
                <BeatLoader color="#6F7CED" />
              </div>
            ) : (
              <Form
                form={form}
                className="form"
                disabled={dataid}
                onFinish={handleFormFinish}>
                <Row gutter={[24, 0]}>
                  {dataid ? (
                    <PartyRoleDetailView data={data} />
                  ) : (
                    <PartyRolesFormFields
                      permissionList={permissionList}
                      isDefault={data?.isDefault}
                    />
                  )}
                  {!dataid && (
                    <Col xs={24} md={24} lg={24}>
                      <ButtonComponent
                        isLoading={isLoading}
                        disabled={data?.isDefault}
                        text={paramid ? UPDATE_BTN : CREATE_BTN}
                        style={{ width: 220, marginTop: 10, padding: 7 }}
                      />
                    </Col>
                  )}
                </Row>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PartRoleForm;
