import qs from "qs";
import ApiHandler from "../services/ApiHandler";
import { getCurrentAccessToken } from "../services/utils";

export const API_TIMEOUT = 30000;
export const ABORT_REQUEST_MESSAGE = "Network failed. Aborted request.";

// export const BASE_URL = process.env.REACT_APP_BACKEND_DEV_URL;
// export const BASE_URL = process.env.REACT_APP_BACKEND_STAGING_URL;
export const BASE_URL = process.env.REACT_APP_BACKEND_PROD_URL;
// export const BASE_URL = "http://localhost:1337/";
// export const BASE_URL = "https://9e86-110-39-172-42.ngrok.io/";

const ADMIN_PREFIX = `api/admin/`;
const API_PREFIX = `api/`;

export const ERROR_SOMETHING_WENT_WRONG =
  "Something went wrong, Please try again later";
export const ERROR_API_NOT_FOUND = "Api not found, Please try again later";

export const ERROR_NETWORK_NOT_AVAILABLE =
  "Please connect to the working Internet";

export const ERROR_ACCOUNT_BLOCKED =
  "Either your account is blocked or deleted";

export const ERROR_TOKEN_EXPIRE = "Session Expired, Please login again!";

export const REQUEST_TYPE = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
  PATCH: "patch",
};

// GENERAL
// export const UPLOAD_IMAGE = {
//   route: `${API_PREFIX}files/upload`,
//   access_token_required: true,
//   type: REQUEST_TYPE.POST,
// };
export const UPLOAD_IMAGE = {
  route: `${API_PREFIX}auth/uploadfile`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_DASHBOARD_STATS = {
  route: `${ADMIN_PREFIX}dashboard/stats`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_PERMISSIONS = {
  route: `${ADMIN_PREFIX}permission`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// AUTH
export const SIGN_IN = {
  route: `${ADMIN_PREFIX}auth/signin`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const LOGOUT = {
  route: `${API_PREFIX}auth/logout`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const CONFIRM_OTP = {
  route: `${ADMIN_PREFIX}auth/confirmOtp`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const RESEND_OTP = {
  route: `${ADMIN_PREFIX}auth/resentOtp`,
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

// USERS
export const GET_USERS_LIST = {
  route: `${ADMIN_PREFIX}users`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ONE_USER = {
  route: `${ADMIN_PREFIX}users`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const BLOCK_UNBLOCK_USER = {
  route: `${ADMIN_PREFIX}users/block`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

// ENTERPRISE
export const CREATE_ENTERPRISE = {
  route: `${ADMIN_PREFIX}enterprise`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_ENTERPRISE_LIST = {
  route: `${ADMIN_PREFIX}enterprise`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ONE_ENTERPRISE = {
  route: `${ADMIN_PREFIX}enterprise`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const BLOCK_UNBLOCK_ENTERPRISE = {
  route: `${ADMIN_PREFIX}enterprise/block`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const UPDATE_ENTERPRISE = {
  route: `${ADMIN_PREFIX}enterprise`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

// TRANSACTION TYPE
export const CREATE_TRANSACTION_TYPE = {
  route: `${ADMIN_PREFIX}transactionType`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_TRANSACTION_TYPE = {
  route: `${ADMIN_PREFIX}transactionType`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};
export const CHANGE_TRANSACTION_TYPE_STATUS = {
  route: `${ADMIN_PREFIX}transactionType`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_TRANSACTION_TYPE_LIST = {
  route: `${ADMIN_PREFIX}transactionType`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ONE_TRANSACTION_TYPE = {
  route: `${ADMIN_PREFIX}transactionType`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const DELETE_TRANSACTION_TYPE = {
  route: `${ADMIN_PREFIX}transactionType/del`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

// QUESTIONS
export const CREATE_QUESTION = {
  route: `${ADMIN_PREFIX}question`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_QUESTION = {
  route: `${ADMIN_PREFIX}question`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};
export const DELETE_QUESTION = {
  route: `${ADMIN_PREFIX}question`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_QUESTIONS_LIST = {
  route: `${ADMIN_PREFIX}question`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ONE_QUESTION = {
  route: `${ADMIN_PREFIX}question`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_QUESTION_OPTIONS = {
  route: `${ADMIN_PREFIX}question/opt`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// ADVISORY ROLES
export const CREATE_ADVISORY_ROLE = {
  route: `${ADMIN_PREFIX}advisor`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_ADVISORY_ROLE = {
  route: `${ADMIN_PREFIX}advisor`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};
export const CHANGE_ADVISORY_STATUS = {
  route: `${ADMIN_PREFIX}advisor`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_ADVISORY_ROLE_LIST = {
  route: `${ADMIN_PREFIX}advisor`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ONE_ADVISORY_ROLE = {
  route: `${ADMIN_PREFIX}advisor`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const DELETE_ADVISORY_ROLE = {
  route: `${ADMIN_PREFIX}advisor/del`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

// PARTY ROLES
export const CREATE_PARTY_ROLE = {
  route: `${ADMIN_PREFIX}party`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_PARTY_ROLE = {
  route: `${ADMIN_PREFIX}party`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};
export const CHANGE_PARTY_STATUS = {
  route: `${ADMIN_PREFIX}party`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_PARTY_ROLE_LIST = {
  route: `${ADMIN_PREFIX}party`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_ONE_PARTY_ROLE = {
  route: `${ADMIN_PREFIX}party`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const DELETE_PARTY_ROLE = {
  route: `${ADMIN_PREFIX}party/del`,
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

// RESOURCES

export const CREATE_FOLDER = {
  route: `${ADMIN_PREFIX}resource`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_FOLDER = {
  route: `${ADMIN_PREFIX}resource`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const DELETE_FOLDER = {
  route: `${ADMIN_PREFIX}resource`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_FOLDER_LIST = {
  route: `${ADMIN_PREFIX}resource`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_FOLDER_DETAIL = {
  route: `${ADMIN_PREFIX}resource`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const UPLOAD_FILE = {
  route: `${ADMIN_PREFIX}resource/file`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_FILE = {
  route: `${ADMIN_PREFIX}resource/file`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const GET_FILE_LIST = {
  route: `${ADMIN_PREFIX}resource/file`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const DELETE_FILE = {
  route: `${ADMIN_PREFIX}resource/file`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

// CHECKLIST TEMPLATE
export const CREATE_CHECKLIST_TEMPLATE = {
  route: `${ADMIN_PREFIX}checklist-template`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_CHECKLIST_TEMPLATE = {
  route: `${ADMIN_PREFIX}checklist-template`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};
export const DELETE_CHECKLIST_TEMPLATE = {
  route: `${ADMIN_PREFIX}checklist-template`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_CHECKLIST_TEMPLATES = {
  route: `${ADMIN_PREFIX}checklist-template`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_CHECKLIST_TEMPLATE_DETAIL = {
  route: `${ADMIN_PREFIX}checklist-template`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_CHECKLIST_TYPES = {
  route: `${API_PREFIX}users/checklist/type/all`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// REVIEWS
export const CREATE_REVIEW = {
  route: `${ADMIN_PREFIX}review`,
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPDATE_REVIEW = {
  route: `${ADMIN_PREFIX}review`,
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};
export const DELETE_REVIEW = {
  route: `${ADMIN_PREFIX}review`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_REVIEWS = {
  route: `${ADMIN_PREFIX}review`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_REVIEW_DETAIL = {
  route: `${ADMIN_PREFIX}review`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// CONTACT US

export const DELETE_CONTACT = {
  route: `${ADMIN_PREFIX}contact-us`,
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_CONTACTS = {
  route: `${ADMIN_PREFIX}contact-us`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_CONTACT_DETAIL = {
  route: `${ADMIN_PREFIX}contact-us`,
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

//CALL REQUEST
export const callRequest = async ({
  url,
  data = {},
  queryParams,
  pathParams,
  header = {},
  baseURL = BASE_URL,
  cacheBusting = false,
}) => {
  let _header = header;
  if (url.access_token_required) {
    const _access_token = getCurrentAccessToken();
    if (_access_token) {
      _header = {
        ..._header,
        ...{
          Authorization: `Bearer ${_access_token}`,
        },
      };
    }
  }

  let _url = url.route;
  if (pathParams) {
    _url = `${url.route}/${pathParams}`;
  }
  if (queryParams && !_.isEmpty(queryParams)) {
    _url = `${_url}?${
      queryParams instanceof Object ? qs.stringify(queryParams) : queryParams
    }`;
  }

  if (cacheBusting) _url += `?t${Date.now()}`;

  let response = await ApiHandler(url.type, _url, data, _header, baseURL);
  return response;
};
