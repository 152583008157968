import React, { useState } from "react";
import "../styles.scss";
import { Form, Input } from "antd";
import { userSignInRequest } from "../../../redux/slicers/auth";
import { Images } from "../../../themes";
import { ButtonComponent, TwoFactorModal } from "../../../components";
import { DASHBOARD_ROUTE, WEB_STRINGS } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useCustomDispatch } from "../../../helper/customDispatch";
import { inputFieldRule, toastAlert } from "../../../services/utils";

const SignIn = () => {
  //STATES
  const [tfaModalPreview, setTfaModalPreview] = useState(false);
  const [email, setEmail] = useState("");

  //CONST VALS
  const { SIGNIN } = WEB_STRINGS;
  const { EMAIL, PASSWORD } = SIGNIN.FIELDS;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  //CUSTOM DISPATCH
  const [signInReq, isLoading] = useCustomDispatch(userSignInRequest);

  //HANDLERS

  const handleFormFinish = () => {
    const payload = form.getFieldsValue();
    setEmail(payload?.email);
    signInReq({
      payload,
      logic(response) {
        // toastAlert(response.message);
        // navigate(DASHBOARD_ROUTE);
        tfaModalPreviewHandler();
      },
    });
  };

  const tfaModalPreviewHandler = () => {
    setTfaModalPreview(!tfaModalPreview);
  };

  return (
    <section className="signin-wrapper">
      <div className="logo-wrapper">
        <img src={Images.Logo} alt="Dealtru" />
      </div>
      <div className="form-wrapper">
        <h3 className="title">{SIGNIN.TITLE}</h3>
        <Form form={form} className="form" onFinish={handleFormFinish}>
          <Form.Item
            name={EMAIL.NAME}
            rules={inputFieldRule({
              name: EMAIL.PLACEHOLDER,
              isEmail: true,
            })}
          >
            <Input name={EMAIL.NAME} placeholder={EMAIL.PLACEHOLDER} />
          </Form.Item>
          <Form.Item
            name={PASSWORD.NAME}
            rules={inputFieldRule({
              name: PASSWORD.PLACEHOLDER,
            })}
          >
            <Input.Password
              // className="pass"
              name={PASSWORD.NAME}
              placeholder={PASSWORD.PLACEHOLDER}
            />
          </Form.Item>
          <Form.Item>
            <ButtonComponent isLoading={isLoading} text={SIGNIN.BTN} />
          </Form.Item>
        </Form>
      </div>
      <TwoFactorModal
        preview={tfaModalPreview}
        handleClose={tfaModalPreviewHandler}
        email={email}
      />
    </section>
  );
};

export default SignIn;
