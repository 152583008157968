import React, { useState } from "react";
import "../styles.scss";
import { Form, Input, Button } from "antd";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Images } from "../../../themes";
import { WEB_STRINGS } from "../../../constants";
import { ButtonComponent } from "../../../components";

const ResetPassword = () => {
  const { RESETPASS } = WEB_STRINGS;
  const { PASSWORD, CONFIRMPASSWORD } = RESETPASS.FIELDS;
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const handleFormFinish = () => {
    setLoading(true);
    const credentials = form.getFieldsValue();
    // adminLogin(credentials, (res) => {
    // if (res.status) {
    //   window.location.replace(DASHBOARD_ROUTE);
    // }
    //   setLoading(false);
    // });
  };

  return (
    <section className="signin-wrapper">
      <div className="logo-wrapper">
        <img src={Images.Logo} alt="Dealtru" />
      </div>
      <div className="form-wrapper">
        <h3 className="title forget-ttl">{RESETPASS.TITLE}</h3>
        <Form form={form} className="form" onFinish={handleFormFinish}>
          <Form.Item name={PASSWORD.NAME}>
            <Input.Password
              name={PASSWORD.NAME}
              placeholder={PASSWORD.PLACEHOLDER}
            />
          </Form.Item>
          <Form.Item name={CONFIRMPASSWORD.NAME}>
            <Input.Password
              name={CONFIRMPASSWORD.NAME}
              placeholder={CONFIRMPASSWORD.PLACEHOLDER}
            />
          </Form.Item>
          <Form.Item>
            <ButtonComponent isLoading={isLoading} text={RESETPASS.BTN} />
          </Form.Item>
        </Form>
      </div>
    </section>
  );
};

export default ResetPassword;
