import React, { useState } from "react";
import { Col, Select, Form, Input, Tooltip, Button } from "antd";
import {
  MinusCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { inputFieldRule } from "../../../services/utils";

const QuestionFormFields = ({
  setSelectedTypes,
  transactiontypes,
  isTypesLoading,
  setTypeSearchText,
  questions,
  isQuestionLoading,
  setQuestionSearchText,
  removeQuestionOption,
  addQuestionOption,
  mauplatequestionOption,
  questionOptions,
}) => {
  return (
    <>
      <Col xs={24}>
        <label className="form-lbl">Question</label>
        <Form.Item
          name="question"
          rules={inputFieldRule({
            name: "Question",
            isMax: true,
            max: 200,
          })}>
          <Input placeholder="Enter question" className="form-input" />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <label className="form-lbl">Transaction types</label>
        <Form.Item
          name="transactionTypeId"
          rules={inputFieldRule({
            name: "Transaction type",
            isWhiteSpace: false,
          })}>
          <Select
            mode="multiple"
            showArrow
            placeholder="Select transaction type"
            name="transactionTypeId"
            className="form-select"
            loading={isTypesLoading}
            onSearch={(val) => {
              setTypeSearchText(val);
            }}
            onChange={(val) => {
              setSelectedTypes(val);
            }}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {transactiontypes?.map((data, index) => (
              <Select.Option value={data.id} key={index}>
                {data.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24}>
        <label className="form-lbl">Options</label>
      </Col>
      <Form.List
        name="answer"
        initialValue={[
          {
            answer: "",
          },
        ]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <React.Fragment key={index}>
                <Col xs={12} md={12}>
                  <Form.Item
                    name={[name, "answer"]}
                    {...restField}
                    rules={inputFieldRule({
                      name: "Option",
                      isMax: true,
                      max: 100,
                    })}>
                    <Input placeholder="Enter option" className="form-input" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={fields.length > 1 ? 11 : 12}>
                  {questionOptions.includes(name) ? (
                    <>
                      <Form.Item
                        {...restField}
                        name={[name, "nextQuestionId"]}
                        rules={inputFieldRule({
                          isWhiteSpace: false,
                          requiredMessage: "Please select dependent question",
                        })}>
                        <Select
                          placeholder="Select dependent question"
                          name={[name, "nextQuestionId"]}
                          className="form-select removable-select"
                          showSearch
                          loading={isQuestionLoading}
                          onSearch={(val) => {
                            setQuestionSearchText(val);
                          }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }>
                          {questions?.map((data, index) => (
                            <Select.Option value={data.id} key={index}>
                              {data.question}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Tooltip title="Remove dependent question">
                        <MinusCircleOutlined
                          style={{ marginRight: 10 }}
                          className="form-delete-btn"
                          onClick={() => removeQuestionOption(name)}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <Button
                      className="add-fields-btn w-100"
                      type="dashed"
                      onClick={() => {
                        addQuestionOption(name);
                      }}>
                      Add question dependent to option
                    </Button>
                  )}
                </Col>
                {fields.length > 1 && (
                  <Col xs={12} md={1}>
                    <Tooltip title="Delete option">
                      <DeleteOutlined
                        style={{ marginRight: 10 }}
                        className="form-delete-btn"
                        onClick={() => {
                          remove(name);
                          mauplatequestionOption(name);
                        }}
                      />
                    </Tooltip>
                  </Col>
                )}
              </React.Fragment>
            ))}
            <Col xs={24}>
              {fields.length < 20 && (
                <Tooltip title="Add another option">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    className="add-fields-btn icon-btn">
                    <PlusOutlined />
                  </Button>
                </Tooltip>
              )}
            </Col>
          </>
        )}
      </Form.List>
    </>
  );
};

export default QuestionFormFields;
