import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import { ButtonComponent } from "../../../components";
import { BeatLoader } from "react-spinners";
import { ALERT_TYPES, RESOURCE_PREFIX, WEB_STRINGS } from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import { getQuestionsRequest } from "../../../redux/slicers/questions";
import {
  documentValidation,
  inputFieldRule,
  toastAlert,
} from "../../../services/utils";
import { FileUploader } from "react-drag-drop-files";
import { Images } from "../../../themes";
import { uploadImageRequest } from "../../../redux/slicers/general";
import {
  updateFileRequest,
  uploadResourceFileRequest,
} from "../../../redux/slicers/resources";
import { useParams } from "react-router-dom";

const FileForm = ({ data, toggle, onSuccess }) => {
  // WEB STRINGS
  const { CREATE_BTN, UPDATE_BTN, CLOSE_BTN, CREATE_TITLE, UPDATE_TITLE } =
    WEB_STRINGS.FILE_FORM;

  //CONST VALS
  const fileTypes = [
    "doc",
    "docx",
    "odt",
    "pdf",
    "txt",
    "rtf",
    "wps",
    "wkf",
    "wks",
    "wpd",
    "png",
    "jpg",
    "jpeg",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
    "tex",
  ];
  const setErrorToggle = (error) => {
    toastAlert(error, ALERT_TYPES.ERROR);
  };

  // STATES
  const [fileName, setfileName] = useState(null);
  const [file, setFile] = useState(null);

  // DISPATCH CALLS
  const [uploadFileReq, uploadFileReqLoader] = useCustomDispatch(
    uploadResourceFileRequest
  );
  const [updateFileReq, updateFileReqLoader] =
    useCustomDispatch(updateFileRequest);
  const [uploadImage, uploadLoading] = useCustomDispatch(uploadImageRequest);

  // DEFAULT CONFIG
  const isLoading = uploadFileReqLoader || updateFileReqLoader;
  const [form] = Form.useForm();
  const { dataid } = useParams();

  // HELPERS
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic(response) {
        onSuccess();
        toastAlert(response.message);
        toggle();
      },
    });
  };

  //HANDLERS
  const handleFormFinish = ({ title }) => {
    const payload = { filename: title };

    if (data) {
      requestHelper(updateFileReq, payload, data.id);
    } else {
      payload["url"] = file;
      payload["resourceId"] = parseInt(dataid);
      requestHelper(uploadFileReq, payload);
    }
  };

  const handleFile = (file) => {
    const temp = file.name.split(".");
    const extension = temp.pop();
    const modName = temp.toString() + "." + extension;
    const mutatedFile = new File([file], modName ?? "", { type: file?.type });
    if (documentValidation(mutatedFile, setErrorToggle)) {
      const data = new FormData();
      data.append("file", mutatedFile);
      data.append("directory", RESOURCE_PREFIX);
      uploadImage({
        payload: data,
        logic({ key }) {
          setFile(key);
          setfileName(mutatedFile.name);
        },
      });
    }
  };

  // HOOKS
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        title: data.filename,
      });
    }
  }, [data]);

  return (
    <Modal
      visible={true}
      title={<span>{data ? UPDATE_TITLE : CREATE_TITLE}</span>}
      centered
      onCancel={toggle}
      footer={null}
      className="modal"
    >
      <Form form={form} className="form" onFinish={handleFormFinish}>
        <div className="modal-wrapper">
          {/* {isDataLoading ? (
            <div className="loader-wrapper" style={{ height: 140 }}>
              <BeatLoader color="#6F7CED" />
            </div>
          ) : ( */}
          <div className="modal-body">
            {!data && (
              <Form.Item
                name={"url"}
                rules={inputFieldRule({
                  name: "File",
                  isWhiteSpace: false,
                })}
              >
                <div
                  className={`upload-doc-box ${file ? "active" : ""}`}
                  style={{ borderColor: `${uploadLoading && "#6F7CED"}` }}
                >
                  {uploadLoading ? (
                    <BeatLoader size={14} height="100%" color="#6F7CED" />
                  ) : (
                    <>
                      <FileUploader
                        classes="drop_area"
                        handleChange={handleFile}
                        name="file"
                        types={fileTypes}
                      />
                      <div className="content">
                        <Images.Upload />
                        <p>{fileName ?? "Drag and Drop File From Computer"}</p>
                      </div>
                    </>
                  )}
                </div>
              </Form.Item>
            )}
            <label className="form-lbl"> Name</label>
            <Form.Item
              name="title"
              rules={inputFieldRule({
                name: "Name",
                isMax: true,
                max: 100,
              })}
            >
              <Input placeholder="Enter file name" className="form-input" />
            </Form.Item>
          </div>
          {/* )} */}
          <div className="modal-footer">
            <label className="close" onClick={toggle}>
              {CLOSE_BTN}
            </label>
            <ButtonComponent
              isLoading={isLoading}
              disabled={isLoading}
              text={data ? UPDATE_BTN : CREATE_BTN}
              style={{ width: 120, marginTop: 10, padding: 7 }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default FileForm;
