import React, { useState } from "react";
import { Col, Select, Form, Input } from "antd";
import { inputFieldRule } from "../../../services/utils";

const TemplateFormFields = ({
  checklistTypes,
  isChecklistTypesLoading,
  setSelectedTypes,
  transactiontypes,
  isTypesLoading,
  setTypeSearchText,
  isPartyRoleLoading,
  partyroles,
  setPartySearchText,
  isAdvisoryRoleLoading,
  advisoryroles,
  setAdvisorySearchText,
  setFieldChangedHandler,
}) => {
  return (
    <>
      <Col xs={24} md={12}>
        <label className="form-lbl">Name</label>
        <Form.Item
          name="name"
          rules={inputFieldRule({
            name: "Name",
            isMax: true,
            max: 100,
          })}>
          <Input placeholder="Enter Template name" className="form-input" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Type</label>
        <Form.Item
          name="type"
          rules={inputFieldRule({
            name: "Type",
            isWhiteSpace: false,
          })}>
          <Select
            placeholder="Select type"
            name="tyoe"
            className="form-select"
            loading={isChecklistTypesLoading}>
            {checklistTypes?.map((data, index) => (
              <Select.Option value={data.key} key={index}>
                {data.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24}>
        <label className="form-lbl">Note</label>
        <Form.Item
          name="notes"
          rules={inputFieldRule({
            name: "Note",
            isRequired: false,
            isMax: true,
            max: 1000,
          })}>
          <Input.TextArea
            placeholder="Enter template note"
            className="form-input"
            style={{ height: "100px", resize: "none" }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Party Roles</label>
        <Form.Item
          name="PartyRoles"
          rules={inputFieldRule({
            name: "Party Role",
            isWhiteSpace: false,
            isRequired: false,
          })}>
          <Select
            mode="multiple"
            showArrow
            placeholder="Select party role"
            name="PartyRoles"
            className="form-select"
            loading={isPartyRoleLoading}
            onSearch={(val) => {
              setPartySearchText(val);
            }}
            showSearch
            onChange={setFieldChangedHandler}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {partyroles?.map((data, index) => (
              <Select.Option value={data.id} key={index}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <label className="form-lbl">Advisor Roles</label>
        <Form.Item
          name="AdvisorRoles"
          rules={inputFieldRule({
            name: "Advisor Role",
            isWhiteSpace: false,
            isRequired: false,
          })}>
          <Select
            mode="multiple"
            showArrow
            placeholder="Select advisor role"
            name="AdvisorRoles"
            className="form-select"
            loading={isAdvisoryRoleLoading}
            onChange={setFieldChangedHandler}
            onSearch={(val) => {
              setAdvisorySearchText(val);
            }}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {advisoryroles?.map((data, index) => (
              <Select.Option value={data.id} key={index}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24}>
        <label className="form-lbl">Transaction types</label>
        <Form.Item
          name="transactioTypes"
          rules={inputFieldRule({
            name: "Transaction type",
            isWhiteSpace: false,
            isRequired: false,
          })}>
          <Select
            mode="multiple"
            showArrow
            placeholder="Select transaction type"
            name="transactioTypes"
            className="form-select"
            loading={isTypesLoading}
            onSearch={(val) => {
              setTypeSearchText(val);
            }}
            onChange={(val) => {
              setSelectedTypes(val);
              setFieldChangedHandler();
            }}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {transactiontypes?.map((data, index) => (
              <Select.Option value={data.id} key={index}>
                {data.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default TemplateFormFields;
