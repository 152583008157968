import React from "react";
import { Form, Input, Select } from "antd";
import { WEB_STRINGS } from "../../../constants";

function ReviewFilter({
  transactiontypes,
  isTypesLoading,
  setTypeSearchText,
  checklistTypes,
  checklisttypeLoader,
}) {
  const { NAME, RATING } = WEB_STRINGS.REVIEWS.FILTER.FIELDS;
  return (
    <>
      <label className="form-lbl">{NAME.LABEL}</label>
      <Form.Item name={NAME.NAME} rules={NAME.RULES}>
        <Input
          name={NAME.NAME}
          placeholder={NAME.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>
    </>
  );
}

export default ReviewFilter;
