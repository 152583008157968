import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import { useSelector } from "react-redux";
import { ButtonComponent } from "../../../components";
import { BeatLoader } from "react-spinners";
import {
  DROPDOWN_DATA_SIZE,
  SELECT_PERPAGE_SIZE,
  WEB_STRINGS,
} from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import { getTransactionTypeListRequest } from "../../../redux/slicers/transactiontype";
import { inputFieldRule, toastAlert } from "../../../services/utils";
import {
  createFolderRequest,
  updateFolderRequest,
} from "../../../redux/slicers/resources";
const FolderForm = ({ data, toggle, onSuccess = () => {} }) => {
  // WEB STRINGS
  const { CREATE_BTN, UPDATE_BTN, CLOSE_BTN, CREATE_TITLE, UPDATE_TITLE } =
    WEB_STRINGS.RESOURCES_FORM;

  // STATES
  const [transactionTypeSearchText, setTransactionTypeSearchText] =
    useState("");
  const [isTransactionTypeLoading, setTransactionTypeLoading] = useState(true);

  // REDUX DATA
  const { transactionsList } = useSelector((state) => state.transactiontypes);

  // DISPATCH CALLS
  const [getTransactionType, transactionTypeLoader] = useCustomDispatch(
    getTransactionTypeListRequest
  );
  const [createFolder, createFolderReqLoader] =
    useCustomDispatch(createFolderRequest);
  const [updateFolderReq, updateFolderReqLoader] =
    useCustomDispatch(updateFolderRequest);

  // DEFAULT CONFIG
  const isLoading = createFolderReqLoader || updateFolderReqLoader;
  const [form] = Form.useForm();

  // HELPERS
  const getTransactionTypeHandler = (isSearched) => {
    const queryParams = {
      limit: SELECT_PERPAGE_SIZE,
      offset: 0,
      status: true,
      sort: true,
      ids: data?.transactionTypes.id,
    };
    if (isSearched) queryParams["search"] = transactionTypeSearchText;

    getTransactionType({
      queryParams,
      logic(res) {
        setTransactionTypeLoading(false);
      },
    });
  };
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic(response) {
        onSuccess();
        toastAlert(response.message);
        toggle();
      },
    });
  };

  //HANDLERS
  const handleFormFinish = ({ title, transactionType }) => {
    const payload = { name: title, transactionTypeId: transactionType };
    if (data) {
      requestHelper(updateFolderReq, payload, data.slug);
    } else {
      requestHelper(createFolder, payload);
    }
  };

  // HOOKS
  useEffect(() => {
    if (data) {
      const arr = [];
      data.transactionTypes.forEach((x) => {
        arr.push(x.id);
      });
      form.setFieldsValue({
        title: data.name,
        transactionType: arr,
      });
    }
  }, [data]);

  useEffect(() => {
    getTransactionTypeHandler(transactionTypeSearchText !== "");
  }, [transactionTypeSearchText]);

  const isDataLoading = data && isTransactionTypeLoading;
  return (
    <Modal
      visible={true}
      title={<span>{data ? UPDATE_TITLE : CREATE_TITLE}</span>}
      centered
      onCancel={toggle}
      footer={null}
      className="modal"
    >
      <Form form={form} className="form" onFinish={handleFormFinish}>
        <div className="modal-wrapper">
          {isDataLoading ? (
            <div className="loader-wrapper" style={{ height: 140 }}>
              <BeatLoader color="#6F7CED" />
            </div>
          ) : (
            <div className="modal-body">
              <label className="form-lbl">Name</label>
              <Form.Item
                name="title"
                rules={inputFieldRule({
                  name: "Name",
                  isMax: true,
                  max: 100,
                })}
              >
                <Input placeholder="Enter resource" className="form-input" />
              </Form.Item>
              <label className="form-lbl">Transaction Type</label>
              <Form.Item
                name="transactionType"
                rules={inputFieldRule({
                  name: "Transaction type",
                  isWhiteSpace: false,
                })}
              >
                <Select
                  mode="multiple"
                  placeholder="Select transaction type"
                  name="question"
                  className="form-select"
                  showSearch
                  loading={transactionTypeLoader}
                  onSearch={(val) => {
                    setTransactionTypeSearchText(val);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  maxTagCount={4}
                  maxTagTextLength={12}
                >
                  {transactionsList.map((data, index) => (
                    <Select.Option value={data.id} key={index}>
                      {data.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
          <div className="modal-footer">
            <label className="close" onClick={toggle}>
              {CLOSE_BTN}
            </label>
            <ButtonComponent
              isLoading={isLoading}
              disabled={isLoading}
              text={data ? UPDATE_BTN : CREATE_BTN}
              style={{ width: 120, marginTop: 10, padding: 7 }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default FolderForm;
