import { call, fork, put, take } from "redux-saga/effects";
import {
  callRequest,
  CONFIRM_OTP,
  LOGOUT,
  RESEND_OTP,
  SIGN_IN,
} from "../../config/webService";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../services/utils";
import {
  confirmOtpRequest,
  resendOtpRequest,
  userLogOutRequest,
  userLogOutSuccess,
  userSignInRequest,
  userSignInSuccess,
} from "../slicers/auth";

function* signIn() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(userSignInRequest.type);
    try {
      const response = yield call(callRequest, {
        url: SIGN_IN,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        // yield put(userSignInSuccess(response.data.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* logOut() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(userLogOutRequest.type);

    try {
      const response = yield call(callRequest, {
        url: LOGOUT,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(userLogOutSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

function* confirmOtp() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(confirmOtpRequest.type);
    try {
      const response = yield call(callRequest, {
        url: CONFIRM_OTP,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        yield put(userSignInSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
function* resendOtp() {
  while (true) {
    const {
      payload: { payload, queryParams, pathParams, responseCallback },
    } = yield take(resendOtpRequest.type);
    try {
      const response = yield call(callRequest, {
        url: RESEND_OTP,
        data: payload,
        queryParams,
        pathParams,
      });
      if (response.status) {
        // yield put(userSignInSuccess(response.data));
        responseCallback?.(true, response);
      } else {
        responseCallback?.(false, response);
        response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(signIn);
  yield fork(logOut);
  yield fork(confirmOtp);
  yield fork(resendOtp);
}
