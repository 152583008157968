import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "antd";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  ButtonComponent,
  ImageUploader,
  ProfileImage,
  ConfirmationModal,
} from "../../../components";
import {
  DEFAULT_IMAGE,
  IMAGE_HOST,
  REVIEWS_PREFIX,
  REVIEWS_ROUTE,
  WEB_STRINGS,
} from "../../../constants";
import { useCustomDispatch } from "../../../helper/customDispatch";
import {
  createReviewRequest,
  updateReviewRequest,
  getReviewDetailRequest,
  deleteReviewRequest,
} from "../../../redux/slicers/reviews";
import { toastAlert } from "../../../services/utils";
import ReviewFormFields from "./fields";
import ReviewDetailView from "./view";

const ReviewForm = () => {
  // WEB STRINGS
  const { CREATE_BTN, EDIT_BTN, DELETE_BTN, UPDATE_BTN } =
    WEB_STRINGS.REVIEWS_FORM;
  const { CONFIRMATION_MODAL } = WEB_STRINGS.REVIEWS;

  // STATES
  const [profileImage, setprofileImage] = useState(DEFAULT_IMAGE);
  const [profilePreview, setProfilePreview] = useState(
    IMAGE_HOST + DEFAULT_IMAGE
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // REDUX DATA
  const { data } = useSelector((state) => state.reviews);

  // DISPATCH CALLS
  const [createReview, createLoading] = useCustomDispatch(createReviewRequest);
  const [updateReview, updateLoading] = useCustomDispatch(updateReviewRequest);
  const [deleteReview, deleteLoading] = useCustomDispatch(deleteReviewRequest);
  const [getReviewDetail, dataLoading] = useCustomDispatch(
    getReviewDetailRequest
  );

  // CONST VALS
  const { id, dataid } = useParams();
  const paramid = id || dataid;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // HELPERS
  const requestHelper = (request, payload, pathParams) => {
    request({
      payload,
      pathParams,
      logic(response) {
        toastAlert(response.message);
        navigate(REVIEWS_ROUTE.GET);
      },
    });
  };

  // HANDLERS
  const setprofileImageHandler = (logo) => {
    setprofileImage(logo);
  };
  const setprofilePreviewHandler = (logo) => {
    setProfilePreview(logo);
  };
  const handleFormFinish = (values) => {
    const payload = { ...values };
    if (profileImage) {
      payload["image"] = profileImage;
    }
    requestHelper(id ? updateReview : createReview, payload, id);
  };

  const handleConfirmModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const deleteHandler = () => {
    deleteReview({
      pathParams: data.id,
      logic({ message }) {
        toastAlert(message);
        handleConfirmModal();
        navigate(REVIEWS_ROUTE.GET);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (paramid) {
      getReviewDetail({ pathParams: paramid });
    }
  }, []);

  useEffect(() => {
    if (data && paramid) {
      form.setFieldsValue(data);
      setprofileImage(data?.image);
    }
  }, [paramid, data]);

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div style={{ textAlign: "right" }}>
            {dataid && (
              <Link to={REVIEWS_ROUTE.UPDATE.replace(":id", paramid)}>
                <button className="filter-btn">{EDIT_BTN}</button>
              </Link>
            )}
            {paramid && (
              <button
                className="block-btn"
                onClick={handleConfirmModal}
                style={{ marginLeft: 10, marginBottom: 15 }}>
                {DELETE_BTN}
              </button>
            )}
          </div>
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 25px" }}>
            {dataLoading ? (
              <div className="loader-wrapper" style={{ height: 320 }}>
                <BeatLoader color="#6F7CED" />
              </div>
            ) : (
              <Form
                form={form}
                className="form"
                disabled={dataid}
                onFinish={handleFormFinish}>
                <Row gutter={[24, 0]}>
                  <Col xs={24} md={24} lg={24}>
                    <div className="image-uploader">
                      <ProfileImage
                        color="#fff"
                        border="#fff"
                        image={profilePreview}
                        username={data?.name}
                      />
                      <div className="action-btns">
                        {!dataid && (
                          <ImageUploader
                            setImage={setprofileImageHandler}
                            setPreviewImage={setprofilePreviewHandler}
                            directory={REVIEWS_PREFIX}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                  {dataid ? (
                    <ReviewDetailView data={data} />
                  ) : (
                    <ReviewFormFields />
                  )}
                  {!dataid && (
                    <Col xs={24} md={24} lg={24}>
                      <Form.Item>
                        <ButtonComponent
                          isLoading={createLoading || updateLoading}
                          text={id ? UPDATE_BTN : CREATE_BTN}
                          style={{ width: 220, marginTop: 10, padding: 10 }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        title={CONFIRMATION_MODAL.TITLE}
        description={CONFIRMATION_MODAL.DESCRIPTION}
        handleClose={handleConfirmModal}
        modalPreview={showConfirmationModal}
        handelConfirm={deleteHandler}
        isLoading={deleteLoading}
      />
    </div>
  );
};

export default ReviewForm;
