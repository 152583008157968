import React from "react";
import { Form, Input, Select } from "antd";
import {
  WEB_STRINGS,
  ACTIVE_STATUS_FILTER,
  BLOCKED_STATUS_FILTER,
} from "../../../constants";

function UsersFilter({ enterprises, isEnterpriseLoading, setSearchText }) {
  const {
    NAME,
    EMAIL,
    COMPANY,
    ENT_STATUS,
    BLOCK_STATUS,
    FIRST_NAME,
    LAST_NAME,
  } = WEB_STRINGS.USERS.FILTER.FIELDS;

  return (
    <>
      {/* <label className="form-lbl">{NAME.LABEL}</label> */}
      {/* <Form.Item name={NAME.NAME} rules={NAME.RULES}>
        <Input
          name={NAME.NAME}
          placeholder={NAME.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item> */}
      <label className="form-lbl">{FIRST_NAME.LABEL}</label>

      <Form.Item name={FIRST_NAME.NAME} rules={FIRST_NAME.RULES}>
        <Input
          name={FIRST_NAME.NAME}
          placeholder={FIRST_NAME.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">{LAST_NAME.LABEL}</label>

      <Form.Item name={LAST_NAME.NAME} rules={LAST_NAME.RULES}>
        <Input
          name={LAST_NAME.NAME}
          placeholder={LAST_NAME.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">{EMAIL.LABEL}</label>
      <Form.Item name={EMAIL.NAME} rules={EMAIL.RULES}>
        <Input
          name={EMAIL.NAME}
          placeholder={EMAIL.PLACEHOLDER}
          className="form-input"
        />
      </Form.Item>
      <label className="form-lbl">{COMPANY.LABEL}</label>
      <Form.Item name={COMPANY.NAME}>
        <Select
          loading={isEnterpriseLoading}
          placeholder={COMPANY.PLACEHOLDER}
          name={COMPANY.NAME}
          className="form-select"
          showSearch
          onSearch={(val) => {
            setSearchText(val);
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {enterprises?.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <label className="form-lbl">{ENT_STATUS.LABEL}</label>
      <Form.Item name={ENT_STATUS.NAME}>
        <Select
          placeholder={ENT_STATUS.PLACEHOLDER}
          name={ENT_STATUS.NAME}
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {ACTIVE_STATUS_FILTER?.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <label className="form-lbl">{BLOCK_STATUS.LABEL}</label>
      <Form.Item name={BLOCK_STATUS.NAME}>
        <Select
          placeholder={BLOCK_STATUS.PLACEHOLDER}
          name={BLOCK_STATUS.NAME}
          className="form-select"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {BLOCKED_STATUS_FILTER?.map((data, index) => (
            <Select.Option value={data.id} key={index}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
}

export default UsersFilter;
