import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "antd";
import { AnalayticsCard } from "../../../components";
import { useCustomDispatch } from "../../../helper/customDispatch";
import { getDashboardStatsRequest } from "../../../redux/slicers/general";
import { getEnterpriseListRequest } from "../../../redux/slicers/enterprise";
import { getUsersListRequest } from "../../../redux/slicers/user";
import { DASHBOARD_ANALYTICS, TABLE_PERPAGE_SIZE } from "../../../constants";
import { DashboardUsers, DashboardEnterprises } from "./partials";
import { formatValue } from "../../../services/utils";
import { BeatLoader } from "react-spinners";
function AdminDashboard() {
  // STATES
  const [stats, setStats] = useState({});

  // CUSTOM DISPATCH
  const [getUsersList, userLoading] = useCustomDispatch(getUsersListRequest);
  const [getDashboardStats, statsLoading] = useCustomDispatch(
    getDashboardStatsRequest
  );
  const [getEnterprises, enterpriseLoading] = useCustomDispatch(
    getEnterpriseListRequest
  );

  // CONST VALS
  const isLoading = userLoading || statsLoading || enterpriseLoading;

  // HOOKS
  useEffect(() => {
    const queryParams = {
      limit: TABLE_PERPAGE_SIZE,
      offset: 0,
    };
    getUsersList({
      queryParams,
    });
    getEnterprises({
      queryParams,
    });
    getDashboardStats({
      logic({ data }) {
        setStats(data);
      },
    });
  }, []);

  return (
    <>
      <div className="layout-content">
        {isLoading ? (
          <div className="loader-wrapper" style={{ height: "75vh" }}>
            <BeatLoader color="#6F7CED" size={18} />
          </div>
        ) : (
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            {DASHBOARD_ANALYTICS.map((item, index) => (
              <Col xs={24} md={12} lg={6} className="mb-40" key={index}>
                <AnalayticsCard
                  title={item.title}
                  thumb={item.icon}
                  total={formatValue(stats?.[item.key])}
                />
              </Col>
            ))}
            <Col xs={24} md={12} xl={15} className="mb-24">
              <Card
                bordered={false}
                className="criclebox h-full"
                style={{ minHeight: 360 }}>
                <DashboardUsers isLoading={userLoading} />
              </Card>
            </Col>
            <Col xs={24} md={12} xl={9} className="mb-24">
              <Card
                bordered={false}
                className="criclebox h-full"
                style={{ minHeight: 360 }}>
                <DashboardEnterprises isLoading={enterpriseLoading} />
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default AdminDashboard;
