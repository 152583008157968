import React, { useState, useEffect } from "react";
import { Col, Select, Form, Tooltip, Button } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { inputFieldRule } from "../../../services/utils";
import { useCustomDispatch } from "../../../helper/customDispatch";
import { getQuestionOptionsRequest } from "../../../redux/slicers/questions";

const TemplateQuestionFields = ({
  isUpdate,
  index,
  questions,
  selectedQuestion,
  setFieldChangedHandler,
  selectedQuestionsLength,
  isQuestionLoading,
  setQuestionSearchText,
  removeQuestion,
  addSelectedQuestion,
}) => {
  // STATES
  // const [inputQuestion, setinputQuestion] = useState(second)
  const [questionOptions, setquestionOptions] = useState([]);

  // CUSTOM DISPATCH
  const [getOptions, isOptionLoading] = useCustomDispatch(
    getQuestionOptionsRequest
  );
  // HELPERS
  const getOptionsHelper = (value) => {
    getOptions({
      pathParams: value,
      logic({ data }) {
        setquestionOptions(data);
      },
    });
  };

  // HOOKS
  useEffect(() => {
    if (isUpdate) {
      getOptionsHelper(selectedQuestion[index]);
    }
  }, [isUpdate]);

  // HANDLERS
  const handleQuestionChange = (value) => {
    addSelectedQuestion(value, index);
    getOptionsHelper(value);
  };

  return (
    <>
      <Col xs={12} md={12}>
        <Form.Item
          name={`question_${index}`}
          rules={inputFieldRule({
            isWhiteSpace: false,
            isRequired: false,
            // requiredMessage: "Please select question",
          })}>
          <Select
            placeholder="Select question"
            name={`question_${index}`}
            className="form-select"
            showSearch
            loading={isQuestionLoading}
            onChange={setFieldChangedHandler}
            onSelect={(val) => {
              handleQuestionChange(val);
            }}
            // onSearch={(val) => {
            //   setQuestionSearchText(val);
            // }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {questions?.map((data, index) => (
              <Select.Option
                value={data.id}
                key={index}
                disabled={selectedQuestion.includes(data.id)}>
                {data.question}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={12} md={selectedQuestion.length > 1 ? 11 : 12}>
        <Form.Item
          name={`answer_${index}`}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue(`question_${index}`) && !value) {
                  return Promise.reject(new Error("Option is required"));
                }
                return Promise.resolve();
              },
            }),
          ]}>
          <Select
            mode="multiple"
            showArrow
            placeholder="Select question answers"
            name={`answer_${index}`}
            className="form-select"
            onChange={setFieldChangedHandler}
            showSearch
            loading={isOptionLoading}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {questionOptions?.map((data, index) => (
              <Select.Option value={data.id} key={index}>
                {data.answer}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {selectedQuestionsLength > 1 && (
        <Col xs={12} md={1}>
          <Tooltip title="Delete option">
            <DeleteOutlined
              style={{ marginRight: 10 }}
              className="form-delete-btn"
              onClick={() => {
                removeQuestion(index);
                setFieldChangedHandler();
              }}
            />
          </Tooltip>
        </Col>
      )}
    </>
  );
};

export default TemplateQuestionFields;
