import React from "react";
import { Checkbox, Col, Form, Input } from "antd";
import { inputFieldRule } from "../../../services/utils";

const PartyRolesFormFields = ({ permissionList, isDefault }) => {
  return (
    <>
      <Col xs={24} md={24} lg={24}>
        <label className="form-lbl"> Name</label>
        <Form.Item
          name="name"
          rules={inputFieldRule({
            name: "Name",
            isMax: true,
            max: 80,
          })}>
          <Input
            placeholder="Enter party role"
            disabled={isDefault}
            className="form-input"
          />
        </Form.Item>
      </Col>
      {/* <Col span={24}>
        <div className="permissions-wrapper">
          <h2>Permissions</h2>
          <p>Participants with this role can perform the following actions.</p>
          <Form.Item
            name="permissions"
            rules={inputFieldRule({
              isWhiteSpace: false,
              requiredMessage:
                "Please provide at least one permission to the role.",
            })}>
            <Checkbox.Group
              options={permissionList}
              className="permission-checkbox"
            />
          </Form.Item>
        </div>
      </Col> */}
    </>
  );
};

export default PartyRolesFormFields;
